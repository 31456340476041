import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { IncrementSetpoint } from '../../models/views-models/incrementSetpoint.model';
import { Tag } from '../../models/views-models/tag.model';
import { CurrentProjectService } from './current-project.service';

const API = environment.serverUrl;

@Injectable()
export class SetPointService {
    constructor(private httpClient: HttpClient, private currentProjectService: CurrentProjectService) { }

    getAllIncrementSetPoints(): Observable<IncrementSetpoint[]> {
        return this.httpClient.get<Response>(`${API}increment-setpoint/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const incrementSetpoints: IncrementSetpoint[] = response.data.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                return incrementSetpoints;
            })
        );
    }

    newIncrementSetPoint(incrementSetpoint: IncrementSetpoint): Observable<IncrementSetpoint> {
        const project = this.currentProjectService.getCurrentProject();
        if (incrementSetpoint?.new_sp_tag) incrementSetpoint.new_sp_tag.project = project;
        if (incrementSetpoint?.act_sp) incrementSetpoint.act_sp.project = project;

        return this.httpClient
            .post<Response>(`${API}increment-setpoint/new`, incrementSetpoint, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const newIncrementSetPoint: IncrementSetpoint = response.data;
                    return newIncrementSetPoint;
                })
            );
    }

    updateIncrementSetPoint(incrementSetpoint: IncrementSetpoint): Observable<IncrementSetpoint> {
        return this.httpClient
            .put<Response>(`${API}increment-setpoint/edit`, incrementSetpoint, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const incrementSetpoint: IncrementSetpoint = response.data;
                    return incrementSetpoint;
                })
            );
    }

    updateSetpointStatus(incrementSetpoint: IncrementSetpoint): Observable<IncrementSetpoint> {
        return this.httpClient
            .patch<Response>(`${API}increment-setpoint/save-status`, incrementSetpoint, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const incrementSetpoint: IncrementSetpoint = response.data;
                    return incrementSetpoint;
                })
            );
    }

    getById(incrementSetPointId: string): Observable<IncrementSetpoint> {
        return this.getAllIncrementSetPoints().pipe(
            map((setPoints) => setPoints.find((setPoint) => setPoint.id == incrementSetPointId))
        );
    }

    deleteIncrementSetPoint(id: string): Observable<Tag[]> {
        return this.httpClient
            .delete<Response>(`${API}increment-setpoint/delete/${id}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const incrementSetpoints: Tag[] = response.data;
                    return incrementSetpoints;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
        });
        return headers;
    }
}
