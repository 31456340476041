import { Injectable } from '@angular/core';
import { PopUpMessagesComponent } from '../../components/pop-up-messages/pop-up-messages.component';
import { LoginService } from 'src/app/views/login/login.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class AuthIdleService {


    dialogRef: any;

    constructor(public loginService: LoginService,
        public dialog: MatDialog,

    ) { }


    openDialogExpiredSession() {
        this.dialogRef = this.dialog.open(PopUpMessagesComponent, {
            panelClass: 'pop-up-dialog-container',
            width: 'auto',
            height: 'auto',
            data: {
                subTitle: 'Sessão expirada por inatividade, por favor faça login novamente',
                typePopUp: 'titlePopUpConfirmationMessage'
            },
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            this.dialog.closeAll();
            this.loginService.logout();
        });
    }


    recreateToken() {
        this.dialog.closeAll();
        return this.loginService.login()
    }


}
