<div class="container" *ngIf="user">
    <!-- <app-code-editor></app-code-editor> -->

    <ihm-card-header icon="functions" [title]="(isEditing ? 'Editar' : 'Adicionar') + ' Variável Calculada'">
    </ihm-card-header>

    <div class="containerCard">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <span class="label"> Identificação da variável</span>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxFlex="100">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome </mat-label>
                        <input matInput placeholder="Nome da Variável" type="text" formControlName="name"
                            autocomplete="off" data-cy="name" />
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxFlex="100">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput placeholder="Descrição" type="text" formControlName="description"
                            autocomplete="off" data-cy="description" />
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="error-flow-select-input">
                    <app-select-error-flow class="select-app"
                        [title]="'Fluxo de Erro'"
                        [options]="errorFlowOptions"
                        [selectedValue]="formGroup.get('selectErrorFlow').value"
                        [placeholder]="'Modo de Falha'"
                        [showDescriptions]="true"
                        (selectedValueChange)="onErrorFlowChange($event)"
                        [hint]="formGroup.get('selectErrorFlow').value === 'last_value' ? 'Caso não exista um último valor disponível, será usado o valor default informado no campo ao lado.' : ''">
                    </app-select-error-flow>
                <mat-form-field appearance="outline" class="input-flow-error">
                    <input matInput placeholder="Valor*" type="text" formControlName="defaultValue"
                    autocomplete="off" data-cy="inputErrorFlow" />
                </mat-form-field>
            </div>    
            <mat-divider></mat-divider>

            <div class="containerCard" fxLayout="row" fxLayoutAlign="start center">
                <div class="left-text-area">
                    <span class="label"> Equação da variável</span>
                    <i class="material-icons info-header" matTooltip="a=5+8
                                            b=10*6
                                            c=a+b
            
                                " matTooltipPosition="after">info</i>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="0px">
                <div class="subCard" fxFlex="100">
                    <app-code-editor [code]="formGroup.get('equation').value" 
                                     [isReadOnly]="currentProjectService.isRunningMode()"
                                     (codeChange)="setEquation($event)"
                                     class="code-editor">
                    </app-code-editor>
                </div>
            </div>

            <div class="fuzzy-inputs" fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutAlign="start center"
                fxLayoutGap="15px">
                <div fxFlex fxLayoutAlign="start center" class="entry-tag" fxFlex="35">
                    <mat-form-field appearance="outline" class="data-select no-padding">
                        <mat-label>Tag</mat-label>
                        <input type="text" matInput list="tagList" autocomplete="off" formControlName="tagValue" />
                    </mat-form-field>
                    <datalist id="tagList">
                        <option *ngFor="let tag of tagOptions" [value]="tag.name"></option>
                    </datalist>
                </div>
                <div *ngIf="user?.permissions?.canCreate" fxFlex="10" fxLayoutAlign="start center">
                    <button mat-button class="addTagButton" type="button" (click)="addEquation()"
                        [disabled]="!user?.permissions?.canUpdate || currentProjectService.isRunningMode()"
                        data-cy="add-entry">
                        <i class="material-icons"> add_circle </i> Adicionar tag
                    </button>
                </div>
                <div fxFlex fxLayoutAlign="end center" class="entry-tag" fxFlex="35">
                    <mat-form-field appearance="outline" class="data-select no-padding">
                        <mat-label>Variáveis Internas</mat-label>
                        <input type="text" matInput list="sysList" autocomplete="off" formControlName="sysValue" />
                    </mat-form-field>
                    <datalist id="sysList">
                        <option *ngFor="let sys of sysOptions" [value]="sys"></option>
                    </datalist>
                </div>
                <div *ngIf="user?.permissions?.canCreate" fxFlex="17.5" fxLayoutAlign="start center">
                    <button mat-button class="addTagButton" type="button" (click)="addSysVariable()"
                        [disabled]="!user?.permissions?.canUpdate || currentProjectService.isRunningMode()"
                        data-cy="add-entry">
                        <i class="material-icons"> add_circle </i> Adicionar variáveis internas 
                    </button>
                </div>
            </div>
            <!-- <div class="fuzzy-inputs" fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutAlign="start center"
                fxLayoutGap="15px">
            </div> -->

            <mat-divider></mat-divider>

            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxFlex>
                    <button mat-button class="whiteButton" [disabled]="!isEditing"
                        *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()" type="button"
                        (click)="delete()" data-cy="delete">
                        <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR EQUAÇÃO
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                <button mat-button class="blueButton" type="submit"
                    *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()" data-cy="save">
                    SALVAR
                </button>
            </div>
        </form>
    </div>
</div>