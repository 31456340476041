import { NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { from, lastValueFrom } from 'rxjs';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { InternVariables } from 'src/app/shared/utils/internVariables';

export function getMonacoConfig(tagService: TagService): NgxMonacoEditorConfig {
    return {
        baseUrl: '/assets',
        defaultOptions: {
            scrollBeyondLastLine: false,
            minimap: {
                enabled: true,
                size: 'fit',
                showSlider: 'mouseover',
                scale: 5,
                renderCharacters: true,
                maxColumn: 80,
            },
        },
        onMonacoLoad: () => initializeMonaco(tagService),
    };
}

function initializeMonaco(tagService: TagService): void {
    const monaco = (window as any).monaco;
    if (!monaco) {
        console.error('Monaco Editor was not loaded correctly.');
        return;
    }

    setupCustomLanguage(monaco);
    setupCustomTheme(monaco);
    setupCompletionProvider(monaco, tagService);
    setupValidation(monaco);
}

function setupCustomLanguage(monaco: any): void {
    const languageId = 'customLang';

    monaco.languages.register({ id: languageId });
    monaco.languages.setMonarchTokensProvider(languageId, getLanguageDefinition());
    monaco.languages.setLanguageConfiguration(languageId, getLanguageConfiguration());
}

function getLanguageDefinition(): any {
    return {
        tokenizer: {
            root: [
                [/\/\/.*/, 'comment'],
                [/tag(?=\()/, { token: 'tag', next: '@expectTagParens' }],
                [/sys(?=\()/, { token: 'sys', next: '@expectSysParens' }],
                [/\(/, 'paren'],
                [/\)/, 'paren'],
                [/\d+/, 'number'],
                [/tag\s[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, 'tag'],
                [/sys\s[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, 'sys'],
                [/\b(if|then|else|true|false|True|False|not|and|or)\b/, 'keyword'],
                [/[=><!+\-*/^]+/, 'operator'],
                [/[a-zA-Z_][\w]*/, 'identifier'],
                [/[()]/, '@brackets'],
            ],
            expectTagParens: [
                [/\(/, { token: 'paren', next: '@insideTagParens' }],
                [/.*/, { token: '', next: '@root' }],
            ],
            insideTagParens: [
                [/[^,)]+/, 'tag-content'],
                [/,/, { token: '', next: '@root' }],
                [/\)/, { token: 'paren', next: '@root' }],
            ],
            expectSysParens: [
                [/\(/, { token: 'paren', next: '@insideSysParens' }],
                [/.*/, { token: '', next: '@root' }],
            ],
            insideSysParens: [
                [/[^)]+/, 'tag-content'],
                [/\)/, { token: 'paren', next: '@root' }],
            ],
        },
    };
}

function getLanguageConfiguration(): any {
    return {
        autoClosingPairs: [
            { open: '(', close: ')' },
            { open: '[', close: ']' },
            { open: '{', close: '}' },
        ],
        surroundingPairs: [
            { open: '(', close: ')' },
            { open: '[', close: ']' },
            { open: '{', close: '}' },
            { open: '"', close: '"' },
            { open: "'", close: "'" },
        ],
        brackets: [
            ['{', '}'],
            ['[', ']'],
            ['(', ')'],
        ],
    };
}

function setupCustomTheme(monaco: any): void {
    monaco.editor.defineTheme('customTheme', {
        base: 'vs',
        inherit: true,
        rules: [
            { token: 'tag', foreground: '#0077aa' },
            { token: 'sys', foreground: '#FF77aa' },
            { token: 'keyword', foreground: '#0077aa', fontStyle: 'bold' },
            { token: 'tag-content', foreground: '#669900' },
            { token: 'paren', foreground: '#000000' },
            { token: 'number', foreground: '#990055' },
            { token: 'comment', foreground: '#909090', fontStyle: 'italic' },
            { token: 'operator', foreground: '000000', fontStyle: 'bold' },
        ],
        colors: {
            'minimap.background': '#FFFFFF',
            'editor.lineHighlightBackground': '#FBFBFB',
            'editor.lineHighlightBorder': '#FFFFFF',
            'editorOverviewRuler.border': '#CCCCCC',
            'editorLineNumber.foreground': '#A0A0A0',
        },
    });
}

function setupCompletionProvider(monaco: any, tagService: TagService): void {
    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: ['t'],
        provideCompletionItems: (model, position) => {
            return {
                suggestions: [
                    {
                        label: 'tag',
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: 'tag($1) $0',
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        detail: "-> tag(nome)",
                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: position.column - 1,
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        },
                        command: {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger Tag Suggestions',
                        },
                    },
                    {
                        label: 'tag com valor default',
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: 'tag($1, $2) $0', 
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        detail: "-> tag(nome, valor_default)",

                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: position.column - 1,
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        },
                        command: {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger Tag Suggestions',
                        },
                    },
                    {
                        label: 'tag com acesso à memória',
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: 'tag($1)[-$2] $0',
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        detail: "-> tag(nome)[-memória]",

                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: position.column - 1,
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        },
                        command: {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger Tag Suggestions',
                        },
                    },
                    {
                        label: 'tag com valor default e acesso à memória',                      
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: 'tag($1, $2)[-$3] $0',
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        detail: "-> tag(nome, valor_default)[-memória]",

                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: position.column - 1,
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        },
                        command: {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger Tag Suggestions',
                        },
                    },
                ],
            };
        },
    });
    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: ['('],
        provideCompletionItems: (model, position) => {
            const lineContent = model.getLineContent(position.lineNumber);
            const textUntilPosition = lineContent.substring(0, position.column - 1);

            const isInsideTag = textUntilPosition.match(/tag\([^)]*$/);
            if (!isInsideTag) return { suggestions: [] };

            const word = model.getWordUntilPosition(position);

            return new Promise(async (resolve) => {
                const tags = await lastValueFrom(tagService.getAllLocalTags());

                const tagNames = tags.map((tag) => tag.name);

                const suggestions = {
                    incomplete: true,
                    suggestions: tagNames.map((tag) => ({
                        label: tag,
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: tag,
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: word.startColumn,
                            endLineNumber: position.lineNumber,
                            endColumn: word.endColumn,
                        },
                    })),
                };

                resolve(suggestions);
            });
        },
    });

    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: ['s'],
        provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const textUntilPosition = model.getValueInRange({
                startLineNumber: position.lineNumber,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });

            if (!textUntilPosition.endsWith('s')) {
                return { suggestions: [] };
            }

            return {
                suggestions: [
                    {
                        label: 'sys',
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: 'sys($1)',
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        range: {
                            startLineNumber: position.lineNumber,
                            startColumn: position.column - 1, 
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        },
                        command: {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger sys Suggestions',
                        },
                    },
                ],
            };
        },
    });

    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: ['('],
        provideCompletionItems: (model, position) => {
            const textUntilPosition = model.getValueInRange({
                startLineNumber: position.lineNumber,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });
            const sysVariables = InternVariables.sysVariables;

            const word = model.getWordUntilPosition(position);

            const sysFunctionRegex = /sys\([^)]*$/;
            if (!sysFunctionRegex.test(textUntilPosition)) {
                return { suggestions: [] };
            }

            const suggestions = sysVariables.map((name) => {
                const description = name.substring(name.indexOf(' ') + 1) + " -> sys(nome)";
                const suggestion: any = {
                    label: `${name.split(' ')[0]}`,
                    kind: monaco.languages.CompletionItemKind.Text,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: `${name.split(' ')[0]}) `,
                    detail: description,
                    range: {
                        startLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endLineNumber: position.lineNumber,
                        endColumn: word.endColumn + 1,
                    },
                };

                if (
                    name === 'cycles_timestamp_ms Ciclos anteriores em memória' ||
                    name === 'cycles_timestamp_s Ciclos anteriores em memória'
                ) {
                    suggestion.insertText = `${name.split(' ')[0]})`,
                    suggestion.detail = suggestion.detail + ' -> sys(nome)[-memória]',
                    suggestion.command = {
                            id: 'editor.action.triggerSuggest',
                            title: 'Trigger Suggestion',
                        };
                }

                return suggestion;
            });

            return { suggestions };
        },
    });

    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: [')'],
        provideCompletionItems: (model, position) => {
            const brackets = ['Sem memória', 'Memória [- ]'];
            const lineContent = model.getLineContent(position.lineNumber);
            const textUntilPosition = lineContent.substring(0, position.column - 1);

            const isInsideTag = textUntilPosition.match(/sys\((cycles_timestamp_ms|cycles_timestamp_s)\)$/);
            if (!isInsideTag) return { suggestions: [] };

            const suggestions = {
                suggestions: brackets.map((bracket) => ({
                    label: bracket,
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    insertText: bracket === 'Sem memória' ? ' ' : '[-$1] ',
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: {
                        startLineNumber: position.lineNumber,
                        startColumn: position.column,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    },
                })),
            };
            return new Promise((resolve) => resolve(suggestions));
        },
    });

    monaco.languages.registerCompletionItemProvider('customLang', {
        triggerCharacters: ['t', 's', 'i', 'e', 'n', 'f', '('],
        provideCompletionItems: (model, position) => {
            const text = getTextUntilPosition(model, position);

            const keywordsWithParentheses = ['if', 'else'];
            const otherKeywords = ['then', 'true', 'false', 'True', 'False', 'not', 'and', 'or'];

            const lastWord = text.split(/\s+/).pop();

            const suggestionsWithParentheses = keywordsWithParentheses
                .filter((keyword) => keyword.startsWith(lastWord))
                .map((keyword) => ({
                    label: keyword,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertText: `${keyword}($1)`,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: {
                        startLineNumber: position.lineNumber,
                        startColumn: position.column - (lastWord?.length || 0),
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    },
                }));

            const suggestionsWithoutParentheses = otherKeywords
                .filter((keyword) => keyword.startsWith(lastWord))
                .map((keyword) => ({
                    label: keyword,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertText: keyword,
                    range: {
                        startLineNumber: position.lineNumber,
                        startColumn: position.column - (lastWord?.length || 0),
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    },
                }));

            const suggestions = { suggestions: [...suggestionsWithParentheses, ...suggestionsWithoutParentheses] };

            return suggestions;
        },
    });
}

function getTextUntilPosition(model: any, position: any): string {
    return model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
    });
}

function setupValidation(monaco: any): void {
    monaco.editor.onDidCreateModel((model: any) => {
        model.onDidChangeContent(() => validateModel(model, monaco));
    });
}

function validateModel(model: any, monaco: any): void {
    // const text = model.getValue(); //? Retorna todo o texto atual até a posição do cursor
    const markers: any[] = [];
    const stack: { char: string; line: number; column: number }[] = [];

    for (let lineIndex = 0; lineIndex < model.getLineCount(); lineIndex++) {
        const line = model.getLineContent(lineIndex + 1);

        for (let columnIndex = 0; columnIndex < line.length; columnIndex++) {
            const char = line[columnIndex];

            if (char === '(') {
                stack.push({ char, line: lineIndex + 1, column: columnIndex + 1 });
            } else if (char === ')') {
                if (stack.length > 0 && stack[stack.length - 1].char === '(') {
                    stack.pop();
                } else {
                    markers.push(createMarker(lineIndex + 1, columnIndex + 1, 'Unmatched closing parenthesis.'));
                }
            }
        }
    }

    stack.forEach((item) => {
        markers.push(createMarker(item.line, item.column, 'Unmatched opening parenthesis.'));
    });

    monaco.editor.setModelMarkers(model, 'customLang', markers);
}

function createMarker(line: number, column: number, message: string): any {
    return {
        startLineNumber: line,
        startColumn: column,
        endLineNumber: line,
        endColumn: column + 1,
        message,
        severity: 8,
    };
}
