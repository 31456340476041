<div class="container-title" fxLayout="row" fxLayoutAlign="start center">
    <div class="title">Configurações de Administrador</div>
</div>

<button mat-icon-button class="close-button" tabindex="-1" (click)="close()">
    <mat-icon>close</mat-icon>
</button>
<div *ngIf="user" class="container body" fxLayout="column">
    <form [formGroup]="opcSettingsForm">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let opcServer of opcServers; let indexOfelement = index"
                (opened)="currentOpcServer(opcServer)" (closed)="disableOpcServerEdit()">
                <mat-expansion-panel-header collapsedHeight="80px">
                    <mat-panel-title class="accordion-title"> Servidor OPC {{ indexOfelement + 1 }} </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" class="field-spacing">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="name" data-cy="name" />
                    <mat-error *ngIf="
                                opcSettingsForm.get('name').invalid &&
                                (opcSettingsForm.get('name').dirty ||
                                    opcSettingsForm.get('name').touched)
                            " class="limit-error">
                        O nome é obrigatório
                    </mat-error>
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>Host</mat-label>
                        <input matInput type="text" formControlName="host" data-cy="host" />
                        <mat-error *ngIf="
                                opcSettingsForm.get('host').invalid &&
                                (opcSettingsForm.get('host').dirty || opcSettingsForm.get('host').touched)
                            " class="limit-error">
                            <!-- Limite de 250 caracteres -->
                            O host é obrigatório
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>Domínio</mat-label>
                        <input matInput type="text" formControlName="domain" data-cy="domain" />
                        <mat-hint *ngIf="
                                opcSettingsForm.get('domain').invalid &&
                                (opcSettingsForm.get('domain').dirty || opcSettingsForm.get('domain').touched)
                            " class="limit-error">Limite de 100 caracteres</mat-hint>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>Tipo de Chave</mat-label>
                        <mat-select placeholder="Selecione o tipo de variável" formControlName="key_type"
                            (selectionChange)="clearIdentifier()" data-cy="key-type">
                            <mat-option *ngFor="let item of identifiers" [value]="item">
                                {{ item }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>{{ dynamicLabel }}</mat-label>
                        <input matInput type="text" formControlName="identifier" data-cy="identifier" />
                        <mat-hint *ngIf="
                                opcSettingsForm.get('identifier').invalid &&
                                (opcSettingsForm.get('identifier').dirty || opcSettingsForm.get('identifier').touched)
                            " class="limit-error">Limite de 100 caracteres</mat-hint>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>Usuário</mat-label>
                        <input matInput type="text" formControlName="user" data-cy="username" />
                        <mat-hint *ngIf="
                                opcSettingsForm.get('user').invalid &&
                                (opcSettingsForm.get('user').dirty || opcSettingsForm.get('user').touched)
                            " class="limit-error">Limite de 100 caracteres</mat-hint>
                        <span matSuffix><i class="material-icons">person</i></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-spacing">
                        <mat-label>Senha</mat-label>
                        <input matInput type="password" formControlName="password" data-cy="password" />
                        <mat-hint *ngIf="
                                opcSettingsForm.get('password').invalid &&
                                (opcSettingsForm.get('password').dirty || opcSettingsForm.get('password').touched)
                            " class="limit-error">Limite de 100 caracteres</mat-hint>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-button (click)="removeOPCServer(opcServers[indexOfelement])" type="button"
                        class="delete-button" *ngIf="buttons?.opcSettings?.delete">
                        DELETAR
                    </button>
                    <button mat-stroked-button (click)="enableOpcFormEdit()" color="primary" type="button"
                        *ngIf="buttons?.opcSettings?.edit" class="edit-button">
                        EDITAR
                    </button>
                    <button mat-button class="cancelButton" type="button" *ngIf="isEditingOPCServer"
                        (click)="disableOpcServerEdit()">
                        CANCELAR
                    </button>
                    <button mat-flat-button color="primary" type="submit" *ngIf="isEditingOPCServer"
                        [disabled]="!opcSettingsForm.valid"
                        (click)="onSaveOPCServerSettings(opcServers[indexOfelement])">
                        SALVAR
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>

    <div class="div-button-container" fxLayoutAlign="center center">
        <button *ngIf="user?.permissions?.canCreate" class="button-container" mat-button color="primary" type="button"
            (click)="addOPCServerForm()">
            ADICIONAR SERVIDOR
        </button>
    </div>

    <form [formGroup]="quarterbackForm" (ngSubmit)="onSaveQuarterbackConfigs()">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header collapsedHeight="80px">
                    <mat-panel-title class="accordion-title"> QuarterBack </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="outline">
                        <mat-label>Host</mat-label>
                        <input matInput type="text" formControlName="quarterback_host" data-cy="quarterback_host" />
                        <mat-error *ngIf="
                                quarterbackForm.get('quarterback_host').invalid &&
                                (quarterbackForm.get('quarterback_host').dirty ||
                                    quarterbackForm.get('quarterback_host').touched)
                            " class="limit-error">
                            O host é obrigatório
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Porta</mat-label>
                        <input matInput type="number" formControlName="quarterback_port" data-cy="quarterback-port" />
                        <mat-error *ngIf="
                                quarterbackForm.get('quarterback_port').invalid &&
                                (quarterbackForm.get('quarterback_port').dirty ||
                                    quarterbackForm.get('quarterback_port').touched)
                            " class="limit-error">
                            A porta é obrigatória
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="outline">
                        <mat-label>Tempo de Ciclo</mat-label>
                        <div fxLayout="row">
                            <div fxFlex="80%">
                                <input matInput type="number" pattern="\d+" min="1" formControlName="collector_time"
                                    data-cy="collector-time" />
                            </div>
                            <div fxFlex fxLayoutAlign="end">s</div>
                        </div>
                        <mat-error *ngIf="
                                quarterbackForm.get('collector_time').invalid &&
                                (quarterbackForm.get('collector_time').dirty ||
                                    quarterbackForm.get('collector_time').touched)
                            " class="limit-error">
                            O tempo de ciclo é obrigatório
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px" class="buttons">
                    <button mat-button class="cancelButton" type="button" *ngIf="isEditingQuarterback"
                        (click)="disableQuarterbackEdit()">
                        CANCELAR
                    </button>
                    <button mat-stroked-button (click)="enableQuarterbackEdit()" color="primary" type="button"
                        *ngIf="buttons?.quarterback?.edit" class="edit-button">
                        EDITAR
                    </button>
                    <button mat-button class="blueButton" type="submit" data-cy="save" *ngIf="isEditingQuarterback"
                        [disabled]="!quarterbackForm.valid">
                        SALVAR
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>