import { Component, ElementRef } from '@angular/core';

@Component({
    selector: 'app-ellipsis-text',
    templateUrl: './ellipsis-text.component.html',
    styleUrls: ['./ellipsis-text.component.scss'],
})
export class EllipsisTextComponent {
    tooltipText: string | null = null;

    constructor(private element: ElementRef) {}

    ngAfterContentInit(): void {
        this.checkOverflow();
    }

    private checkOverflow(): void {
        const element = this.element.nativeElement.querySelector('.limited-text');

        this.tooltipText = null;
        
        if (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
            this.tooltipText = element.innerText.trim();
        }
    }
}

