import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/components/dialogs/delete-dialog/delete-dialog.component';
import { FormulaDialogComponent } from 'src/app/shared/components/views-components/formula-dialog/formula-dialog.component';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { SPFormula } from 'src/app/shared/models/views-models/spFormula.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { SetPointService } from 'src/app/shared/service/views-services/setpoint.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { OpcServer } from './../../shared/models/views-models/opcServer.model';
import { OpcServerService } from './../../shared/service/views-services/opcServer.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { SelectOption, UserPermission } from 'src/app/shared/models/views-models/user.model';
import { ErrorFlowEnum } from 'src/app/shared/models/enum/errorFlow.enum';

@Component({
    selector: 'app-increment-setpoint',
    templateUrl: './increment-setpoint.component.html',
    styleUrls: ['./increment-setpoint.component.scss'],
})
export class IncrementSetpointComponent implements OnInit {
    successMessage = MessagesEnum.SuccessMessage;
    failureNameMessage = MessagesEnum.FailureNameMessage;
    formAlertMessage = MessagesEnum.invalidFormMessage;
    deleteMessage = MessagesEnum.DeleteMessage;
    invalidStateMessage: string;

    parameterTypes = ['Constante', 'Variável'];
    executionTypes = [{ name: 'Síncrona', value: true }];

    fuzzyOptions = [];
    actualTagOptions = [];
    pvOptions = [];
    limitOptions = [];
    mvList: Process[];

    incrementSPForm: UntypedFormGroup;
    setpoint = new IncrementSetpoint();
    isEditing = false;
    user: UserPermission;
    currentName: string;
    currentOPCAddress: string;
    opcServers: OpcServer[];
    currentOpcServer: OpcServer;
    currentOpcStatus: OpcServer;

    currentSetpoint: string;
    currentIncDec: string;
    currentIncFactor: string;
    currentDecFactor: string;
    currentMaxLimit: string;
    currentMinLimit: string;
    currentMv: string;
    currentPv: string;

    isDecConst = false;
    isIncConst = false;
    isActive = false;
    isLimitConst = true;
    isSpSync = true;
    mvControl: boolean;
    isActSpMV: boolean;

    dialogRef: any;
    cycleTime: any;
    dynamicTime: number;

    spMin: string;
    showMinLabel = false;
    spMax: string;
    showMaxLabel = false;
    defaultDialog = {
        component: ConfirmDialogComponent,
        panelClass: 'pop-up-dialog-container',
        width: 'auto',
        height: 'auto',
        data: {
            message: '',
        },
    };

    tagsErrorDialog = {
        component: ConfirmDialogComponent,
        panelClass: 'custom-dialog-container',
        width: 'auto',
        height: 'auto',
        data: {
            message: '',
        },
    };

    resetPvField: boolean = false;
    resetMvField: boolean = false;

    data: IncrementSetpoint;
    selectedSetpointId: string;
    errorFlowOptions: SelectOption[] = ErrorFlowEnum.values.map(option => ({
        value: option.value,
        label: ErrorFlowEnum.getLabel(option.value),
        description: ErrorFlowEnum.getDescription(option.value),
    }));
      
    constructor(
        private router: Router,
        private activatedRouter: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private processService: ProcessInputService,
        private opcServerService: OpcServerService,
        private spService: SetPointService,
        private projectService: ProjectService,
        public currentProjectService: CurrentProjectService,
        private authService: AuthService,

        private tagService: TagService
    ) { }

    async ngOnInit() {

        this.user = await this.authService.getUserPermission()

        this.initForm();
        this.selectedSetpointId = this.activatedRouter.snapshot?.fragment;
        if (this.selectedSetpointId) {
            this.data = await this.spService.getById(this.selectedSetpointId).toPromise();
            if (this.data.max_limit_tag) this.setLimitType();
        }
        this.isEditing = Boolean(this.selectedSetpointId) && Boolean(this.data);

        await this.loadData();
        if (this.data?.id) {
            this.setpoint = this.data;
            this.currentName = this.setpoint.new_sp_tag.name;
            this.currentOPCAddress = this.setpoint.opc_address;
            this.createEditForm(this.data);
        }
        if (!this.user?.permissions.canCreate) {
            this.incrementSPForm.controls['actualTag'].disable();
            this.incrementSPForm.controls['incrementTag'].disable();
        }
        if (!this.user?.permissions.canUpdate) {
            this.incrementSPForm.controls['spStatus'].disable();
        }
        if (this.currentProjectService.isRunningMode()) {
            this.incrementSPForm.controls['actualTag'].disable();
            this.incrementSPForm.controls['incrementTag'].disable();
            this.incrementSPForm.controls['spStatus'].disable();
        }
        this.calculateTime();
        this.onErrorFlowChange(this.setpoint.selectErrorFlow);
    }
    onErrorFlowChange(value: any) {
        this.incrementSPForm.get('selectErrorFlow').setValue(value);
        if(value === ErrorFlowEnum.ERROR){
            this.incrementSPForm.get('defaultValue').disable();
        }
        else{
            this.incrementSPForm.get('defaultValue').enable();
        }

      }

    changeDecInput() {
        this.isDecConst = !this.isDecConst;
        this.incrementSPForm.patchValue({
            decFactor: '',
        });
    }

    changeIncInput() {
        this.isIncConst = !this.isIncConst;
        this.incrementSPForm.patchValue({
            incFactor: '',
        });
    }

    changeExecutionType() {
        this.isSpSync = !this.isSpSync;
        this.calculateTime();
    }

    setLimitType() {
        this.isLimitConst = !this.isLimitConst;
        if (!this.isLimitConst) {
            this.incrementSPForm.patchValue({
                inputMin: '',
                inputMax: '',
            });
        }
    }

    calculateTime() {
        const incrementSPFormData = this.incrementSPForm.getRawValue();
        let currenteCycle = this.incrementSPForm.controls['inputCycle'].valid || this.isShowForm() ? incrementSPFormData.inputCycle : 1;
        this.dynamicTime = currenteCycle * this.cycleTime;
    }

    setDefaultOptions() {
        this.incrementSPForm.patchValue({
            toggleFormulaInc: false,
            toggleFormulaDec: false,
            spStatus: false,
            inputCycle: 1,
            limitType: 'Constante',
            executionType: false,
            incFactorType: 'Constante',
            decFactorType: 'Constante',
            incFactor: 1,
            decFactor: 1,
        });
        this.setpoint.inc_formula.inc_dec = 'inc';
        this.setpoint.dec_formula.inc_dec = 'dec';
    }

    async loadData() {
        const waitForCycleTime = async (): Promise<number> => {
            let cycleTime = this.projectService.getCycleTime().toPromise();
            cycleTime.then((cycle) => {
                this.cycleTime = cycle;
            });
            return cycleTime;
        };
        const waitForActualTagOptions = async (): Promise<Process[]> => {
            let spList = this.processService.getAllProcessesByType(VariableTypeEnum.SETPOINT).toPromise();
            spList.then((sp) => {
                this.actualTagOptions.push(...sp);
            });
            return spList;
        };
        const waitForFuzzy = async (): Promise<Tag[]> => {
            let fuzzyList = this.tagService.getAllTagsByType(VariableTypeEnum.FUZZY).toPromise();
            fuzzyList.then((fuzzy) => {
                this.fuzzyOptions.push(...fuzzy);
            });
            return fuzzyList;
        };
        const waitForWeighted = async (): Promise<Tag[]> => {
            let weightedList = this.tagService.getAllTagsByType(VariableTypeEnum.WEIGHTED).toPromise();
            weightedList.then((weighted) => {
                this.fuzzyOptions.push(...weighted);
            });
            return weightedList;
        };

        const waitForEquations = async (): Promise<Tag[]> => {
            let equationList = this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION).toPromise();
            equationList.then((equationsTag) => {
                this.fuzzyOptions.push(...equationsTag);
                this.limitOptions.push(
                    ...equationsTag.map((eq) => {
                        return {
                            tag: eq,
                        };
                    })
                );
            });
            return equationList;
        };
        const waitForCalculated = async (): Promise<Process[]> => {
            let calculatedList = this.processService.getAllProcessesByType(VariableTypeEnum.ANALOGIC).toPromise();
            calculatedList.then((processes) => {
                this.pvOptions = processes;
            });
            return calculatedList;
        };
        const waitForLimits = async (): Promise<Process[]> => {
            let limitList = this.processService.getAllProcessesByType(VariableTypeEnum.LIMIT).toPromise();
            limitList.then((limit) => {
                this.limitOptions.push(...limit);
                //this.limitOptions = limit.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
            });
            return limitList;
        };
        const waitForMvControl = async (): Promise<boolean> => {
            let isMvEnabled = this.projectService
                .isMVControlEnabled(this.currentProjectService.getCurrentProject().id)
                .toPromise();
            isMvEnabled.then((mvControl) => (this.mvControl = mvControl.valueOf()));
            return (await isMvEnabled).valueOf();
        };
        const waitForMvList = async (): Promise<Process[]> => {
            let mvList = this.processService.getAllProcessesByType(VariableTypeEnum.MANIPULATED_VARIABLE).toPromise();
            mvList.then((mv) => {
                this.mvList = mv;
            });
            return mvList;
        };
        const waitForOpcServers = async (): Promise<OpcServer[]> => {
            let opcServers = this.opcServerService.getAllOpcServers().toPromise();
            opcServers.then((opcServers) => {
                this.opcServers = opcServers;
            });
            return opcServers;
        };

        await Promise.all([
            waitForActualTagOptions(),
            waitForFuzzy(),
            waitForWeighted(),
            waitForLimits(),
            waitForCycleTime(),
            waitForMvControl(),
            waitForMvList(),
            waitForCalculated(),
            waitForEquations(),
            waitForOpcServers(),
        ]);
        this.calculateTime();
        this.fuzzyOptions = this.fuzzyOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.limitOptions = this.limitOptions.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
        if (this.mvControl) {
            this.actualTagOptions.push(...this.mvList);
        }
        this.actualTagOptions = this.actualTagOptions.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
        this.incrementSPForm.get('actualTag').valueChanges.subscribe((actSp) => {
            let setpoint = this.actualTagOptions.find((sp) => sp.tag.name == actSp);
            let actSpIsMv = this.mvList.find((mv) => mv.tag.name == actSp);
            this.isActSpMV = setpoint && actSpIsMv ? true : false;
        });

        if (!this.isEditing) {
            this.changeDecInput();
            this.changeIncInput();
            this.setDefaultOptions();
        } else {
            this.isActSpMV = this.mvList.find((mv) => mv.tag.name == this.setpoint.act_sp.name) ? true : false;
            this.getMinMax(this.data.act_sp.name);
            this.currentIncDec = this.setpoint.increment_tag.name;
            if (this.setpoint.inc_factor.factor_tag) {
                this.currentIncFactor = this.setpoint.inc_factor.factor_tag.name;
            }
            if (this.setpoint.dec_factor.factor_tag) {
                this.currentDecFactor = this.setpoint.dec_factor.factor_tag.name;
            }
            if (this.setpoint.max_limit_tag) {
                this.currentMaxLimit = this.setpoint.max_limit_tag.name;
            }
            if (this.setpoint.min_limit_tag) {
                this.currentMinLimit = this.setpoint.min_limit_tag.name;
            }
            this.onErrorFlowChange(ErrorFlowEnum.ERROR);
        }
        
    }

    initForm() {
        this.incrementSPForm = this.formBuilder.group({
            inputName: [null, [Validators.required]],
            inputDesc: [null, [Validators.required]],
            inputOPC: [null, [Validators.required]],
            statusOPC: [null, [Validators.required]],
            opcServerAddress: [null, [Validators.required]],
            opcServerStatus: [null, [Validators.required]],
            spStatus: [null, [Validators.required]],
            actualTag: [null, [Validators.required]],
            incrementTag: [null, [Validators.required]],
            inputCycle: [null, [Validators.required, Validators.min(1)]],
            toggleFormulaInc: [null, [Validators.required]],
            toggleFormulaDec: [null, [Validators.required]],
            incFactorType: [null, [Validators.required]],
            decFactorType: [null, [Validators.required]],
            incFactor: [null, [Validators.required]],
            decFactor: [null, [Validators.required]],
            inputMin: [null, [Validators.required]],
            inputMax: [null, [Validators.required]],
            limitType: [null, [Validators.required]],
            executionType: [null, [Validators.required]],
            mvInput: [null],
            pvInput: [null],
            selectErrorFlow: [null, [Validators.required]],
            defaultValue:[null,  Validators.minLength(1)],
        });
        if (!this.user?.permissions.canUpdate || this.isShowForm()) {
            this.incrementSPForm.disable();
        }

    }

    onSubmit() {
        let conf = this.defaultDialog;
        this.transformDataToDto();
        if(this.setpoint.selectErrorFlow === ErrorFlowEnum.ERROR){
            this.setpoint.defaultValue = '';
        }
        if (this.validateMinMax()) {
            if (!this.validateStates()) {
                conf.data.message = this.invalidStateMessage;
                this.openDialog(conf);
            } else if (this.user?.permissions.canUpdate) {
                if (this.isEditing) {
                    this.spService.updateIncrementSetPoint(this.setpoint).subscribe((sp) => {
                        if (sp) {
                            this.setpoint.inc_formula.id = sp.inc_formula.id;
                            this.setpoint.dec_formula.id = sp.dec_formula.id;
                            conf.data.message = this.successMessage;
                            //TODO: Implement here flag to reload quarterback.
                        } else {
                            conf.data.message = this.failureNameMessage;
                        }
                        this.openDialog(conf);
                    });
                } else {
                    this.spService.newIncrementSetPoint(this.setpoint).subscribe((sp) => {
                        if (sp) {
                            this.setpoint = sp;
                            conf.data.message = this.successMessage;
                            this.isEditing = true;
                            //TODO: Implement here flag to reload quarterback.
                        } else {
                            conf.data.message = this.failureNameMessage;
                        }
                        this.openDialog(conf);
                    });
                }
            }
        }
    }

    getMinMax(tag) {
        let setpoint = this.actualTagOptions.find((sp) => sp.tag.name == tag);
        if (setpoint) {
            this.spMax = setpoint.tag.max.toString();
            this.spMin = setpoint.tag.min.toString();
            this.currentSetpoint = setpoint.tag.name;
        } else {
            this.incrementSPForm.patchValue({ actualTag: this.currentSetpoint });
        }
    }

    resetActualSP() {
        this.currentSetpoint = this.incrementSPForm.get('actualTag').value
            ? this.incrementSPForm.get('actualTag').value
            : this.currentSetpoint;
        this.incrementSPForm.patchValue({ actualTag: '' });
    }

    setIncDec(tag) {
        let tagOption = this.fuzzyOptions.find((fuzzy) => fuzzy.name == tag);
        if (tagOption) {
            this.currentIncDec = tagOption.name;
        } else {
            this.incrementSPForm.patchValue({ incrementTag: this.currentIncDec });
        }
    }

    resetIncDec() {
        this.currentIncDec = this.incrementSPForm.get('incrementTag').value
            ? this.incrementSPForm.get('incrementTag').value
            : this.currentIncDec;
        this.incrementSPForm.patchValue({ incrementTag: '' });
    }

    setIncFactor(tag) {
        let tagOption = this.fuzzyOptions.find((fuzzy) => fuzzy.name == tag);
        if (tagOption) {
            this.currentIncFactor = tagOption.name;
        } else if (!this.isIncConst) {
            this.incrementSPForm.patchValue({ incFactor: this.currentIncFactor });
        }
    }

    resetIncFactor() {
        if (!this.isIncConst) {
            this.currentIncFactor = this.incrementSPForm.get('incFactor').value
                ? this.incrementSPForm.get('incFactor').value
                : this.currentIncFactor;
            this.incrementSPForm.patchValue({ incFactor: '' });
        }
    }

    setDecFactor(tag) {
        let tagOption = this.fuzzyOptions.find((fuzzy) => fuzzy.name == tag);
        if (tagOption) {
            this.currentDecFactor = tagOption.name;
        } else if (!this.isDecConst) {
            this.incrementSPForm.patchValue({ decFactor: this.currentDecFactor });
        }
    }

    resetDecFactor() {
        if (!this.isDecConst) {
            this.currentDecFactor = this.incrementSPForm.get('decFactor').value
                ? this.incrementSPForm.get('decFactor').value
                : this.currentDecFactor;
            this.incrementSPForm.patchValue({ decFactor: '' });
        }
    }

    setMv(tagName) {
        let tagOption = this.mvList.find((mv) => mv.tag.name == tagName);
        if (tagOption) {
            this.currentMv = tagOption.tag.name;
        }
        if (this.currentMv && !this.resetMvField) {
            this.incrementSPForm.patchValue({ mvInput: this.currentMv });
        }
        this.resetMvField = false;
    }

    resetMv() {
        this.incrementSPForm.patchValue({ mvInput: '' });
    }

    setPv(tagName) {
        let tagOption = this.pvOptions.find((pv) => pv.tag.name == tagName);
        if (tagOption) {
            this.currentPv = tagOption.tag.name;
        }
        if (this.currentPv && !this.resetPvField) {
            this.incrementSPForm.patchValue({ pvInput: this.currentPv });
        }
        this.resetPvField = false;
    }

    resetPv() {
        this.incrementSPForm.patchValue({ pvInput: '' });
    }

    clearPv() {
        this.resetPvField = true;
    }

    clearMv() {
        this.resetMvField = true;
    }

    setMaxLimit(tag) {
        let tagOption = this.limitOptions.find((limit) => limit.tag.name == tag);
        if (tagOption) {
            this.currentMaxLimit = tagOption.name;
        } else if (!this.isLimitConst) {
            this.incrementSPForm.patchValue({ inputMax: this.currentMaxLimit });
        }
        this.showMaxLabel = false;
    }

    resetMaxLimit() {
        if (!this.isLimitConst) {
            this.currentMaxLimit = this.incrementSPForm.get('inputMax').value
                ? this.incrementSPForm.get('inputMax').value
                : this.currentMaxLimit;
            this.incrementSPForm.patchValue({ inputMax: '' });
        }
        const SP = this.validateTag(this.currentSetpoint);
        const isIntputWithLimitVariable = SP.tagMax && SP.tagMin;
        if (isIntputWithLimitVariable) {
            this.spMax = this.parameterTypes[1].toLowerCase();
        }
        this.showMaxLabel = true;
        this.resetLimitsInput();
    }

    setMinLimit(tag) {
        let tagOption = this.limitOptions.find((limit) => limit.tag.name == tag);
        if (tagOption) {
            this.currentMinLimit = tagOption.name;
        } else if (!this.isLimitConst) {
            this.incrementSPForm.patchValue({ inputMin: this.currentMinLimit });
        }
        this.showMinLabel = false;
    }

    resetMinLimit() {
        if (!this.isLimitConst) {
            this.currentMinLimit = this.incrementSPForm.get('inputMin').value
                ? this.incrementSPForm.get('inputMin').value
                : this.currentMinLimit;
            this.incrementSPForm.patchValue({ inputMin: '' });
        }
        const SP = this.validateTag(this.currentSetpoint);
        const isIntputWithLimitVariable = SP.tagMax && SP.tagMin;
        if (isIntputWithLimitVariable) {
            this.spMin = this.parameterTypes[1].toLowerCase();
        }
        this.showMinLabel = true;
        this.resetLimitsInput();
    }

    setOpcServer(opcServer) {
        if (opcServer) {
            const opcServerOption = this.opcServers.find((element) => element.name == opcServer);
            if (opcServerOption) {
                this.incrementSPForm.patchValue({ opcServerAddress: opcServerOption.name });
            }
        } else {
            this.incrementSPForm.patchValue({ opcServerAddress: this.currentOpcServer.name });
        }
    }

    resetInputOpcServer() {
        if (this.isEditing) {
            this.setpoint.opc_server_adress = this.incrementSPForm.get('opcServerAddress').value
                ? this.incrementSPForm.get('opcServerAddress').value
                : this.currentOpcServer.name;
        }

        this.incrementSPForm.patchValue({ opcServerAddress: '' });
        this.incrementSPForm.controls['opcServerAddress'].setErrors(null);
    }

    setOpcServerStatus(opcServer) {
        if (opcServer) {
            const opcServerOption = this.opcServers.find((element) => element.name == opcServer);
            if (opcServerOption) {
                this.incrementSPForm.patchValue({ opcServerStatus: opcServerOption.name });
            }
        } else {
            this.incrementSPForm.patchValue({ opcServerStatus: this.currentOpcServer.name });
        }
    }

    resetInputOpcServerStatus() {
        if (this.isEditing) {
            this.setpoint.opc_server_adress = this.incrementSPForm.get('opcServerStatus').value
                ? this.incrementSPForm.get('opcServerStatus').value
                : this.currentOpcServer.name;
        }

        this.incrementSPForm.patchValue({ opcServerStatus: '' });
        this.incrementSPForm.controls['opcServerStatus'].setErrors(null);
    }

    createEditForm(data: any) {
        let incFactor;
        let decFactor;
        let max;
        let min;
        this.currentOpcServer = this.opcServers.find(({ id }) => id === this.setpoint.opc_server_adress);
        this.currentOpcStatus = this.opcServers.find(({ id }) => id === this.setpoint.opc_server_status);
        this.isEditing = true;

        if (data.max_limit_tag) {
            this.incrementSPForm.patchValue({ limitType: 'Variável' });
            this.isLimitConst = false;
            max = data.max_limit_tag.name;
            min = data.min_limit_tag.name;
        } else {
            this.incrementSPForm.patchValue({ limitType: 'Constante' });
            max = data.new_sp_tag.max;
            min = data.new_sp_tag.min;
        }
        if (data.inc_factor.value != null) {
            this.incrementSPForm.patchValue({ incFactorType: 'Constante' });
            this.isIncConst = true;
            incFactor = data.inc_factor.value;
        } else {
            this.incrementSPForm.patchValue({ incFactorType: 'Variável' });
            this.isIncConst = false;
            incFactor = data.inc_factor.factor_tag.name;
        }

        if (data.dec_factor.value != null) {
            this.incrementSPForm.patchValue({ decFactorType: 'Constante' });
            this.isDecConst = true;
            decFactor = data.dec_factor.value;
        } else {
            this.incrementSPForm.patchValue({ decFactorType: 'Variável' });
            this.isDecConst = false;
            decFactor = data.dec_factor.factor_tag.name;
        }

        this.incrementSPForm.patchValue({
            inputName: data.new_sp_tag.name,
            inputDesc: data.new_sp_tag.description,
            inputMax: max,
            inputMin: min,
            actualTag: data.act_sp.name,
            incrementTag: data.increment_tag.name,
            incFactor: incFactor,
            decFactor: decFactor,
            inputCycle: data.cycles,
            executionType: data.sync_inc,
            toggleFormulaInc: data.inc_formula ? data.inc_formula.activate : false,
            toggleFormulaDec: data.dec_formula ? data.dec_formula.activate : false,
            opcServerAddress: this.currentOpcServer.name,
            opcServerStatus: this.currentOpcStatus.name,
            inputOPC: data.opc_address,
            statusOPC: data.opc_status,
            spStatus: data.active,
            mvInput: data.mv_tag ? data.mv_tag.name : '',
            pvInput: data.pv_tag ? data.pv_tag.name : '',
            selectErrorFlow: data.selectErrorFlow,
            defaultValue: data.defaultValue,
        });

        this.setpoint.inc_formula = this.setpoint.inc_formula ? this.setpoint.inc_formula : new SPFormula();
        this.setpoint.dec_formula = this.setpoint.dec_formula ? this.setpoint.dec_formula : new SPFormula();
    }

    transformDataToDto() {
        const incrementSPFormData = this.incrementSPForm.getRawValue();
        this.resetFactorErrors();
        this.setpoint.new_sp_tag.name = incrementSPFormData.inputName;
        this.setpoint.new_sp_tag.description = incrementSPFormData.inputDesc;
        this.setpoint.sync_inc = this.isSpSync;
        this.setpoint.inc_formula.activate = incrementSPFormData.toggleFormulaInc;
        this.setpoint.dec_formula.activate = incrementSPFormData.toggleFormulaDec;
        this.setpoint.selectErrorFlow = incrementSPFormData.selectErrorFlow;
        this.setpoint.defaultValue = incrementSPFormData.defaultValue;
        const opcServerAdressId = this.opcServers.find(
            (opcServer) => opcServer.name === incrementSPFormData.opcServerAddress
        ).id;
        const opcServerStatusId = this.opcServers.find(
            (opcServer) => opcServer.name === incrementSPFormData.opcServerStatus
        ).id;

        if (incrementSPFormData.limitType == 'Constante') {
            this.setpoint.new_sp_tag.max = parseFloat(incrementSPFormData.inputMax);
            this.setpoint.new_sp_tag.min = parseFloat(incrementSPFormData.inputMin);
            this.setpoint.max_limit_tag = null;
            this.setpoint.min_limit_tag = null;
        } else {
            if (this.validateTag(incrementSPFormData.inputMax)) {
                this.setpoint.max_limit_tag = this.limitOptions.find(
                    (limit) => limit.tag.name == incrementSPFormData.inputMax
                ).tag;
            } else {
                this.incrementSPForm.patchValue({ inputMax: '' });
            }
            if (this.validateTag(incrementSPFormData.inputMin)) {
                this.setpoint.min_limit_tag = this.limitOptions.find(
                    (limit) => limit.tag.name == incrementSPFormData.inputMin
                ).tag;
            } else {
                this.incrementSPForm.patchValue({ inputMin: '' });
            }
        }

        if (this.validateTag(incrementSPFormData.actualTag)) {
            this.setpoint.act_sp = this.validateTag(incrementSPFormData.actualTag).tag;
        } else {
            this.incrementSPForm.patchValue({ actualTag: '' });
        }

        if (this.validateTag(incrementSPFormData.incrementTag)) {
            this.setpoint.increment_tag = this.validateTag(incrementSPFormData.incrementTag);
        } else {
            this.incrementSPForm.patchValue({ incrementTag: '' });
        }

        this.setpoint.cycles = incrementSPFormData.inputCycle;
        this.setpoint.opc_address = incrementSPFormData.inputOPC;
        this.setpoint.opc_server_adress = opcServerAdressId;
        this.setpoint.opc_status = incrementSPFormData.statusOPC;
        this.setpoint.opc_server_status = opcServerStatusId;
        this.setpoint.active = incrementSPFormData.spStatus;

        this.setpoint.pv_tag = incrementSPFormData.pvInput
            ? this.pvOptions.find((pv) => pv.tag.name == incrementSPFormData.pvInput).tag
            : null;
        this.setpoint.mv_tag =
            incrementSPFormData.mvInput && !this.isActSpMV
                ? this.mvList.find((mv) => mv.tag.name == incrementSPFormData.mvInput).tag
                : null;

        let incFactor = incrementSPFormData.incFactor;
        if (incrementSPFormData.incFactorType == 'Constante') {
            if (incFactor >= 0) {
                this.setpoint.inc_factor.value = parseFloat(incFactor);
                this.setpoint.inc_factor.factor_tag = null;
            } else {
                this.incrementSPForm.patchValue({ incFactor: '' });
            }
        } else {
            if (this.validateTag(incFactor)) {
                this.setpoint.inc_factor.factor_tag = new Tag();
                this.setpoint.inc_factor.value = null;
                this.setpoint.inc_factor.factor_tag = this.validateTag(incFactor);
            } else {
                this.incrementSPForm.patchValue({ incFactor: '' });
            }
        }
        let decFactor = incrementSPFormData.decFactor;
        if (incrementSPFormData.decFactorType == 'Constante') {
            if (decFactor >= 0) {
                this.setpoint.dec_factor.value = parseFloat(decFactor);
                this.setpoint.dec_factor.factor_tag = null;
            } else {
                this.incrementSPForm.patchValue({ decFactor: '' });
            }
        } else {
            if (this.validateTag(decFactor)) {
                this.setpoint.dec_factor.factor_tag = new Tag();
                this.setpoint.dec_factor.value = null;
                this.setpoint.dec_factor.factor_tag = this.validateTag(decFactor);
            } else {
                this.incrementSPForm.patchValue({ decFactor: '' });
            }
        }
    }

    resetLimitsInput() {
        this.incrementSPForm.controls['inputMax'].setErrors(null);
        this.incrementSPForm.controls['inputMin'].setErrors(null);
    }

    validateMinMax() {
        let conf = this.defaultDialog;
        if (this.incrementSPForm.invalid) {
            conf.data.message = this.formAlertMessage;
            this.openDialog(conf);
            return false;
        } else {
            let SP = this.actualTagOptions.find((sp) => sp.tag.name == this.incrementSPForm.get('actualTag').value);
            const isIntputWithLimitVariable = SP.tagMax && SP.tagMin;
            if (isIntputWithLimitVariable) {
                return true;
            }
            let max = this.setpoint.new_sp_tag.max;
            let min = this.setpoint.new_sp_tag.min;
            let maxValue = SP.tag.max;
            let minValue = SP.tag.min;
            conf.data.message =
                'Verifique os valores de mínimo e máximo. Para esse setpoint o valor mínimo  é: ' +
                minValue.toString() +
                ', e o valor máximo é: ' +
                maxValue.toString();
            if (this.setpoint.max_limit_tag) {
                if (this.setpoint.max_limit_tag != this.setpoint.min_limit_tag) {
                    return true;
                } else {
                    conf.data.message = 'As variáveis de limite não podem ser iguais';
                    this.incrementSPForm.controls['inputMax'].setErrors({ incorrect: true });
                    this.incrementSPForm.controls['inputMin'].setErrors({ incorrect: true });
                    this.openDialog(conf);
                    return false;
                }
            } else if (!(min < max && min >= minValue && max <= maxValue)) {
                if (max < min) {
                    conf.data.message = 'O valor máximo deve ser maior que o valor mínimo';
                }
                if (min > max || min < minValue || min > maxValue) {
                    this.incrementSPForm.controls['inputMin'].setErrors({ incorrect: true });
                }
                if (max < min || max > maxValue || max < minValue) {
                    this.incrementSPForm.controls['inputMax'].setErrors({ incorrect: true });
                }
                this.openDialog(conf);
                return false;
            } else {
                this.incrementSPForm.controls['inputMax'].setErrors(null);
                this.incrementSPForm.controls['inputMin'].setErrors(null);
                return true;
            }
        }
    }

    validateTag(tagName: string) {
        let tag = this.fuzzyOptions.find((fuzzy) => fuzzy.name == tagName);
        if (!tag) {
            tag = this.actualTagOptions.find((actual) => actual.tag.name == tagName);
            if (!tag) {
                tag = this.limitOptions.find((limit) => limit.tag.name == tagName);
            }
        }
        return tag;
    }

    /**
     * Validates the minimum value if the factor is constant,
     * and returns either the factors values are valid or not.
     *
     * @return {boolean} state of factors.
     */
    validateStates() {
        let decFactor = this.incrementSPForm.get('decFactor').value;
        let incFactor = this.incrementSPForm.get('incFactor').value;
        let validState = false;
        if (!this.isDecConst && !this.isIncConst) {
            validState = true;
        } else {
            if (this.isIncConst && this.isDecConst && decFactor >= 0.1 && incFactor >= 0.1) {
                validState = true;
            } else if (this.isDecConst && decFactor >= 0.1 && !this.isIncConst) {
                validState = true;
            } else if (!this.isDecConst && this.isIncConst && incFactor >= 0.1) {
                validState = true;
            } else if (decFactor < 0.1 || incFactor < 0.1) {
                this.invalidStateMessage = 'O valor mínimo para os fatores é 0,1';
                this.invalidFactor(decFactor, incFactor);
            }
        }
        return validState;
    }

    resetFactorErrors() {
        this.incrementSPForm.controls['incFactor'].setErrors(null);
        this.incrementSPForm.controls['decFactor'].setErrors(null);
    }

    invalidFactor(decFactor, incFactor) {
        if (decFactor < 0.1) {
            this.incrementSPForm.controls['decFactor'].setErrors({ incorrect: true });
        }
        if (incFactor < 0.1) {
            this.incrementSPForm.controls['incFactor'].setErrors({ incorrect: true });
        }
    }

    delete() {
        let deleteDialog = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        this.openDialog(deleteDialog);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.spService.deleteIncrementSetPoint(this.setpoint.new_sp_tag.id).subscribe((dependencies) => {
                    let conf = this.tagsErrorDialog;
                    if (dependencies.length > 0) {
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            if (
                                dependencieNames.indexOf(tag.name + '(' + tag.variableType.description + ')' + ', ') ==
                                -1
                            ) {
                                dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                            }
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message = 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames;
                    } else {
                        conf.data.message = this.deleteMessage;
                        //TODO: Implement here flag to reload quarterback.
                    }
                    this.openDialog(conf);
                });
            }
        });
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            maxWidth: '1200px',
            maxHeight: '800px',
            data: options.data,
        });
        if (options.data.message == MessagesEnum.DeleteMessage || options.data.message == MessagesEnum.SuccessMessage) {
            this.dialogRef.afterClosed().subscribe((response) => {
                this.back();
            });
        }
    }

    openFormula(inc_dec: any) {
        let formula;
        if (inc_dec == 'inc') {
            formula = this.setpoint.inc_formula;
        } else {
            formula = this.setpoint.dec_formula;
        }
        formula.inc_dec = inc_dec;
        const conf = {
            component: FormulaDialogComponent,
            width: '65%',
            height: 'auto',
            maxWidth: '1200px',
            panelClass: 'custom-dialog-container',
            padding: '10%',
            data: {
                formula: formula,
            },
        };
        if (!formula.id && !this.user?.permissions.canUpdate) {
            this.defaultDialog.data.message = MessagesEnum.emptyConditions;
            this.openDialog(this.defaultDialog);
        } else {
            this.openDialog(conf);
            this.dialogRef.afterClosed().subscribe((response) => {
                if (response) {
                    if (response.delete) {
                        if (inc_dec == 'inc') {
                            this.incrementSPForm.patchValue({ toggleFormulaInc: false });
                            this.setpoint.inc_formula = new SPFormula();
                        } else {
                            this.incrementSPForm.patchValue({ toggleFormulaDec: false });
                            this.setpoint.dec_formula = new SPFormula();
                        }
                    } else {
                        formula = response.formula;
                    }
                }
            });
        }
    }

    back() {
        this.router.navigate(['/setpoints']);
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        //return false;
        return project?.versionType !== 'BUILDING';
    }
}
