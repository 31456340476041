import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/service/auth/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { AddEditControlRule } from './views/add-control-rule/add-control-rule.module';
import { FuzzyAndWeightedModule } from './views/fuzzy-and-weighted/fuzzy-and-weighted.module';
import { AddEditSetpointsModule } from './views/increment-setpoint/increment-setpoint.module';
import { LoginModule } from './views/login/login.module';
import { ProcessInputsListModule } from './views/process-inputs-list/process-inputs-list.module';
import { SetpointsModule } from './views/setpoints/setpoints.module';
import { AuthConfigModule } from './auth/auth-config.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgIdleKeepaliveModule.forRoot(),
        BrowserModule,
        SharedModule,
        RouterModule.forRoot(routes, {}),
        BrowserAnimationsModule,
        LoginModule,
        SetpointsModule,
        AddEditSetpointsModule,
        ProcessInputsListModule,
        FuzzyAndWeightedModule,
        FontAwesomeModule,
        AddEditControlRule,
        AuthConfigModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule {}
