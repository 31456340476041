<div *ngIf="matrixAvailable" class="container customFont">
    {{"Teste"}}
    <div fxLayout="column" fxLayoutAlign="start">
        <form [formGroup]="matrixForm">
            <div fxLayout="column">
                <div fxFlex="50%" fxLayoutAlign="center center">
                    <app-matrix [matrix]="matrix" [matrixForm]="matrixForm" [fuzzySet]="fuzzySet"
                        [antecedents]="antecedents" data-cy="matrix"
                        [ngClass]="{'disabled-matrix': isMatrixDisabled}"></app-matrix>
                </div>
            </div>
        </form>
    </div>
</div>