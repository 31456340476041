export class ErrorFlowEnum {
    public static values = [
        { value: 'error', label: 'Erro', description: 'Exibe um erro e impede o cálculo' },
        { value: 'default_value', label: 'Valor Default', description: 'Usa um valor padrão caso haja falha' },
        { value: 'last_value', label: 'Último Valor', description: 'Utiliza o último valor calculado. Caso o último valor não esteja disponível, será usado o valor default informado.' }
    ];

    public static ERROR = 'error';
    public static DEFAULT_VALUE = 'default_value';
    public static LAST_VALUE = 'last_value';

    public static getLabel(value: string): string {
        return this.values.find(item => item.value === value)?.label || value;
    }

    public static getDescription(value: string): string {
        return this.values.find(item => item.value === value)?.description || '';
    }
}
