<mat-toolbar class="topbar">
    <mat-toolbar-row class="top-header">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <div fxFlex="25%" fxLayout="row" fxLayoutAlign="start center">
                <img src="assets/images/logo-nebuluz/logo-header-ihm.png" class="ihm" />
            </div>
            <div fxLayout="row" fxFlex="50%" fxLayoutAlign="center">
                <img src="assets/images/logo-nebuluz/Logo-header-neb.png" (click)="navigateHome()" class="nbz" />
            </div>
            <div fxLayout="row" fxFlex="25%" fxLayoutAlign="end">
                <button mat-icon-button [matMenuTriggerFor]="accountMenu" data-cy="account-menu">
                    <i class="material-icons">person</i>
                    <i class="material-icons">arrow_drop_down</i>
                </button>
                <mat-menu #accountMenu="matMenu">
                    <button mat-menu-item (click)="openUserSettings()" data-cy="user-settings">
                        <span>Minha Conta</span>
                    </button>
                    <button mat-menu-item (click)="onLogout()" data-cy="logout">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sair</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar-row>

    <mat-toolbar-row>
        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
            <div fxFlex="25%" fxLayout="row" fxLayoutAlign="start center">
                <span>
                    <h2>{{ pageTitle }}</h2>
                </span>
            </div>
            <div fxLayout="row" fxFlex="50%" fxLayoutAlign="center center">
                <div>
                    <button mat-button [matMenuTriggerFor]="projectSelect" (click)="getAllProjects()">
                        <span>
                            <h2 class="project-select">{{ project.name }}</h2>
                            <mat-icon class="arrow-margin">arrow_drop_down</mat-icon>
                        </span>
                    </button>
                    <mat-menu #projectSelect="matMenu">
                        <button mat-menu-item *ngFor="let project of filtredProjects" (click)="selectProject(project)">
                            {{ project.name }}
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div fxLayout="row" fxFlex="25%" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div class="nbz-status" fxLayout="column" *ngIf="!isBuilding()">
                    <div class="project-on" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                        <i
                            class="material-icons {{ currentProjectSysParams?.systemParams.projectOnWebApp ? 'icon-on' : 'icon-off' }}">fiber_manual_record</i>
                        <span>Projeto</span>
                        <span class="bold">{{ currentProjectSysParams?.systemParams.projectOnWebApp ? "ligado" :
                            "desligado" }}</span>
                    </div>
                    <div class="project-apt" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                        <i
                            class="material-icons {{ currentProjectSysParams?.systemParams?.projectApt ? 'icon-on' : 'icon-off' }}">{{
                            currentProjectSysParams?.systemParams?.projectApt? "check_circle" : "cancel"
                            }}</i>
                        <span>Projeto</span>
                        <span class="bold">{{ currentProjectSysParams?.systemParams?.projectApt ? "apto" : "inapto"
                            }}</span>
                        <a [matMenuTriggerFor]="projectStatusMenu">
                            <div fxLayout="column" fxLayoutAlign="end">
                                <i class="material-icons menu bold">arrow_drop_down</i>
                            </div>
                        </a>
                    </div>
                </div>
                <mat-menu #projectStatusMenu="matMenu" xPosition="before">
                    <div fxLayout="column" class="menu-container" fxLayoutGap="10px">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                            <i
                                class="material-icons {{ currentInfraSysParams?.quarterbackStatus ? 'icon-apt' : 'icon-off' }}">{{
                                currentInfraSysParams?.quarterbackStatus ? "check_circle" : "cancel" }}</i>
                            <span>Status da Coleta de Dados</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                            <i
                                class="material-icons {{ currentInfraSysParams?.engineStatus ? 'icon-apt' : 'icon-off' }}">{{
                                currentInfraSysParams?.engineStatus ? "check_circle" : "cancel"
                                }}</i>
                            <span>Status do Tratamento de Dados</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                            <i
                                class="material-icons {{ currentInfraSysParams?.workerStatus ? 'icon-apt' : 'icon-off' }}">{{
                                currentInfraSysParams?.workerStatus ? "check_circle" : "cancel"
                                }}</i>
                            <span>Status dos Cálculos</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                            <i
                                class="material-icons {{ currentProjectSysParams?.systemParams?.processStatus ? 'icon-apt' : 'icon-off' }}">{{
                                currentProjectSysParams?.systemParams?.processStatus ? "check_circle" : "cancel"
                                }}</i>
                            <span>Status do Processo</span>
                        </div>
                    </div>
                </mat-menu>
                <button mat-button [ngClass]="[isDeployDisabled() ? 'blueButtonDisabled' : 'blueButton']" class=""
                    (click)="deploySettings()" *ngIf="isBuilding()" data-cy="deploy" [disabled]="isDeployDisabled()">
                    <img mat-card-image class="material-symbols-outlined deploy-icon"
                        src="assets/images/icons/deploy-icon/deploy.png" />
                    <span class="text-deploy">DEPLOY</span>
                </button>
                <app-version-buttons></app-version-buttons>
                <app-history-sider> </app-history-sider>

                <button class="settingsButton" mat-icon-button (click)="editProjectSettings()">
                    <mat-icon class="icon-settings">settings</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isBuilding()" class="warning" fxLayoutAlign="start center">
        <div fxLayoutAlign="start center">
            <span class="material-icons-outlined menu-icon icon-style"> info </span>
            <span class="warning-text">Você está visualizando a versão com as últimas alterações, que é diferente da
                versão sendo executada,
                por isso não é possível visualizar dados históricos ou online.</span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>