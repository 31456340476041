<div class="resizable-container" [ngClass]="{ 'is-running': isReadOnly }"
tabindex="-1" #editorContainer>
  <ngx-monaco-editor 
    class="editor"
    [ngClass]="{ 'hide-cursor': isReadOnly }"
    [options]="editorOptions" 
    [(ngModel)]="code"
    (ngModelChange)="onCodeChange($event)"
    (onInit)="onEditorInit($event)">
  </ngx-monaco-editor>
</div>