
export const environment = {
    production: true,
    serverUrl: 'api/',
    serverWebSocketUrl: `wss://${window.location.host}/ws`,
    timeToLogoutUserIdleInSeconds: window['env']?.TIME_LOGOUT_USER_IDLE ? parseInt(window['env'].TIME_LOGOUT_USER_IDLE) : 3600,
    security: {
        authority: window['env']?.SECURITY_AUTH_HOST || 'https://localhost:5003',
        redirectUrl: `${window.location.origin}`,
        postLogoutRedirectUri: `${window.location.origin}`,
        clientId: 'NebuluzUi',
        scope: 'openid profile email security_admin.user',
        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: `${window.location.origin}/silent-refresh.html`,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        ignoreNonceAfterRefresh: true,
    }
};

