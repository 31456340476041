<div class="container">
    <ihm-card-header data-cy="title" icon="add_circle" [title]="isEditing ? 'Editar' : 'Adicionar' + ' Entrada'"
        [info]="inputInfo">
    </ihm-card-header>

    <form *ngIf="processForm" [formGroup]="processForm" (ngSubmit)="onSubmit()">
        <div fxLayout="column" fxLayoutAlign="start" data-cy="input-form">
            <div fxLayout="row" fxLayoutGap="15px">
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da Entrada</mat-label>
                        <input matInput placeholder="Nome da Entrada" type="text" formControlName="name"
                            autocomplete="off" data-cy="name" />
                        <mat-error *ngIf="processForm.get('name')?.errors?.['required']">
                            *O nome da entrada é obrigatório
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select placeholder="Tipo" formControlName="process_type"
                            (selectionChange)="setDigitalValues($event.value)" data-cy="type">
                            <mat-option *ngFor="let item of processTypes" [value]="item.id">
                                {{ item.description }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="processForm.get('process_type')?.errors?.['required']">
                            *O tipo é obrigatório
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Descrição</mat-label>
                <input matInput placeholder="Descrição" type="text" formControlName="description" autocomplete="off"
                    data-cy="description" />
                <mat-error *ngIf="processForm.get('description')?.errors?.['required']">
                    *O campo descrição é obrigatório
                </mat-error>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="center center">
                <datalist id="opcServerList">
                    <option *ngFor="let opcServer of opcServers" [value]="opcServer.name"></option>
                </datalist>
                <mat-form-field appearance="outline" class="opc-server-container">
                    <mat-label>Servidor OPC</mat-label>
                    <input matInput placeholder="Endereço OPC" type="text" formControlName="opc_server"
                        autocomplete="off" data-cy="opcServer" list="opcServerList" autocomplete="off"
                        (focus)="resetInputOpcServer()" (focusout)="setOpcServer($event.target.value)" />
                    <mat-error *ngIf="processForm.get('opc_server')?.errors?.['required']">
                        *O servidor OPC é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="opc-address-container">
                    <mat-label>Endereço OPC</mat-label>
                    <input matInput placeholder="Endereço OPC" type="text" formControlName="opc_address"
                        autocomplete="off" data-cy="opcAddress" />
                    <mat-error *ngIf="processForm.get('opc_address')?.errors?.['required']">
                        *O endereço OPC é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="15px">
                <div *ngIf="!isLimit" fxFlex="28%">
                    <mat-form-field appearance="outline">
                        <mat-label>Mínimo</mat-label>
                        <input matInput placeholder="Mínimo" list="variableList" [attr.type]="inputType"
                            formControlName="min" autocomplete="off" data-cy="min" autocomplete="off"
                            (focus)="resetMinLimit()" (focusout)="setMinLimit($event.target.value)" />
                        <mat-error *ngIf="processForm.get('min')?.hasError('minMaxInvalid')">
                            *O valor mínimo deve ser menor que o máximo
                        </mat-error>
                        <mat-error *ngIf="
                                processForm.get('min')?.hasError('required') &&
                                !processForm.get('min')?.hasError('minMaxInvalid')
                            ">
                            *O campo mínimo é obrigatório
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="!isLimit" fxFlex="28%">
                    <mat-form-field appearance="outline">
                        <mat-label>Máximo</mat-label>
                        <input matInput placeholder="Máximo" list="variableList" [attr.type]="inputType"
                            formControlName="max" autocomplete="off" data-cy="max" autocomplete="off"
                            (focus)="resetMaxLimit()" (focusout)="setMaxLimit($event.target.value)" />
                        <mat-error *ngIf="processForm.get('max')?.hasError('required')">
                            *O campo máximo é obrigatório
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="!isLimit" fxFlex>
                    <i class="material-icons info"
                        matTooltip="Limites de instrumentação conforme configurado no sistema de automação.">info</i>
                    <div *ngIf="!isLimitConst">
                        <datalist id="variableList">
                            <option *ngFor="let limit of limitOptions" [value]="limit.tag.name"></option>
                        </datalist>
                    </div>
                </div>

                <div fxFlex="28%">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade</mat-label>
                        <input matInput placeholder="Unidade" type="text" formControlName="unit" autocomplete="off"
                            list="unitsList" data-cy="unit" />
                        <mat-error *ngIf="processForm.get('unit')?.hasError('required')">
                            *O campo unidade é obrigatório
                        </mat-error>
                        <datalist id="unitsList">
                            <option *ngFor="let unit of units" [value]="unit.description"></option>
                        </datalist>
                    </mat-form-field>
                </div>
            </div>

            <div class="radio-buttons" *ngIf="showRadioButton">
                <mat-radio-group aria-label="Selecione uma opção" [(ngModel)]="isLimitConst"
                    [disabled]="currentProjectService.isRunningMode()" [ngModelOptions]="{ standalone: true }"
                    (change)="inputTypeChange()">
                    <mat-radio-button [value]="true">Constante</mat-radio-button>
                    <mat-radio-button [value]="false">Variavel</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxFlex>
                    <button mat-button class="whiteButton"
                        *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()"
                        [disabled]="!isEditing" type="button" (click)="deleteProcess()" data-cy="delete-process">
                        <i class="material-icons">delete</i>&nbsp;EXCLUIR ENTRADA
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                <button mat-button class="blueButton"
                    *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()" type="submit"
                    data-cy="save">
                    SALVAR
                </button>
            </div>
        </div>
    </form>
</div>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>