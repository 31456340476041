import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Category } from 'src/app/shared/models/views-models/category.model';
import { OpcServer } from 'src/app/shared/models/views-models/opcServer.model';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { CategoriesService } from 'src/app/shared/service/views-services/categories.service';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { OpcServerService } from 'src/app/shared/service/views-services/opcServer.service';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ProcessInputComponent } from '../process-input/process-input.component';
import { ProcessOutputService } from './../../../service/views-services/processOutput.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { SelectOption, UserPermission } from 'src/app/shared/models/views-models/user.model';
import { ErrorFlowEnum } from 'src/app/shared/models/enum/errorFlow.enum';

@Component({
    selector: 'app-add-process-output',
    templateUrl: './add-process-output.component.html',
    styleUrls: ['./add-process-output.component.scss'],
})
export class AddProcessOutputComponent implements OnInit {
    processOutputForm: UntypedFormGroup;
    isEditing: boolean;
    currentOpcServer: OpcServer;
    opcServers: OpcServer[] = [];
    user: UserPermission;
    currentProcessOutput: any;
    tagOptions = [];
    tagsByType = [];
    processTags: Tag[];
    output: OutputProcess;
    tagTypes: Array<Category>;
    digitalTags = [];
    tagOptionEnabled: boolean = false;
    conditionTags = {};

    successMessage = MessagesEnum.SuccessMessage;
    deleteMessage = MessagesEnum.DeleteMessage;
    failureMessage = MessagesEnum.FailureNameMessage;
    restrictNameMessage = MessagesEnum.restrictNameMessage;
    invalidLimits = MessagesEnum.invalidLimits;
    invalidFormMessage = MessagesEnum.invalidFormMessage;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };
    dialogRefMsg: any;
    errorFlowOptions: SelectOption[] = ErrorFlowEnum.values.map(option => ({
            value: option.value,
            label: ErrorFlowEnum.getLabel(option.value),
            description: ErrorFlowEnum.getDescription(option.value),
        }));
      
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private opcServerService: OpcServerService,
        private tagService: TagService,
        private dialogRef: MatDialogRef<ProcessInputComponent>,
        private processOutputService: ProcessOutputService,
        private processService: ProcessInputService,
        public dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        public currentProjectService: CurrentProjectService,
        private authService: AuthService,
        private categories: CategoriesService
    ) { 
    }

    async ngOnInit() {
        this.tagTypes = this.categories.listAllCategories().sort((a, b) => a.description.localeCompare(b.description));

        this.user = await this.authService.getUserPermission();

        this.isEditing = false;
        if (this.data != null) {
            this.currentProcessOutput = Object.assign({}, this.data);
            this.isEditing = true;
        }
        await this.initForm();
    }

    async initForm() {
        this.processOutputForm = this.formBuilder.group({
            name: [null, Validators.required],
            description: [null, Validators.required],
            tagType: [null, Validators.required],
            tagOption: [{ value: '', disabled: true }, Validators.required],
            condition: [{ value: '', disabled: false }],
            opcAddress: [null, Validators.required],
            opcServer: [null, Validators.required],
            selectErrorFlow: [null, [Validators.required]],
            defaultValue:[null,  Validators.minLength(1)],
        });
        if (!this.user?.permissions.canUpdate || this.currentProjectService.isRunningMode()) {
            this.processOutputForm.disable();
        }
        await this.loadOptions();
        if (this.isEditing) {
            this.fillEditForm();
        }
        this.onErrorFlowChange(this.currentProcessOutput.selectErrorFlow);
    }


    onErrorFlowChange(value: any) {
        this.processOutputForm.get('selectErrorFlow').setValue(value);
        if(value === ErrorFlowEnum.ERROR){
            this.processOutputForm.get('defaultValue').disable();
        }
        else{
            this.processOutputForm.get('defaultValue').enable();
        }
      }

    async fillEditForm() {
        const { name, conditionId, opcAddress, opcServerId, description, tagVariable, selectErrorFlow, defaultValue} = this.data;
        this.processOutputForm.patchValue({
            name: name,
            description: description,
            tagType: tagVariable.variableType.name,
            opcAddress: opcAddress,
            opcServer: opcServerId,
            selectErrorFlow: selectErrorFlow,
            defaultValue: defaultValue,
        });

        this.onTagTypeSelected(tagVariable.variableType.name);
        if (conditionId) {
            this.processOutputForm.get('condition').setValue(conditionId);
        }

        this.processOutputForm.get('tagOption').setValue(tagVariable.id);
    }

    async loadOptions() {
        const waitForTagsByType = async (type) => {
            const tags = await lastValueFrom(this.tagService.getAllTagsByType(type));
            this.tagOptions.push(...tags);
            if (type === VariableTypeEnum.EQUATION.valueOf()) {
                tags.forEach((tag) => {
                    this.conditionTags[tag.id] = {
                        id: tag.id,
                        name: tag.name,
                    };
                });
            }
            return tags;
        };

        const waitForDigitalTags = async () => {
            const digitalTags = await this.processService
                .getAllProcessesByType(VariableTypeEnum.DIGITAL.valueOf())
                .toPromise();
            digitalTags.forEach((digitalTag) => {
                this.conditionTags[digitalTag.tag.id] = {
                    id: digitalTag.tag.id,
                    name: digitalTag.tag.name,
                };
            });
            return digitalTags;
        };

        const waitForOpcServers = async () => {
            const opcServers = await lastValueFrom(this.opcServerService.getAllOpcServers());
            this.opcServers.push(...opcServers);
            return opcServers;
        };

        await Promise.all([
            waitForTagsByType(VariableTypeEnum.FUZZY),
            waitForTagsByType(VariableTypeEnum.WEIGHTED),
            waitForTagsByType(VariableTypeEnum.CALCULATED),
            waitForTagsByType(VariableTypeEnum.EQUATION),
            waitForDigitalTags(),
            waitForOpcServers(),
        ]);
    }

    resetInputOpcServer() {
        if (this.isEditing) {
            this.currentOpcServer.name = this.processOutputForm.get('opc_server').value
                ? this.processOutputForm.get('opc_server').value
                : this.currentOpcServer.name;
        }
        this.processOutputForm.patchValue({ opc_server: '' });
        this.processOutputForm.controls['opc_server'].setErrors(null);
    }

    setOpcServer(opcServer) {
        if (opcServer) {
            const opcServerOption = this.opcServers.find((element) => element.name == opcServer);
            if (opcServerOption) {
                this.processOutputForm.patchValue({ opc_server: opcServerOption.name });
            }
        } else {
            this.processOutputForm.patchValue({ opc_server: this.currentOpcServer.name });
        }
    }

    close() {
        this.dialogRef.close();
    }

    async onSubmit(): Promise<void> {
        const conf = this.defaultDialog;
        const output = await this.transformDataToDto();
        if(output.selectErrorFlow === ErrorFlowEnum.ERROR){
            output.defaultValue = '';
        }
        if (!this.processOutputForm.valid) {
            conf.data.message = this.invalidFormMessage;
            this.openDialog(conf);
            return;
        }

        if (!this.validateName()) {
            conf.data.message = this.restrictNameMessage;
            this.openDialog(conf);
            return;
        }

        this.isEditing ? this.handleEditingProcess(conf, output) : this.handleNewProcess(conf, output);
    }

    validateName() {
        let name = this.processOutputForm.get('name').value;
        if (name.startsWith(VariableTypeEnum.GRD) || name.startsWith(VariableTypeEnum.AVG)) {
            return false;
        } else {
            return true;
        }
    }

    async transformDataToDto() {
        let formData = this.processOutputForm.getRawValue();
        let output: OutputProcess = new OutputProcess();
        output.name = formData.name;
        output.description = formData.description;
        output.selectErrorFlow = formData.selectErrorFlow;
        output.defaultValue = formData.defaultValue;
        try {
            const tagVar = await this.tagService.getTag(formData.tagOption).toPromise();
            output.tagVariable = tagVar;
        } catch (error) {
            console.error(error);
        }
        output.conditionId = formData.condition;
        output.opcServerId = formData.opcServer;
        output.opcAddress = formData.opcAddress;
        return output;
    }

    private async handleNewProcess(conf: any, output: OutputProcess): Promise<void> {
        if (!this.user?.permissions.canUpdate) {
            return;
        }
        try {
            const response = await this.processOutputService.newOutputProcess(output).toPromise();
            if (response) {
                this.isEditing = true;
                conf.data.message = this.successMessage;
                //TODO: Implement here flag to reload quarterback.
            } else {
                conf.data.message = this.failureMessage;
            }
            this.openDialog(conf);
        } catch (error) {
            console.error(error);
        }
    }

    async handleEditingProcess(conf: any, output: OutputProcess): Promise<void> {
        if (!this.user?.permissions.canUpdate) {
            return;
        }

        try {
            output.id = this.data.id;
            const response = await this.processOutputService.editOutputProcess(output).toPromise();

            if (response) {
                conf.data.message = this.successMessage;
                //TODO: Implement here flag to reload quarterback.
            } else {
                conf.data.message = this.failureMessage;
            }
            this.openDialog(conf);
        } catch (error) {
            console.error(error);
        }
    }

    deleteOutput() {
        this.processOutputService.deleteOutputProcess(this.data.id);
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (options.data.message == this.successMessage || options.data.message == this.deleteMessage) {
                this.dialogRef.close(this.output);
            }
        });
    }

    clearField(tagName) {
        this.processOutputForm.patchValue({
            entryName: '',
        });
    }

    onTagTypeSelected(tagType: string) {
        this.processOutputForm.controls['tagOption'].enable();
        this.tagOptionEnabled = tagType !== '' && this.currentProjectService.isBuildingMode();
        if (!this.tagOptionEnabled) {
            this.processOutputForm.controls['tagOption'].disable();
            this.processOutputForm.get('tagOption').setValue('');
        }
        this.updateTagsOptions(tagType);
    }

    updateTagsOptions(type: string) {
        this.tagsByType = this.tagOptions
            .filter((tag) => tag.variableType.name == type)
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    onClearCondition() {
        this.processOutputForm.get('condition').setValue('');
    }

}
