export class InternVariables {
    public static sysVariables = [
        'cycle_time_s Tempo de ciclo',
        'cycle_time_ms Tempo de ciclo',
        'watchdog_s Timeout de comunicação',
        'watchdog_ms Timeout de comunicação',
        'current_cycle_time_s Data/hora do ciclo atual',
        'current_cycle_time_ms Data/hora do ciclo atual',
        'previous_cycle_time_s Data/hora do ciclo anterior',
        'previous_cycle_time_ms Data/hora do ciclo anterior',
        'write_time_s Tempo de escrita',
        'write_time_ms Tempo de escrita',
        'cycle_number Número do ciclo',
        'collector_time_s Tempo de coleta do OPC',
        'collector_time_ms Tempo de coleta do OPC',
        'cycles_timestamp_s Ciclos anteriores em memória',
        'cycles_timestamp_ms Ciclos anteriores em memória'
    ]
}