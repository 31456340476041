<mat-card appearance="outlined">
    <mat-card-header>
        <div class="title-home" data-cy="control-rule">
            <mat-card-title>Regras de Controle</mat-card-title>
            <button class="customButton add-button" mat-raised-button type="button" (click)="add()"
                *ngIf="user?.permissions?.canCreate && !isShowForm()" data-cy="add">
                ADICIONAR
            </button>
        </div>
    </mat-card-header>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <mat-form-field class="search-input" appearance="outline">
            <mat-label>Buscar Regras</mat-label>
            <input  matInput (keyup)="applyFilter($event.target.value)" />
            <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
        </mat-form-field>

        <div class="table-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text><a (click)="selectElement(element)">{{ element.name
                            }}</a></app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{ element.description }}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions" class="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-button (click)="trackRule(element)" [disabled]="!isShowForm()"
                            *ngIf="currentProjectService.isRunningMode();" matTooltip="Acompanhar"
                            matTooltipPosition="above">
                            <img mat-card-image class="material-symbols-outlined tracking-rules"
                                src="assets/images/icons/tracking-rules-icon/tracking-rules-blue.svg" />
                        </button>
                        <button mat-button (click)="selectElement(element)"
                            *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode(); else visibilityButton"
                            matTooltip="Editar" matTooltipPosition="above">
                            <i class="material-icons">edit</i>
                        </button>
                        <button mat-button (click)="deleteElement(element)"
                            *ngIf="user?.permissions?.canDelete && !isShowForm()" matTooltip="Excluir"
                            matTooltipPosition="above">
                            <i class="material-icons">delete</i>
                        </button>
                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)" matTooltip="Visualizar"
                                matTooltipPosition="above">
                                <i class="material-icons-outlined">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
</mat-card>