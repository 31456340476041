import { MatDialogConfig } from '@angular/material/dialog';
import { PopUpMessagesComponent } from '../components/pop-up-messages/pop-up-messages.component';
import { MessagesEnum } from '../models/enum/messages.enum';

export interface DialogInterface {
    imageUrl?: string;
    title: string;
    subTitle?: string;
    optionalText?: string;
    firstButtonText?: string;
    secondButtonText?: string;
    typePopUp: string;
}

export class DialogConfigs {
    static successIcon: string = 'assets/images/status/successMessage.svg';
    static errorIcon: string = 'assets/images/status/errorMessage.svg';
    static confirmationPopUpType = 'titlePopUpConfirmationMessage';
    static informationPopUpType = 'titlePopUpInformationMessage';

    static processCreateSuccess: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.successIcon,
            title: MessagesEnum.inputRegisterSuccessMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processCreateError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.inputRegisterErrorMessage,
            subTitle: 'Não foi possível cadastrar entrada.',
            optionalText: 'Tente novamente mais tarde.',
            typePopUp: this.informationPopUpType,
        },
    };

    static processEditSuccess: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.successIcon,
            title: MessagesEnum.inputEditSuccessMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processEditError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.inputEditErrorMessage,
            subTitle: 'Não foi possível editar entrada.',
            optionalText: 'Tente novamente mais tarde.',
            typePopUp: this.informationPopUpType,
        },
    };

    static processDeleteSuccess: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.successIcon,
            title: MessagesEnum.inputDeleteSuccessMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processDeleteError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.inputDeleteErrorMessage,
            subTitle: 'Não foi possível excluir entrada.',
            optionalText: 'Tente novamente mais tarde.',
            typePopUp: 'titlePopUpInformationMessage',
        },
    };

    static processIvalidLimitsError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.invalidLimits,
            typePopUp: this.informationPopUpType,
        },
    };

    static processIvalidNameError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.restrictNameMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processFormInvalidError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.invalidFormMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processMinMaxInvalidError: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: MessagesEnum.validationError,
            subTitle: MessagesEnum.minMaxValidationErrorMessage,
            typePopUp: this.informationPopUpType,
        },
    };

    static processDeleteDependenciesError(dependencieNames: string): MatDialogConfig<DialogInterface> {
        return {
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: this.errorIcon,
                title: 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames,
                typePopUp: this.informationPopUpType,
            },
        };
    }

    static processSetpointLimitsError(SPmin: number, SPmax: number): MatDialogConfig<DialogInterface> {
        return {
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: this.errorIcon,
                title: 'Erro nos limites de Setpoint',
                subTitle: 'Os limites mínimo e máximo da entrada devem contemplar os limites de atuação do Setpoint',
                optionalText: `sendo estes ${SPmin} e ${SPmax} respectivamente`,
                typePopUp: this.informationPopUpType,
            },
        };
    }

    static processConfirmDelete: MatDialogConfig<DialogInterface> = {
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: '',
            title: 'Excluir Entrada',
            subTitle: 'Você tem certeza que deseja excluir entrada? ',
            optionalText: 'Esta ação não pode ser desfeita.',
            firstButtonText: 'EXCLUIR',
            secondButtonText: 'CANCELAR',
            typePopUp: 'deletePopUp',
        },
    };
    
    static projectDeleteSuccess: MatDialogConfig<DialogInterface> = {
        maxWidth: '32,25rem',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.successIcon,
            title: MessagesEnum.projectDeletingSuccessMessage,
            typePopUp: 'titlePopUpInformationMessage',
        },
    };

    static projectDeleteError(projectName: string): MatDialogConfig<DialogInterface> {
        return {
            maxWidth: '32,25rem',
            panelClass: 'pop-up-dialog-container',
            data: {
              imageUrl: this.errorIcon,
              title: MessagesEnum.projectDeletingErrorMessage,
              subTitle: `Não foi possível excluir o projeto ${projectName}.`,
              optionalText: 'Tente novamente mais tarde.',
              typePopUp: 'titlePopUpInformationMessage',
          },
        };
    }
    static projectConfirmDelete(projectName: string): MatDialogConfig<DialogInterface> {
        return {
            maxWidth: '32,25rem',
            panelClass: 'pop-up-dialog-container',
            data: {
                title: 'Excluir Projeto',
                subTitle: `Você tem certeza que deseja excluir o projeto ${projectName}?`,
                optionalText: 'Esta ação não poderá ser desfeita.',
                firstButtonText: 'EXCLUIR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'deletePopUp',
            },
        };
    }
    static genericEquationEditError(errorMessage: string): MatDialogConfig<DialogInterface> {
        return {
            maxWidth: '32,25rem',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: this.errorIcon,
                title: 'Erro ao editar uma variável calculada',
                subTitle: errorMessage, // Exibir a mensagem vinda do backend
                typePopUp: this.informationPopUpType,
            },
        };
    }
    static equationSchedulerUnavailableError: MatDialogConfig<DialogInterface> = {
        maxWidth: '32,25rem',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.errorIcon,
            title: 'Erro ao editar uma variável calculada',
            subTitle: MessagesEnum.schedulerServiceUnavailable +
            '<br>' +
            MessagesEnum.tryAgain +
            '<br>' +
            MessagesEnum.contactUs,
            typePopUp: this.informationPopUpType,
        },
    };

    static equationEditSuccess: MatDialogConfig<DialogInterface> = {
        maxWidth: '32,25rem',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: this.successIcon,
            title: MessagesEnum.SuccessMessage,
            typePopUp: this.informationPopUpType,
        },
    };
    static unknowEquationEditError(errorMessage: string): MatDialogConfig<DialogInterface> {
        return {
            maxWidth: '32,25rem',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: this.errorIcon,
                title: 'Erro ao editar uma variável calculada',
                subTitle: MessagesEnum.Exception + '\n' + (errorMessage || 'Erro desconhecido'),
                typePopUp: this.informationPopUpType,
            },
        };
    }

}
