<div class="sidenav-container" fxLayout="column" fxLayoutGap="1.48vh" fxLayoutAlign="start"
    [@onSideNavChange]="sideNavState ? 'open' : 'close'">
    <div class="menu-header sidenav-section" fxLayout="column">
        <div [@animateText]="linkText ? 'hide' : 'show'" fxFlexAlign="center">
            <button mat-stroked-button (click)="onSidenavToggle()" [matTooltip]="!sideNavState ? 'Abrir menu' : ''"
                matTooltipPosition="right"
                [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
                [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">menu</mat-icon>
                </div>
            </button>
        </div>
        <div [@animateText]="linkText ? 'show' : 'hide'">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4.2%" class="header-info">
                <div class="user-container" fxLayout="column">
                    <h2 [@animateText]="linkText ? 'show' : 'hide'" class="sidebar-section-title">Usuário</h2>
                    <div class="user-info" fxFlex>
                        <div class="username">
                            {{ user?.name.length > 20 ? user?.name.substring(0, 20) + "..." : user?.name }}
                        </div>
                        <div class="email">
                            {{ role }}
                        </div>
                    </div>
                </div>
                <button ngClass="close-sidebar-button" mat-icon-button (click)="onSidenavToggle()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-divider [ngClass]="linkText ? 'divider-closed' : 'divider-open'" class="over-div"></mat-divider>

    <div class="sidenav-section" [ngClass]="!sideNavState ? 'show-margin-top' : ''" fxLayout="column"
        fxLayoutGap="1.48vh" [fxLayoutAlign]="!sideNavState ? 'start center' : 'start'">
        <h2 [@animateText]="linkText ? 'show' : 'hide'" class="sidebar-section-title">Cadastro</h2>
        <div *ngFor="let view of filterViews('cadastro')">
            <button *ngIf="view.source != 'material-symbols-outlined'" (click)="navigateTo(view.url)"
                [ngClass]="isActive(view.name)" mat-stroked-button type="button"
                [matTooltip]="!sideNavState ? view.name : ''" matTooltipPosition="right"
                [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
                [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <div fxLayout="row" [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                    <img [fxLayoutAlign]="'center center'" [src]="view.source + view.icon" />
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                </div>
            </button>

            <button *ngIf="view.source == 'material-symbols-outlined'" (click)="navigateTo(view.url)"
                [ngClass]="isActive(view.name)" mat-stroked-button type="button"
                [matTooltip]="!sideNavState ? view.name : ''" matTooltipPosition="right"
                [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
                [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <div fxLayout="row" [fxLayoutAlign]="!sideNavState ? ' center' : 'start center'">
                    <mat-icon [fxLayoutAlign]="'center center'" fontSet="material-symbols-outlined">{{ view.icon
                        }}</mat-icon>
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                </div>
            </button>
        </div>
    </div>
    <mat-divider *ngIf="getProjectVersionType() === 'RUNNING'" [ngClass]="linkText ? 'divider-closed' : 'divider-open'"
        class="over-div"></mat-divider>

    <div *ngIf="getProjectVersionType() === 'RUNNING'" class="sidenav-section" fxLayout="column"
        [fxLayoutAlign]="!sideNavState ? 'start center' : 'start'" fxLayoutGap="1.48vh">
        <h2 [@animateText]="linkText ? 'show' : 'hide'" class="sidebar-section-title">Comissionamento e Sustentação</h2>
        <div *ngFor="let view of filterViews('comissionamento-e-sustentacao')">
            <button *ngIf="view.source != 'material-symbols-outlined'" (click)="navigateTo(view.url)"
                [ngClass]="isActive(view.name)" mat-stroked-button type="button"
                [matTooltip]="!sideNavState ? view.name : ''" matTooltipPosition="right"
                [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
                [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <div fxLayout="row" [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                    <img [fxLayoutAlign]="'center center'" [src]="view.source + view.icon" />
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                </div>
            </button>

            <button *ngIf="view.source == 'material-symbols-outlined'" (click)="navigateTo(view.url)"
                [ngClass]="isActive(view.name)" mat-stroked-button type="button"
                [matTooltip]="!sideNavState ? view.name : ''" matTooltipPosition="right">
                <div fxLayout="row" [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
                    [ngStyle]="!sideNavState && { width: '27px' }"
                    [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                    <mat-icon [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'"
                        fontSet="material-symbols-outlined">{{ view.icon }}</mat-icon>
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                </div>
            </button>
        </div>
    </div>
    <mat-divider [ngClass]="linkText ? 'divider-closed' : 'divider-open'" class="over-div"></mat-divider>

    <div *ngIf="user?.permissions?.canUpdate" class="sidenav-section" fxLayout="column"
        [fxLayoutAlign]="!sideNavState ? 'start center' : 'start'" fxLayoutGap="1.48vh">
        <h2 [@animateText]="linkText ? 'show' : 'hide'" class="sidebar-section-title">Configurações</h2>
        <button *ngIf="user?.permissions?.canUpdate" (click)="openSettings()" mat-stroked-button type="button"
            [matTooltip]="!sideNavState ? 'Coleta de Dados' : ''" matTooltipPosition="right"
            [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
            [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
            <div fxLayout="row" [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <img [fxLayoutAlign]="'center center'"
                    src="assets/images/icons/data-collection-icon/data-collection.svg" />
                <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">Coleta de Dados</span>
            </div>
        </button>
        <button *ngIf="user?.permissions?.canUpdate" (click)="navigateToUsers()" [ngClass]="isActive('Usuários')"
            mat-stroked-button type="button" [matTooltip]="!sideNavState ? 'Usuários' : ''" matTooltipPosition="right"
            [class]="sideNavState ? 'button-container' : 'button-container sidebar-closed'"
            [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
            <div fxLayout="row" [fxLayoutAlign]="!sideNavState ? 'center center' : 'start center'">
                <mat-icon [fxLayoutAlign]="'center center'">group_add</mat-icon>
                <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">Usuários</span>
            </div>
        </button>
    </div>
</div>