import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { Project } from '../../models/views-models/project.model';
import { ProjectDownload } from '../../models/views-models/projectDownload.model';
import { ProjectSettings } from '../../models/views-models/projectSettings.model';
import { ProjectStatus } from '../../models/views-models/projectStatus.model';
import { SystemPar } from '../../models/views-models/systemPar.model';
import { CurrentProjectService } from './current-project.service';
import { SystemParamService } from './systemParam.service';

const API = environment.serverUrl;

@Injectable()
export class ProjectService {
    constructor(private httpClient: HttpClient, private systemParamsService: SystemParamService) { }

    reloadQuaterback(): Observable<boolean> {
        return this.httpClient.get<Response>(`${API}project/quaterback/reload`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const isReloaded: boolean = response.data;
                return isReloaded;
            })
        );
    }

    getAllProjects(): Observable<Project[]> {
        return this.httpClient.get<Response>(`${API}project/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const projects: Project[] = response.data.sort((a, b) => a.name.localeCompare(b.name));
                return projects
            })
        );
    }

    getProjectVersions(mainProjectId: string): Observable<Project[]> {
        return this.httpClient
            .get<Response>(`${API}project/versions/${mainProjectId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const projects: Project[] = response.data.sort((a, b) => a.name.localeCompare(b.name));
                    return projects;
                })
            );
    }

    getProjectSettings(projectId: string): Observable<ProjectSettings> {
        return this.httpClient
            .get<Response>(`${API}project/settings/${projectId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const projectSettings: ProjectSettings = response.data;
                    return projectSettings;
                })
            );
    }

    getProjectStatus(projectId: string): Observable<ProjectStatus> {
        return this.httpClient.get<Response>(`${API}project/status/${projectId}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const projectSettings: ProjectStatus = response.data;
                return projectSettings;
            })
        );
    }

    getDefaultProject(): Observable<Project> {
        return this.httpClient.get<Response>(`${API}project/default`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const project: Project = response.data;
                return project;
            })
        );
    }

    saveProjectSettings(projectSettings: ProjectSettings): Observable<ProjectSettings> {
        return this.httpClient
            .patch<Response>(`${API}project/save-settings`, projectSettings, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const projectSettings: ProjectSettings = response.data;
                    return projectSettings;
                })
            );
    }

    saveProjectStatus(projectSettings: ProjectSettings): Observable<ProjectSettings> {
        return this.httpClient
            .patch<Response>(`${API}project/save-status`, projectSettings, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const projectSettings: ProjectSettings = response.data;
                    return projectSettings;
                })
            );
    }

    newProject(project: Project) {
        return this.httpClient.post<Response>(`${API}project/new`, project, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newProject: Project = response.data;
                return newProject;
            })
        );
    }

    updateProject(project: Project) {
        return this.httpClient.patch<Response>(`${API}project/edit/${project.id}`, project, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const project: Project = response.data;
                return project;
            })
        );
    }

    deleteProject(id: string) {
        return this.httpClient.delete<Response>(`${API}project/delete/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const project: Project = response.data;
                this.systemParamsService.deleteProject(project.mainProjectId)
                return project;
            })
        );
    }

    deleteProjectVersion(project: Project) {
        return this.httpClient
            .delete<Response>(`${API}project/delete/version/${project.id}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const project: Project = response.data;
                    return project;
                })
            );
    }

    setProjectDefault(project: Project) {
        return this.httpClient
            .patch<Response>(`${API}project/set-default`, project, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const project: Project = response.data;
                    return project;
                })
            );
    }

    isMVControlEnabled(projectId: string): Observable<Boolean> {
        //const project = this.currentProjectService.getCurrentProject();
        //let id = project.id;
        return this.httpClient
            .get<Response>(`${API}project/mv-control/${projectId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const mvControl: Boolean = response.data;
                    return mvControl;
                })
            );
    }

    getCycleTime(): Observable<number> {
        return this.httpClient.get<Response>(`${API}project/cycle-time`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const cycleTime: number = response.data.value;
                return cycleTime;
            })
        );
    }

    deploySettings(): Observable<SystemPar> {
        return this.httpClient.post<Response>(`${API}project/deploy-settings`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const systemDeploy: SystemPar = response.data;
                return systemDeploy;
            })
        );
    }

    applyVersion(project: Project): Observable<Project> {
        return this.httpClient
            .post<Response>(`${API}project/version/${project.id}/apply`, project, { headers: this.getHeaders() })
            .pipe(map((response: Response) => response.data as Project));
    }

    getProjectDownload(project: Project): Observable<ProjectDownload> {
        return this.httpClient
            .get<Response>(`${API}project/download/${project.id}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const projects: ProjectDownload = response.data;
                    return projects;
                })
            );
    }

    restoreProject() {
        return this.httpClient.post<Response>(`${API}project/restore`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                return response.data;
            })
        );
    }

    upload(file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        // Crie um cabeçalho para a requisição indicando que é "multipart/form-data"
        let headers = new HttpHeaders();

        return this.httpClient.post<Response>(`${API}project/upload-version`, formData, { headers }).pipe(
            map((response: Response) => {
                return response.data;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
        });
        return headers;
    }
}
