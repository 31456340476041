import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import PerfectScrollbar from 'perfect-scrollbar';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { OpcServer } from 'src/app/shared/models/views-models/opcServer.model';
import { Quarterback } from 'src/app/shared/models/views-models/quarterback.model';
import { QuarterbackService } from 'src/app/shared/service/quarterback/quarterback.service';
import { OpcServerService } from 'src/app/shared/service/views-services/opcServer.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';
import { ProcessInputService } from './../../../service/views-services/process.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
    private bodyPS: PerfectScrollbar;
    dialogRefMsg: any;
    dynamicLabel: any;

    identifiers = ['ClsID', 'ProgID'];
    user: UserPermission;
    isEditingOPCServer = false;
    opcSettingsForm: UntypedFormGroup;
    opcServers: OpcServer[];

    quarterbackForm: UntypedFormGroup;
    isEditingQuarterback = false;
    qbPort: number;
    qbHost: string;
    qbSettings = new Quarterback();
    maxCollectorTime: number;
    minCollectorTime = 1;
    isLoading: boolean = false;
    loaderMessage: string = '';

    successIcon: string = 'assets/images/status/successMessage.svg';
    errorIcon: string = 'assets/images/status/errorMessage.svg';
    confirmationPopUpType = 'titlePopUpConfirmationMessage';
    informationPopUpType = 'titlePopUpInformationMessage';

    buttons = {
        quarterback: {
            edit: false,
            create: false,
            delete: false
        },
        opcSettings: {
            edit: false,
            create: false,
            delete: false
        }

    }

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private opcServerService: OpcServerService,
        private quarterbackService: QuarterbackService,
        private processInputService: ProcessInputService,
        public dialog: MatDialog,
        private authService: AuthService,
        private currentProjectService: CurrentProjectService,
        private dialogRef: MatDialogRef<AdminSettingsComponent>
    ) { }

    async ngOnInit(): Promise<void> {
        this.user = await this.authService.getUserPermission()
        this.initForms();
        this.setDefaultOpcValues();
        this.setDefaultQuarterbackValues();
        this.getOpcServersSettings();
        this.getQbSettings();
        this.loadScroll();
        this.setButtons();

    }
    setButtons() {
        if (this.currentProjectService.isBuildingMode()) {

            this.buttons.quarterback.edit = this.user?.permissions?.canUpdate;
            this.buttons.quarterback.create = this.user?.permissions?.canCreate;
            this.buttons.quarterback.delete = this.user?.permissions?.canDelete;
            this.buttons.opcSettings.edit = this.user?.permissions?.canUpdate;
            this.buttons.opcSettings.create = this.user?.permissions?.canCreate;
            this.buttons.opcSettings.delete = this.user?.permissions?.canDelete;


        } else {
            this.buttons.quarterback.edit = false;
            this.buttons.quarterback.create = false;
            this.buttons.quarterback.delete = false;
            this.buttons.opcSettings.edit = false;
            this.buttons.opcSettings.create = false;
            this.buttons.opcSettings.delete = false;

        }

    }

    initForms(): void {
        this.opcSettingsForm = this.formBuilder.group({
            host: [null, [Validators.required, Validators.maxLength(250)]],
            key_type: [null],
            identifier: [null, Validators.maxLength(100)],
            domain: [null, Validators.maxLength(100)],
            password: [null, Validators.maxLength(100)],
            user: [null, Validators.maxLength(100)],
            name: [null, [Validators.required, Validators.maxLength(100)]],
        });
        this.opcSettingsForm.disable();
        this.quarterbackForm = this.formBuilder.group({
            collector_time: [null, [Validators.required, Validators.min(1)]],
            quarterback_host: [null, Validators.required],
            quarterback_port: [null, Validators.required],
        });
        this.quarterbackForm.disable();
    }

    getQbSettings(): void {
        this.quarterbackService.getQuarterbackSettings().subscribe((qbSettings) => {
            this.qbSettings = qbSettings;
            this.qbPort = qbSettings.port;
            this.qbHost = qbSettings.host;
            this.quarterbackForm.patchValue({
                collector_time: this.qbSettings.collectorTime,
                quarterback_host: this.qbHost,
                quarterback_port: this.qbPort,
            });
        });
    }

    async getOpcServersSettings(): Promise<void> {
        this.opcServers = await this.opcServerService.getAllOpcServers().toPromise();
        if (this.opcServers.length === 0) {
            const opcServer = new OpcServer();
            this.opcServers.push(opcServer);
            this.setDefaultOpcValues();
        }
        this.setLabelNames();
    }

    currentOpcServer(opcServer: OpcServer): void {
        if (opcServer) {
            this.opcSettingsForm.patchValue({
                domain: opcServer.domain,
                user: opcServer.username,
                password: opcServer.password,
                host: opcServer.host,
                key_type: opcServer.keyType,
                identifier: opcServer.keyType == VariableTypeEnum.CLS_ID ? opcServer.clsId : opcServer.progId,
                name: opcServer.name,
            });
        } else {
            this.setDefaultOpcValues();
        }
    }

    loadScroll() {
        if (this.bodyPS) {
            this.bodyPS.destroy();
        }
        this.bodyPS = new PerfectScrollbar('.container', {
            suppressScrollX: true,
        });
    }

    enableOpcFormEdit(): void {
        if (this.user?.permissions.canUpdate && this.currentProjectService.isBuildingMode()) {
            this.isEditingOPCServer = true;
            this.opcSettingsForm.enable();
            this.buttons.opcSettings.edit = false;
            this.buttons.opcSettings.create = false;
            this.buttons.opcSettings.delete = false;


        } else {
            this.disableOpcServerEdit()
        }
    }

    disableOpcServerEdit(): void {
        this.buttons.opcSettings.edit = this.user?.permissions.canUpdate && this.currentProjectService.isBuildingMode();
        this.buttons.opcSettings.create = this.user?.permissions.canCreate && this.currentProjectService.isBuildingMode();
        this.buttons.opcSettings.delete = this.user?.permissions.canDelete && this.currentProjectService.isBuildingMode();


        this.isEditingOPCServer = false;
        this.opcSettingsForm.disable();
    }

    removeOPCServer(opcServer: OpcServer): void {
        try {
            if (this.opcServers.length === 1) {
                this.openDialog({
                    component: PopUpMessagesComponent,
                    width: '38.84rem',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: this.errorIcon,
                        title: 'É necessário haver pelo menos um servidor OPC',
                        typePopUp: this.informationPopUpType,
                    },
                });
            } else {
                this.openDialog({
                    component: PopUpMessagesComponent,
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: '',
                        title: 'Excluir Servidor OPC',
                        subTitle: 'Você tem certeza que deseja excluir o Servidor OPC?',
                        optionalText: 'Esta ação não pode ser desfeita.',
                        firstButtonText: 'EXCLUIR',
                        secondButtonText: 'CANCELAR',
                        typePopUp: 'deletePopUp',
                    },
                });
                this.dialogRefMsg.afterClosed().subscribe((result) => {
                    // if opc server hasn't yet been created then opcServer.id is undefined
                    // so its necessary to remove it from from frontend but not from backend
                    this.startLoader('Excluindo servidor OPC, aguarde…');
                    if (result && !opcServer.id) {
                        this.opcServers.pop();
                        this.stopLoader();
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: '38.84rem',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.successIcon,
                                title: MessagesEnum.opcServerDeleteSuccessMessage,
                                typePopUp: this.informationPopUpType,
                            },
                        });
                        return;
                    } else if (!result && !opcServer.id) {
                        this.stopLoader();
                        return;
                    }
                    this.stopLoader();

                    this.processInputService.getAllProcesses().subscribe({
                        next: (processes) => {
                            const processWithOpcServer = processes.find(
                                (process) => process.opcServerId === opcServer.id
                            );
                            const isOpcBeingUsed = !!processWithOpcServer;

                            this.startLoader('Excluindo servidor OPC, aguarde…');
                            if (result && !isOpcBeingUsed) {
                                this.opcServerService.removeOpcServer(opcServer.id).subscribe({
                                    next: (response) => {
                                        this.opcServers = this.opcServers.filter(
                                            (element) => element.id !== opcServer.id
                                        );
                                        this.stopLoader();
                                        this.disableOpcServerEdit();
                                        this.openDialog({
                                            component: PopUpMessagesComponent,
                                            width: '38.84rem',
                                            panelClass: 'pop-up-dialog-container',
                                            data: {
                                                imageUrl: this.successIcon,
                                                title: MessagesEnum.opcServerDeleteSuccessMessage,
                                                typePopUp: this.informationPopUpType,
                                            },
                                        });
                                    },
                                    error: (error) => {
                                        console.error("Error deleting OPC Server.", error);
                                        this.stopLoader();
                                        this.disableOpcServerEdit();
                                        this.openDialog({
                                            component: PopUpMessagesComponent,
                                            width: '38.84rem',
                                            panelClass: 'pop-up-dialog-container',
                                            data: {
                                                imageUrl: this.errorIcon,
                                                title: MessagesEnum.opcServerDeleteErrorMessage,
                                                subTitle: 'Não foi possível excluir o servidor OPC.',
                                                optionalText: 'Tente novamente mais tarde.',
                                                typePopUp: this.informationPopUpType,
                                            },
                                        });
                                    },
                                });
                            } else {
                                this.stopLoader();
                                this.openDialog({
                                    component: PopUpMessagesComponent,
                                    width: '38.84rem',
                                    panelClass: 'pop-up-dialog-container',
                                    data: {
                                        imageUrl: this.errorIcon,
                                        title: 'Servidor OPC em uso',
                                        subTitle: MessagesEnum.OpcInUse,
                                        typePopUp: this.informationPopUpType,
                                    },
                                });
                            }
                        },
                        error: (error) => {
                            console.error('An error occurred while checking if OPC server is in use:', error);
                            this.stopLoader();
                            this.disableOpcServerEdit();
                            this.openDialog({
                                component: PopUpMessagesComponent,
                                width: '38.84rem',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.errorIcon,
                                    title: MessagesEnum.opcServerDeleteErrorMessage,
                                    subTitle: 'Não foi possível excluir o servidor OPC.',
                                    optionalText: 'Tente novamente mais tarde.',
                                    typePopUp: this.informationPopUpType,
                                },
                            });
                        },
                    });
                });
            }
        } catch (error) {
            this.stopLoader();
            this.openDialog({
                component: PopUpMessagesComponent,
                width: '38.84rem',
                panelClass: 'pop-up-dialog-container',
                data: {
                    imageUrl: this.errorIcon,
                    title: MessagesEnum.RemoveFailed,
                    typePopUp: this.informationPopUpType,
                },
            });
        }
    }

    dialogMessage(message): void {
        let conf = {
            component: ConfirmDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        conf.data.message = message;
        this.openDialog(conf);
    }

    addOPCServerForm(): void {
        let mockOpcServer = new OpcServer();
        this.opcServers.push(mockOpcServer);
    }

    onSaveOPCServerSettings(opcServer: OpcServer): void {
        let opcServerSetting = this.opcSettingsForm.getRawValue();
        opcServerSetting.username = opcServerSetting.user;
        opcServerSetting.user = this.user?.userName;
        opcServerSetting.keyType = opcServerSetting.key_type;
        delete opcServerSetting.key_type;
        if (opcServerSetting.keyType == VariableTypeEnum.CLS_ID) {
            opcServerSetting.clsId = opcServerSetting.identifier;
            opcServerSetting.progId = null;
        } else {
            opcServerSetting.progId = opcServerSetting.identifier;
            opcServerSetting.clsId = null;
        }
        try {
            if (opcServer.id !== undefined) {
                opcServerSetting.id = opcServer.id;
                this.startLoader('Atualizando servidor OPC, aguarde…');
                this.opcServerService.editOpcServer(opcServer.id, opcServerSetting).subscribe({
                    //TODO: Implement here flag to reload quarterback.
                    next: () => {
                        this.stopLoader();
                        this.disableOpcServerEdit();
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: '38.84rem',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.successIcon,
                                title: MessagesEnum.opcServerUpdateSuccessMessage,
                                typePopUp: this.informationPopUpType,
                            },
                        });
                    },
                    error: () => {
                        this.stopLoader();
                        this.disableOpcServerEdit();
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: '38.84rem',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.errorIcon,
                                title: MessagesEnum.opcServerUpdateErrorMessage,
                                subTitle: 'Não foi possível atualizar servidor OPC.',
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: this.informationPopUpType,
                            },
                        });
                    },
                });
            } else {
                this.startLoader('Adicionando servidor OPC, aguarde…');
                if (opcServerSetting.password == undefined) {
                    opcServerSetting.password = '';
                }
                this.opcServerService.saveOpcServer(opcServerSetting).subscribe({
                    //TODO: Implement here flag to reload quarterback.
                    next: () => {
                        this.stopLoader();
                        this.disableOpcServerEdit();
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: '38.84rem',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.successIcon,
                                title: MessagesEnum.opcServerRegisterSuccessMessage,
                                typePopUp: this.informationPopUpType,
                            },
                        });
                    },
                    error: () => {
                        this.stopLoader();
                        this.disableOpcServerEdit();
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: '38.84rem',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.errorIcon,
                                title: MessagesEnum.opcServerRegisterErrorMessage,
                                subTitle: 'Não foi possível cadastrar servidor OPC.',
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: this.informationPopUpType,
                            },
                        });
                    },
                });
            }
        } catch (error) {
            this.dialogMessage(MessagesEnum.invalidFormMessage);
        }
    }

    enableQuarterbackEdit(): void {

        if (this.user?.permissions.canUpdate && this.currentProjectService.isBuildingMode()) {
            this.isEditingQuarterback = true;
            this.quarterbackForm.enable();
            this.buttons.quarterback.edit = false;
            this.buttons.quarterback.create = false;
            this.buttons.quarterback.delete = false;

        } else {
            this.disableQuarterbackEdit();
        }

    }

    disableQuarterbackEdit(): void {
        this.isEditingQuarterback = false;
        this.quarterbackForm.disable();
        this.buttons.quarterback.edit = this.user?.permissions.canUpdate && this.currentProjectService.isBuildingMode();
        this.buttons.quarterback.create = this.user?.permissions.canCreate && this.currentProjectService.isBuildingMode();
        this.buttons.quarterback.delete = this.user?.permissions.canDelete && this.currentProjectService.isBuildingMode();

    }

    onSaveQuarterbackConfigs(): void {
        if (this.quarterbackForm.valid) {
            let quarterbackFormData = this.quarterbackForm.getRawValue();
            this.qbSettings.host = quarterbackFormData.quarterback_host;
            this.qbSettings.port = quarterbackFormData.quarterback_port;
            this.qbSettings.collectorTime = quarterbackFormData.collector_time;
            this.isEditingQuarterback = false;
            this.startLoader('Atualizando Quarterback, aguarde…');
            this.quarterbackService.saveQuarterback(this.qbSettings).subscribe({
                next: (settings) => {
                    //TODO: Implement here flag to reload quarterback.
                    this.qbSettings = settings;
                    this.disableQuarterbackEdit();
                    this.stopLoader();

                    this.openDialog({
                        component: PopUpMessagesComponent,
                        width: '38.84rem',
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            imageUrl: this.successIcon,
                            title: MessagesEnum.qbSettingsUpdateSuccessMessage,
                            typePopUp: this.informationPopUpType,
                        },
                    });
                },
                error: (error) => {
                    this.openDialog({
                        component: PopUpMessagesComponent,
                        width: '38.84rem',
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            imageUrl: this.errorIcon,
                            title: MessagesEnum.qbSettingsUpdateErrorMessage,
                            subTitle: 'Não foi possível atualizar o Quarterback.',
                            optionalText: 'Tente novamente mais tarde.',
                            typePopUp: this.informationPopUpType,
                        },
                    });

                    this.quarterbackForm.get('collector_time').setValue(this.qbSettings.collectorTime);
                    this.quarterbackForm.get('quarterback_host').setValue(this.qbHost);
                    this.quarterbackForm.get('quarterback_port').setValue(this.qbPort);
                    this.stopLoader();
                },
            });
        }
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
        if (
            options.data.message == MessagesEnum.SuccessMessage ||
            options.data.title == MessagesEnum.opcServerUpdateSuccessMessage
        ) {
            this.dialogRefMsg.afterClosed().subscribe((result) => {
                this.close();
            });
        }
    }

    setLabelNames(): void {
        let formData = this.opcSettingsForm.getRawValue();
        this.dynamicLabel = formData.key_type ? formData.key_type : 'ClsID/ProgID';
    }

    clearIdentifier(): void {
        this.opcSettingsForm.patchValue({ identifier: '' });
        this.setLabelNames();
    }

    setDefaultOpcValues(): void {
        this.opcSettingsForm.reset();
        this.opcSettingsForm.patchValue({
            name: 'Servidor OPC 1',
            host: 'localhost',
            key_type: VariableTypeEnum.CLS_ID,
        });
    }

    setDefaultQuarterbackValues(): void {
        this.quarterbackForm.patchValue({
            quarterback_host: 'localhost',
            quarterback_port: 8081,
            collector_time: 5,
        });
    }

    close() {
        this.dialogRef.close();
    }

    startLoader(message?: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }
}
