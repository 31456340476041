import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    AfterViewInit,
    ElementRef,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss'],
})
export class CodeEditorComponent implements AfterViewInit {
    @Input() code: string = '';
    @Input() isReadOnly: boolean = false;
    @Output() codeChange = new EventEmitter<string>();
    @ViewChild('editorContainer') editorContainer!: ElementRef;

    editorOptions = {
        theme: 'customTheme',
        language: 'customLang',
        automaticLayout: true,
        readOnly: true,
        fixedOverflowWidgets: true,
    };

    private editorInstance: any;

    ngAfterViewInit(): void {
        const container = this.editorContainer.nativeElement;
        const resizeObserver = window.ResizeObserver
            ? new ResizeObserver(() => this.onResize())
            : window.addEventListener('resize', this.onResize.bind(this));

        if (resizeObserver) {
            resizeObserver.observe(container);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isReadOnly']) {
            this.editorOptions = {
                ...this.editorOptions,
                readOnly: this.isReadOnly,
            };

            if (this.editorInstance) {
                this.editorInstance.updateOptions({ readOnly: this.isReadOnly });
            }
        }
    }

    onCodeChange(newCode: string): void {
        this.codeChange.emit(newCode);
    }

    onEditorInit(editor: any): void {
        this.editorInstance = editor;
        this.editorInstance.updateOptions({ readOnly: this.isReadOnly });
    }

    private onResize(): void {
        this.editorInstance?.layout();
    }
}
