import { ErrorFlowEnum } from '../enum/errorFlow.enum';
import { Tag } from './tag.model';
import { TagValue } from './tagValue.model';

export class OutputProcess {
    id: string;
    tag_id: string;
    conditionId: string;
    name: string;
    description: string;
    status: boolean;
    tagValues: TagValue[];
    tagVariable: Tag = new Tag();
    tagVariableId: string;
    opcServerId: string;
    opcAddress: string;
    projectId: string;
    timestamp: string;
    selectErrorFlow: ErrorFlowEnum;
    defaultValue: string;
}
