<form *ngIf="ready" [formGroup]="fuzzyForm" (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined" class="containerHeader">
        <div class="title" data-cy="title">{{ isEditing ? "Editar" : "Adicionar" }} Regra de Controle</div>

        <mat-divider></mat-divider>
        <p class="subtitle">Consequente</p>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <div fxFlex>
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" type="text" formControlName="inputName" autocomplete="off"
                        data-cy="name" />
                </mat-form-field>
            </div>
            <div fxFlex>
                <mat-form-field appearance="outline">
                    <mat-label>Descrição</mat-label>
                    <input matInput placeholder="Descrição" type="text" formControlName="inputDesc" autocomplete="off"
                        data-cy="description" />
                </mat-form-field>
            </div>
        </div>
    </mat-card>
    <div class="container">
        <div class="card">
            <div fxFill>
                <mat-accordion>
                    <mat-expansion-panel class="border" (opened)="toggleForm()" (closed)="toggleForm()">
                        <mat-expansion-panel-header>
                            <mat-panel-title fxLayoutAlign="center"> Antecedente 1 </mat-panel-title>
                            <mat-panel-title fxLayoutAlign="center"> Antecedente 2 </mat-panel-title>
                            <mat-panel-title fxLayoutAlign="center"> Consequente </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div fxFlex="32">
                            <app-add-antecedent [title]="'Antecedente 1'" [parentLoaded]="isLoaded"
                                [parentForm]="this.fuzzyForm.get('antecedente1')" [tags]="tags" [process]="processTags"
                                [fuzzySets]="fuzzySets" [curveTypes]="curveTypes" (resetEditing)="feedbackReceiver()"
                                data-cy="antecedent-1"></app-add-antecedent>
                        </div>
                        <div fxFlex="32">
                            <app-add-antecedent [title]="'Antecedente 2'" [parentLoaded]="isLoaded"
                                [parentForm]="this.fuzzyForm.get('antecedente2')" [tags]="tags" [process]="processTags"
                                [fuzzySets]="fuzzySets" [curveTypes]="curveTypes" (resetEditing)="feedbackReceiver()"
                                data-cy="antecedent-2"></app-add-antecedent>
                        </div>
                        <div fxFlex="32" class="consequentContainer">
                            <div fxLayout="row" fxLayoutGap="10px">
                                <div fxFlex="50%">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Formato da função central</mat-label>
                                        <mat-select formControlName="curveType" data-cy="curve-type">
                                            <mat-option *ngFor="let item of curveTypes" [value]="item.id">
                                                {{ item.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50%">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Topo do Trapézio (%)</mat-label>
                                        <input matInput placeholder="Topo do Trapézio (%)" type="number" min="0"
                                            max="100" formControlName="top" autocomplete="off" data-cy="top" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <form [formGroup]="this.fuzzyForm.get('matrix')">
                                <div fxLayout="row">
                                    <div fxFlex="calc(100% + 10px)">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Conjunto Fuzzy</mat-label>
                                            <mat-select formControlName="outFuzzySet" data-cy="out-fuzzy-set">
                                                <mat-option *ngFor="let item of outFuzzySets" [value]="item.value">
                                                    {{ item.description }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div fxLayout="row" fxLayoutGap="12px" class="margin-top-chart">
                    <form [formGroup]="this.fuzzyForm.get('antecedente1')" fxLayout="column" fxFlex="33">
                        <div echarts [options]="antecedente1Option" [initOpts]="initOpts"></div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-limites">
                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Mín. Fuzzy</mat-label>
                                    <input matInput placeholder="Mínimo" type="number" formControlName="min"
                                        autocomplete="off" (focus)="showMinLabel = true"
                                        (focusout)="showMinLabel = false" data-cy="min-fuzzy" />
                                </mat-form-field>
                            </div>

                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Máx. Fuzzy</mat-label>
                                    <input matInput placeholder="Máximo" type="number" formControlName="max"
                                        autocomplete="off" (focus)="showMaxLabel = true"
                                        (focusout)="showMaxLabel = false" data-cy="max-fuzzy" />
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <form fxLayout="column" fxFlex="33" [formGroup]="this.fuzzyForm.get('antecedente2')">
                        <div echarts [options]="antecedente2Option" [initOpts]="initOpts"></div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-limites">
                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Mín. Fuzzy</mat-label>
                                    <input matInput placeholder="Mínimo" type="number" formControlName="min"
                                        autocomplete="off" (focus)="showMinLabel = true"
                                        (focusout)="showMinLabel = false" data-cy="min-fuzzy" />
                                </mat-form-field>
                            </div>

                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Máx. Fuzzy</mat-label>
                                    <input matInput placeholder="Máximo" type="number" formControlName="max"
                                        autocomplete="off" (focus)="showMaxLabel = true"
                                        (focusout)="showMaxLabel = false" data-cy="max-fuzzy" />
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <div fxLayout="column" fxFlex="33">
                        <div echarts [options]="consequenteOption" [initOpts]="initOpts"></div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-limites">
                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Mín. Fuzzy</mat-label>
                                    <input matInput placeholder="Mínimo" type="number" formControlName="min"
                                        autocomplete="off" data-cy="min" />
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Máx. Fuzzy</mat-label>
                                    <input matInput placeholder="Máximo" type="number" formControlName="max"
                                        autocomplete="off" data-cy="max" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="matrixAvailable">
                    <app-control-matrix [$fuzzy]="_$fuzzy.asObservable()" [matrixForm]="this.fuzzyForm.get('matrix')"
                        [matrix]="matrix" [antecedentsNames]="antecedentsNames"></app-control-matrix>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="margin-top">
                    <div fxFlex>
                        <button mat-button class="whiteButton" [disabled]="!isEditing"
                            *ngIf="user?.permissions?.canDelete && !isShowForm()" type="button" (click)="deleteFuzzy()"
                            data-cy="delete">
                            <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR REGRA DE CONTROLE
                        </button>
                    </div>
                    <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                    <button mat-button class="blueButton" type="submit"
                        *ngIf="user?.permissions?.canUpdate && !isShowForm()" data-cy="save">
                        SALVAR
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>