export enum MessagesEnum {
    opcServerUpdateSuccessMessage = "Servidor OPC Atualizado com Sucesso!",
    opcServerUpdateErrorMessage = "Erro ao Atualizar Servidor OPC.",
    opcServerRegisterSuccessMessage = "Servidor OPC Cadastrado com Sucesso!",
    opcServerRegisterErrorMessage = "Erro ao Cadastrar Servidor OPC.",
    opcServerDeleteSuccessMessage = "Servidor OPC Excluído com Sucesso!",
    qbSettingsUpdateSuccessMessage = "Quarterback Atualizado com Sucesso!",
    opcServerDeleteErrorMessage = "Erro ao Excluir Servidor OPC.",
    qbSettingsUpdateErrorMessage = "Erro ao Excluir Servidor OPC.",
    projectRegisterSuccessMessage = 'Projeto cadastrado com sucesso!',
    projectRegisterErrorMessage = 'Erro ao Cadastrar Novo Projeto',
    projectEditingSuccessMessage = 'Projeto atualizado com sucesso!',
    projectEditingErrorMessage = 'Erro ao Atualizar Novo Projeto',
    projectDeletingSuccessMessage = 'Projeto excluído com sucesso!',
    projectDeletingErrorMessage = 'Erro ao Excluir Projeto',
    projectSettingsEditSuccessMessage = 'Configurações salvas com sucesso!',
    projectSettingsEditErrorMessage = 'Erro ao Salvar Configurações',
    SuccessMessage = 'Dados registrados com sucesso!',
    inputRegisterSuccessMessage = 'Entrada cadastrada com sucesso!',
    inputRegisterErrorMessage = 'Erro ao cadastrar entrada.',
    inputEditSuccessMessage = 'Entrada Atualizada com Sucesso!',
    inputEditErrorMessage = 'Erro ao Atualizar Entrada',
    inputDeleteSuccessMessage = 'Entrada excluída com sucesso!',
    inputDeleteErrorMessage = 'Erro ao excluir entrada',
    SuccessEditMessage = 'Dados atualizados com sucesso!',
    DeleteMessage = 'Dados deletados com sucesso!',
    FailureNameMessage = 'Este nome já está um uso',
    invalidFormMessage = 'Preencha todos os dados',
    minMaxValidationErrorMessage = 'O valor do campo mínimo deve ser maior que o campo máximo',
    validationError = 'Erro de validação.',
    defaultDeleteMessage = 'Deseja excluir este item permanentemente?',
    manyDeleteMessage = 'Deseja excluir estes itens permanentemente?',
    restrictNameMessage = 'Os prefixos GRD e AVG são reservados ao sistema, por favor escolha outro nome',
    equationAdded = 'Equação adicionada ao setpoint',
    conditionsAdded = 'Condições adicionadas ao setpoint',
    invalidLimits = 'O valor máximo deve ser maior que o mínimo',
    invalidParameters = 'Parâmetros inválidos, verifique os valores de mínimo, máximo e delta',
    intNumberRequired = 'O valor para o campo ciclos deve ser um número inteiro',
    noDataFound = 'Não foram encontrados resultados para esta variável neste intervalo de tempo',
    invalidDate = 'Intervalo de tempo inválido',
    formulaEquationRequired = 'É necessário o preenchimento do campo equação',
    invalidRule = 'A Regra de controle inserida não existe.',
    invalidSP = 'O setpoint inserido não existe.',
    deploySuccessMessage = 'O deploy concluído com sucesso!',
    deployErrorMessage = 'Ocorreu um erro durante o processo de deploy.',
    quarterbackError = 'Deploy realizado, mas o quaterback não foi reiniciado!',
    confirmDeployMessage = 'Você confirma o deploy no projeto ',
    successDeployMessage = 'Deploy realizado com sucesso! ',
    errorDeployMessage = 'Erro ao realizar o deploy ',
    projectSwitchError = 'Ocorreu um erro ao tentar selecionar o projeto',
    existsMV = 'Não é possível desativar o controle por MV, pois está sendo usada como TAG atual nos setpoints.',
    fuzzySetChangeMessage = 'Ao alterar o conjunto Fuzzy de um antecedente ou inserir/remover uma variável GRD, a Matriz de Controle anterior será zerada e será necessário que salve a alteração para criar uma nova matriz',
    conditionsDeleted = 'Condições removidas do setpoint',
    passwordMatchFail = 'As senhas digitadas não conferem',
    passwordChangeConfirm = 'A senha será alterada, deseja continuar?',
    userChangeConfirm = 'O usuário será alterado, deseja continuar?',

    changeProjectStatus = 'A mudança de estado do projeto ocorrerá após deploy',
    emptyConditions = 'Não existem condições cadastradas para esse setpoint',
    invalidEquation = 'Equação inválida, revise os operadores e operandos',
    Exception = 'Erro Desconhecido',
    OpcInUse = 'Este servidor OPC não pode ser deletado, pois, está associado a entradas do sistema',
    RemoveFailed = 'Erro ao tentar deletar servidor',
    ExceptionAxis = 'Não pode ter váriaveis iguais nos dois eixos!',
    IncludesAxis = 'Já está referenciado a este eixo',
    ErroMessage = 'Erro ao registrar dados!',
    LimiteAxisErro = 'Limite de variáveis atigindo!',
    SetPointDefault = 'Este Setpoint é o padrão',
    restoreMessage = 'Restauração realizada com sucesso!',
    uploadMessage = 'Upload realizada com sucesso!',
    schedulerServiceUnavailable = 'Gerenciador de rotinas (Scheduler) não disponível no momento',
    tryAgain = 'Tente novamente mais tarde',
    contactUs = 'Caso o erro persista, entre em contato com o time Nebuluz',
    schedulerUnavailableError = 'Scheduler Unavailable'
}
