import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { from, lastValueFrom, Observable } from 'rxjs';
import { UserService } from 'src/app/shared/service/views-services/user.service';
import { CurrentProjectService } from '../views-services/current-project.service';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public currentProjectService: CurrentProjectService, public userService: UserService, public oidcSecurityService: OidcSecurityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return from(this.verifyPermission());
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        return from(this.verifyPermission());
    }

    async verifyPermission(): Promise<boolean> {

        const loginResponse = await lastValueFrom(this.oidcSecurityService.checkAuth());

        if (!loginResponse.isAuthenticated) {
            this.oidcSecurityService.authorize();

            return true

        }
        const currentUser = await lastValueFrom(this.userService.getCurrentUser());

        return Boolean(currentUser)


    }


}
