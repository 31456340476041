<div class="app-admin-wrap" [dir]="layoutConf.dir">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
        <mat-sidenav #leftSidenav mode="over" opened>
            <app-sidebar></app-sidebar>
        </mat-sidenav>
        <mat-sidenav-content>
            <!-- App content -->
            <div class="main-content-wrap">
                <app-header [sidenav]="leftSidenav"></app-header>
                <div class="main-content-container">
                    <!-- View Loader -->
                    <div class="view-loader" *ngIf="isModuleLoading">
                        <div class="spinner">
                            <div class="double-bounce1 mat-bg-accent"></div>
                            <div class="double-bounce2 mat-bg-primary"></div>
                        </div>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>