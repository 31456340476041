import { Injectable } from '@angular/core';
import { Client, IMessage } from '@stomp/stompjs';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private stompClient: Client;
    private messageSubject = new Subject<any>();
    private WEBSOCKET_URL: string = environment.serverWebSocketUrl;
    private GET_ALL_PROJECTS_STATES_MESSAGE: string = 'getAllSystemParams';
    private GET_SYSTEM_PARAMS_PRODUCER_TOPIC: string = '/topic/system-params';
    private ALL_SYSTEM_PARAMS_CONSUMER_TOPIC: string = '/app/all-system-params';

    constructor() {
        this.stompClient = new Client({
            brokerURL: this.WEBSOCKET_URL,
            reconnectDelay: 5000,
        });

        this.stompClient.onConnect = () => {
            this.subscribeToSystemParams();
            this.sendMessageToRequestAllSystemParams();
        };

        this.stompClient.onStompError = (frame) => {
            console.error('STOMP error: ', frame.headers['message']);
            console.error('Details: ', frame.body);
        };

        this.stompClient.activate();
    }

    private subscribeToSystemParams(): void {
        this.stompClient.subscribe(this.GET_SYSTEM_PARAMS_PRODUCER_TOPIC, (message: IMessage) => {
            const payload = JSON.parse(message.body);
            this.messageSubject.next(payload);
        });
    }

    getMessages(): Observable<any> {
        return this.messageSubject.asObservable();
    }

    sendMessage(topic: string, message: any): void {
        if (this.stompClient && this.stompClient.connected) {
            this.stompClient.publish({
                destination: topic,
                body: JSON.stringify(message),
            });
        } else {
            console.error('Unable to send message. WebSocket disconnected.');
        }
    }

    sendMessageToRequestAllSystemParams() {
        this.sendMessage(this.ALL_SYSTEM_PARAMS_CONSUMER_TOPIC, this.GET_ALL_PROJECTS_STATES_MESSAGE);
    }

    disconnect(): void {
        if (this.stompClient) {
            this.stompClient.deactivate();
        }
    }
}
