<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Lista de Entradas</mat-card-title>
        <span class="data-info">Dados atualizados em {{ lastUpdate }}</span>
    </mat-card-header>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <div *ngIf="selectedList.length == 0; else deleteMarked" fxFlexFill fxLayout="row"
            fxLayoutAlign="space-between">
            <div fxFlex="70%" fxLayout="row" fxLayoutGap="25px">
                <div fxFlex="40%">
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar Entradas</mat-label>
                        <input matInput (keyup)="applyFilter($event.target.value)" data-cy="search-input" />
                        <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center" class="options">
                    <span>Filtrar por:</span>
                    <div fxLayout="column">
                        <span>Tipo:</span>
                        <div fxLayout="row" fxLayoutGap="5px" data-cy="types-filter">
                            <button [ngClass]="showAllTypes ? 'mini-toggle' : 'disabled-mini-toggle'" type="button"
                                (click)="changeAllTypes()">
                                Todos
                            </button>
                            <button *ngFor="let type of processTypes" [ngClass]="
                                    activeTypes[processTypes.indexOf(type)].active
                                        ? 'mini-toggle'
                                        : 'disabled-mini-toggle'
                                " type="button" (click)="enableType(type)">
                                {{ type.description }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <button class="customButton add-button" mat-raised-button type="button" (click)="add()"
                    *ngIf="user?.permissions?.canCreate && currentProjectService.isBuildingMode()" data-cy="add">
                    ADICIONAR
                </button>
                <button class="reload" mat-icon-button type="button" (click)="loadDatasource()">
                    <i class="material-icons">refresh</i>
                </button>
            </div>
        </div>
        <ng-template #deleteMarked>
            <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                <button class="customButton add-button" mat-raised-button type="button"
                    (click)="deleteSelectedElements()"
                    [disabled]="!user?.permissions?.canDelete || currentProjectService.isRunningMode()"
                    data-cy="delete">
                    DELETAR
                </button>
            </div>
        </ng-template>

        <div class="table-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox color="primary" [checked]="checkStatus(element)" (change)="markElement(element)"
                            data-cy="checkbox"></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header #cellRef> Nome </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text> <a (click)="selectElement(element)">{{ element.tag.name
                            }}</a></app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{element.tag.description}}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef> Tipo </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>
                            {{ element.process_type.description }}
                        </app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="min">
                    <mat-header-cell *matHeaderCellDef> Mínimo </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-ellipsis-text *ngIf="element.process_type.name != 'limit'; else noData">
                            <div *ngIf="element.tagMin; else constante">
                                {{ element.tagMin.name }}
                            </div>
                            <ng-template #constante>
                                {{ element.tag.min | number }}
                            </ng-template>
                        </app-ellipsis-text>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="max">
                    <mat-header-cell *matHeaderCellDef> Máximo </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.process_type.name != 'limit'; else noData">
                            <div *ngIf="element.tagMax; else constante">
                                {{ element.tagMax.name }}
                            </div>
                            <ng-template #constante>
                                {{ element.tag.max | number }}
                            </ng-template>
                        </div>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="value">
                    <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.value && currentProjectService.isRunningMode(); else noData">
                            {{ element.value }}
                        </div>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="timestamp">
                    <mat-header-cell *matHeaderCellDef> Última Leitura </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.timestamp && currentProjectService.isRunningMode(); else noData" fxLayout="row"
                            fxLayoutAlign="start center" fxLayoutGap="15px">
                            <span>{{ element.timestamp }}</span>
                            <span *ngIf="element.oldData">
                                <mat-icon class="warning">warning</mat-icon>
                            </span>
                        </div>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quality">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div
                            *ngIf="element.quality == 'Good' && currentProjectService.isRunningMode(); else badQuality">
                            <i class="material-icons good">check_circle</i>
                        </div>
                        <ng-template #badQuality>
                            <i *ngIf="currentProjectService.isRunningMode() else noData"
                                class="material-icons bad">cancel</i>
                        </ng-template>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions" class="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button *ngIf="false" mat-button (click)="trackInput(element)"
                            [disabled]="element.process_type.description !== 'Analógica'">
                            <i-feather name="activity" [ngClass]="
                                    element.process_type.description !== 'Analógica' ? 'white-bold' : 'light-blue'
                                "></i-feather>
                        </button>
                        <button mat-button (click)="selectElement(element)"
                            *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode(); else visibilityButton"
                            matTooltip="Editar" matTooltipPosition="above">
                            <i class="material-icons">edit</i>
                        </button>

                        <button mat-button (click)="deleteElement(element)"
                            *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()"
                            matTooltip="Excluir" matTooltipPosition="above">
                            <i class="material-icons">delete</i>
                        </button>

                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)" matTooltip="Visualizar"
                                matTooltipPosition="above">
                                <i class="material-icons-outlined">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>