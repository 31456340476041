import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Tag } from '../../models/views-models/tag.model';
import { Response } from '../../models/response.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VariableTypeEnum } from '../../models/enum/variableType.enum';

const API = environment.serverUrl;

@Injectable()
export class TagService {
    constructor(private httpClient: HttpClient) {}

    localTags: BehaviorSubject<Array<Tag>> = new BehaviorSubject<Array<Tag>>(null);

    getAllLocalTags(): Observable<Array<Tag>> | Observable<Tag[]> {
        return this.localTags?.value ? of(this.localTags.value) : this.getAllTags();
    }

    getAllTags(): Observable<Tag[]> {
        let observables: Array<Observable<Tag[]>> = [
            this.getAllTagsByType(VariableTypeEnum.FUZZY),
            this.getAllTagsByType(VariableTypeEnum.PROCESS),
            this.getAllTagsByType(VariableTypeEnum.EQUATION),
            this.getAllTagsByType(VariableTypeEnum.WEIGHTED),
            this.getAllTagsByType(VariableTypeEnum.CALCULATED),
            this.getAllTagsByType(VariableTypeEnum.ANALOGIC),
            this.getAllTagsByType(VariableTypeEnum.MANIPULATED_VARIABLE),
            this.getAllTagsByType(VariableTypeEnum.LIMIT),
        ];

        return combineLatest(observables).pipe(
            map((tags) => {
                let tagsArray = tags.reduce((acc, cur) => acc.concat(cur), []);
                tagsArray.sort((a: Tag, b: Tag) => a.name.localeCompare(b.name));
                this.localTags.next(tagsArray);
                return tagsArray;
            })
        );
    }

    getAllTagsByType(type: string): Observable<Tag[]> {
        return this.httpClient.get<Response>(`${API}tag/all/type/${type}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newTag: Tag[] = response.data;
                return newTag;
            })
        );
    }

    getTag(id: string): Observable<Tag> {
        return this.httpClient.get<Response>(`${API}tag/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tag: Tag = response.data;
                return tag;
            })
        );
    }

    getTagByName(tagName: string): Observable<Tag> {
        return this.httpClient.get<Response>(`${API}tag/name/${tagName}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tag: Tag = response.data;
                return tag;
            })
        );
    }

    getTagDependencies(tagId: string): Observable<Tag[]> {
        return this.httpClient
            .get<Response>(`${API}tag/tag-dependencies/${tagId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const tag: Tag[] = response.data;
                    return tag;
                })
            );
    }

    getTagDependenciesConstants(tagId: string): Observable<Tag[]> {
        return this.httpClient
            .get<Response>(`${API}tag/dependencies-limits-constants/${tagId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const tag: Tag[] = response.data;
                    return tag;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
