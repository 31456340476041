import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { LocalStorageService } from 'src/app/shared/service/views-services/localstorage.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { formatDate, formatDateObj, formatDateSaveFile } from 'src/app/shared/utils/date.utils';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';
@Component({
    selector: 'app-history-sider',
    templateUrl: './history-sider.component.html',
    styleUrls: ['./history-sider.component.scss'],
})
export class HistorySiderComponent implements OnInit {
    userPermission: UserPermission;
    constructor(
        private projectService: ProjectService,
        private lsService: LocalStorageService,
        public currentProjectService: CurrentProjectService,
        private authService: AuthService,
        public dialog: MatDialog
    ) { }
    versions: Array<Project>;
    dialogRef: any;
    isSpinnerActive: boolean = false;
    loaderMessage: string = '';

    async ngOnInit(): Promise<void> {
        this.updateProjects();

        this.userPermission = await this.authService.getUserPermission()
    }

    updateProjects() {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                this.versions = versions
                    .map((v) => {
                        let currentProject = this.currentProjectService.getCurrentProject();
                        if (currentProject) {
                            v.defaultVersion = currentProject.id == v.id;
                        }
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');
            });
        }
    }
    isBuilding(project: Project): boolean {
        return this.getVersionType(project) == 'Building'
    }


    enableUpload(project: Project) {
        return this.isBuilding(project) && this.userPermission?.permissions?.canUpdate

    }





    getVersionType(project: Project) {
        return project?.versionType.substring(0, 1).toUpperCase() + project?.versionType.substring(1).toLowerCase();
    }

    getDialogRef(conf: any) {
        return this.dialog.open(conf.component, {
            panelClass: conf.panelClass,
            minWidth: conf.minWidth ? conf.minWidth : 'auto',
            height: 'auto',
            data: conf.data,
        });
    }

    apply(drawer: MatDrawer, project: Project) {
        this.startSpinner(`Carregando versão ${project.versionType.charAt(0).toUpperCase() + project.versionType.slice(1).toLowerCase()}...`);
        this.projectService.applyVersion(project).subscribe({
            next: (isVersionapplied) => {
                if (isVersionapplied) {
                    this.lsService.reset(project);
                    this.lsService.triggerLocalStorageChanges(project);
                    this.currentProjectService.setCurrentProject(project);
                    this.stopSpinner();
                    this.currentProjectService.goReload();
                }
            },
            error: (err) => {
                let conf = {
                    component: PopUpMessagesComponent,
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: 'assets/images/status/errorMessage.svg',
                        title: 'Erro ao Carregar Versão',
                        subTitle: 'Não foi possível carregar a versão ' + project.versionType,
                        optionalText: 'Tente novamente mais tarde',
                        typePopUp: 'titlePopUpInformationMessage',
                    },

                };
                this.stopSpinner();
                this.getDialogRef(conf);
                this.verifyVersion();
            },
        });
    }

    removeVersion(drawer: MatDrawer, project: Project) {
        let conf = {
            component: ConfirmDialogComponent,
            panelClass: 'pop-up-dialog-container',
            minWidth: 'auto',
            data: {
                title: 'Excluir a versão projeto',
                message: 'Deseja excluir a versão V' + project.version + '?',
                cancelAvailable: true,
                confirmButton: 'Remover',
            },
        };

        this.getDialogRef(conf)
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.projectService.deleteProjectVersion(project).subscribe((isVersionRemoved) => {
                        if (isVersionRemoved) {
                            drawer.close();
                            conf.data.title = '';
                            conf.data.cancelAvailable = false;
                            conf.data.confirmButton = '';
                            conf.data.message = MessagesEnum.DeleteMessage;
                            this.getDialogRef(conf);
                        }
                    });
                }
            });
    }

    download(project: Project, drawer: MatDrawer) {
        let conf = {
            component: PopUpMessagesComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: 'assets/images/status/successMessage.svg',
                title: 'Configurações exportadas com sucesso.',
                typePopUp: 'titlePopUpInformationMessage',
                subTitle: '',
            },
        };

        this.startSpinner("Exportando configurações...");
        this.projectService.getProjectDownload(project).subscribe({
            next: (data) => {
                if (data) {
                    drawer.close();
                    let blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
                    saveAs(blob, `${project.name}_${project.versionType}_${formatDateSaveFile(new Date())}.json`);
                    this.stopSpinner();
                    this.getDialogRef(conf);
                }
            },
            error: (err) => {
                conf.data.imageUrl = 'assets/images/status/errorMessage.svg';
                conf.data.title = 'Erro ao exportar as configurações',
                    conf.data.subTitle = 'Tente novamente.'
                drawer.close();
                this.stopSpinner();
                this.getDialogRef(conf);
            }
        });
    }

    restore(drawer: MatDrawer) {
        let conf = {
            component: PopUpMessagesComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Restaurar Versão',
                subTitle: 'Você tem certeza que deseja restaurar as configurações para a versão<br>anterior? Essa ação irá desfazer todas as alterações realizadas na<br>versão Building e irá restaurar o projeto para o estado da versão Running.',
                optionalText: 'Clique em Confirmar para prosseguir ou Cancelar para retornar.',
                firstButtonText: 'CONFIRMAR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'titlePopUpConfirmationMessage',
            },
        };

        this.getDialogRef(conf).afterClosed().subscribe((response) => {
            if (response) {
                this.startSpinner("Restaurando as configurações...");
                this.projectService.restoreProject().subscribe({
                    next: (data) => {
                        if (data) {
                            drawer.close();
                            conf.data.imageUrl = 'assets/images/status/successMessage.svg';
                            conf.data.title = 'As configurações foram restauradas com sucesso.';
                            conf.data.subTitle = 'Visualize as novas configurações da versão BUILDING';
                            conf.data.typePopUp = 'titlePopUpInformationMessage';
                            conf.data.optionalText = '';
                            this.stopSpinner();
                            this.openDialog(conf);
                            this.dialogRef.afterClosed().subscribe(() => {
                                data.versionType = "BUILDING";
                                this.lsService.reset(data);
                                this.lsService.triggerLocalStorageChanges(data);
                                this.currentProjectService.setCurrentProject(data);
                                this.currentProjectService.goHome();
                            });
                        }
                    },
                    error: (err) => {
                        drawer.close();
                        conf.data.imageUrl = 'assets/images/status/errorMessage.svg';
                        conf.data.title = 'Erro ao restaurar as configurações.';
                        conf.data.subTitle = 'Tente novamente.';
                        conf.data.firstButtonText = '';
                        conf.data.secondButtonText = '';
                        conf.data.typePopUp = 'titlePopUpInformationMessage';
                        conf.data.optionalText = '';
                        this.stopSpinner();
                        this.openDialog(conf);

                    },
                });
            }
        });
    }

    isRemoveButtonDisabled(version: Project) {
        return version?.defaultVersion || version?.versionType == 'RUNNING';
    }

    getRemoveButtonLabel(version: Project) {
        if (version?.defaultVersion) {
            return 'Versão default';
        }

        if (version?.versionType == 'RUNNING') {
            return 'Versão Running';
        }

        return 'Remover';
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            minWidth: options.minWidth ? options.minWidth : 'auto',
            height: 'auto',
            data: options.data,
        });
    }

    onFileSelected(event: any, drawer: MatDrawer): void {
        const file: File = event.target.files[0];
        let conf = {
            component: PopUpMessagesComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Importar configurações',
                subTitle: 'Tem certeza que deseja importar as configurações?<br>Isso substituirá as configurações atuais da versão Building<br> para as configurações presentes no arquivo selecionado.<br>',
                optionalText: 'Clique em \'Confirmar\' para prosseguir ou \'Cancelar\' para retornar.',
                firstButtonText: 'CONFIRMAR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'titlePopUpConfirmationMessage',
            },
        };
        this.getDialogRef(conf).afterClosed().subscribe((response) => {
            if (response) {
                this.startSpinner('Importando configurações...');
                this.projectService.upload(file).subscribe({
                    next: (uploadedProject) => {
                        if (uploadedProject) {
                            drawer.close();
                            this.stopSpinner();
                            conf.data.imageUrl = 'assets/images/status/successMessage.svg';
                            conf.data.title = 'Configurações importadas com sucesso!';
                            conf.data.subTitle = '';
                            conf.data.optionalText = '';
                            conf.data.typePopUp = 'titlePopUpInformationMessage';
                            this.openDialog(conf);
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.currentProjectService
                                    .getDefaultVersionFromServer(uploadedProject, 'BUILDING')
                                    .subscribe((currentProject) => {
                                        this.lsService.reset(currentProject);
                                        this.lsService.triggerLocalStorageChanges(currentProject);
                                        this.currentProjectService.setCurrentProject(currentProject);
                                        this.currentProjectService.goHome();
                                    });
                            });
                        }
                    },
                    error: (err) => {
                        drawer.close();
                        conf.data.imageUrl = 'assets/images/status/errorMessage.svg';
                        conf.data.title = 'Erro ao restaurar as configurações.';
                        conf.data.subTitle = 'Tente novamente.';
                        conf.data.typePopUp = 'titlePopUpInformationMessage';
                        this.stopSpinner();
                        this.openDialog(conf);
                    }
                });
            }
            else {
                event.target.value = null;
                drawer.close();
            }
        });
    }

    isHaveVersions() {
        return this.versions.length > 1;
    }

    verifyVersion() {
        const project = this.currentProjectService.getCurrentProject()?.versionType;
        return project.substring(0, 1).toUpperCase() + project.substring(1).toLowerCase();
    }

    startSpinner(text: string) {
        this.loaderMessage = text;
        this.isSpinnerActive = true;
    }

    stopSpinner() {
        this.isSpinnerActive = false;
    }

    fixDate(date: string) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
}


