import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/views/login/login.service';
import { MessagesEnum } from '../../models/enum/messages.enum';
import { Project } from '../../models/views-models/project.model';
import { ProjectSettings } from '../../models/views-models/projectSettings.model';
import { ProjectStatus } from '../../models/views-models/projectStatus.model';
import { InfraSystemParams, ProjectSystemParams } from '../../models/views-models/projectSystemParams';
import { UserPermission } from '../../models/views-models/user.model';
import { AuthService } from '../../service/auth/auth.service';
import { LayoutService } from '../../service/layout/layout.service';
import { ThemeService } from '../../service/theme/theme.service';
import { CurrentProjectService } from '../../service/views-services/current-project.service';
import { LocalStorageService } from '../../service/views-services/localstorage.service';
import { ProjectService } from '../../service/views-services/project.service';
import { SystemParamService } from '../../service/views-services/systemParam.service';
import { UserService } from '../../service/views-services/user.service';
import { WebSocketService } from '../../service/websocket/websocket.service';
import { copy } from '../../utils/copy';
import { formatDateObj } from '../../utils/date.utils';
import { Views } from '../../utils/views-urls';
import { PopUpMessagesComponent } from '../pop-up-messages/pop-up-messages.component';
import { ProjectSettingsComponent } from '../views-components/project-settings/project-settings.component';
import { UserDialogComponent } from '../views-components/user-dialog/user-dialog.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() sidenav: MatSidenav;

    public egretThemes;
    public layoutConf: any;
    userAuthenticated: any;

    pageTitle: string;
    currentProject: string;
    dialogRef: any;
    user: UserPermission;
    currentUser: any;

    projects: Project[];
    filtredProjects: Project[];
    project: Project;
    projectStatus: ProjectStatus = new ProjectStatus();
    projectSettings: ProjectSettings = new ProjectSettings();
    isProjectOn: boolean = false;
    isProjectApt: boolean = false;
    subs: Array<Subscription> = [];

    showFiller = false;
    projectVersions: Project[];
    isLoading: boolean = false;
    isSwitchingProject: boolean = false;
    loaderMessage: string;

    currentProjectSysParams: ProjectSystemParams;
    currentInfraSysParams: InfraSystemParams;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private themeService: ThemeService,
        private loginService: LoginService,
        private projectService: ProjectService,
        private userService: UserService,
        private lsService: LocalStorageService,
        private layout: LayoutService,
        public dialog: MatDialog,
        public currentProjectService: CurrentProjectService,
        private authService: AuthService,
        private renderer: Renderer2,


        private webSocketService: WebSocketService,
        private systemParamService: SystemParamService
    ) { }

    ngOnInit() {
        this.updateDefaultProject(false);

        this.subs.push(
            this.currentProjectService.observeCurrentProject().subscribe((project) => {
                if (this.project?.id != project?.id) {
                    this.getSessionData();
                    this.validadeReloadQuaterback();
                }
                this.project = project;
            })
        );
        this.getSessionData();
        this.pageTitle = this.route.snapshot.firstChild.data['title'];
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.subs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.router.navigated = false;
                }
            })
        );


        this.validadeReloadQuaterback();
        this.subs.push(
            this.webSocketService.getMessages().subscribe({
                next: (payload) => {
                    const message = payload;
                    this.systemParamService.updateSystemParams(message);
                },
                error: (err) => { },
            })
        );
        this.subs.push(
            this.systemParamService.observeAllProjectParams().subscribe((projects) => {
                const project = copy(projects[this.project.mainProjectId]);
                if (project !== this.currentProjectSysParams) {
                    this.currentProjectSysParams = projects[this.project.mainProjectId];
                }
            })
        );
        this.subs.push(
            this.systemParamService.observeInfraParams().subscribe((infra) => {
                this.currentInfraSysParams = infra;
            })
        )
    }

    validadeReloadQuaterback() {
        let reloadQuaterback = this.lsService.getQuaterbackReload();
        if (reloadQuaterback && reloadQuaterback.reload) {
            let lastDateUpdate = new Date(reloadQuaterback.data);
            lastDateUpdate.setSeconds(lastDateUpdate.getSeconds() + 10);
            if (lastDateUpdate < new Date()) {
                this.reloadQuaterback();
                this.markQuaterbackAsNotReload();
            }
        }
    }

    getAllProjects() {
        this.subs.push(
            this.projectService.getAllProjects().subscribe((projects) => {
                this.projects = projects;
                this.filtredProjects = this.projects?.filter((p) => p.versionType == this.project?.versionType);
                if (projects.length == 0) {
                    this.lsService.clean(this.project);
                }
            })
        );
    }

    getSessionData() {

        this.authService.getUserPermission().then(userData => {
            this.user = userData
        })


        if (this.validateCurrentProject()) {
            const currentProject = this.currentProjectService.getCurrentProject();

            this.currentProject = currentProject.name;
            this.project = currentProject;
        }
    }

    /**
     * Validate whether project is present in User object. If not, search the the default project
     */
    validateCurrentProject() {
        if (!this.currentProjectService.hasCurrentProject()) {
            this.updateDefaultProject(true);
            return false;
        }
        return true;
    }

    updateDefaultProject(forceUpdate: boolean) {
        if (this.lsService.hasDefaultProject()) {
            const currentProject = this.lsService.getDefaultProject();

            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                const projectVersions = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');

                const defaultProject =
                    projectVersions.find((p) => p.versionType == currentProject.versionType) || currentProject;

                this.project = defaultProject;
                if (forceUpdate || !this.currentProjectService.hasCurrentProject()) {
                    this.updateProjectDefault(defaultProject);
                }
                this.getProjectVersions(true);
            });
        } else {
            const subscribed = this.projectService.getDefaultProject().subscribe((project) => {
                this.project = project;
                if (forceUpdate) {
                    this.updateProjectDefault(project);
                }
                this.getProjectVersions(true);
            });

            this.subs.push(subscribed);
        }
    }

    selectProject(project: Project) {
        project.projectDefault = true;
        this.startLoader('Carregando projeto…');
        this.subs.push(
            this.projectService.setProjectDefault(project).subscribe(
                (response) => {
                    this.project = response;
                    this.updateProjectDefault(response);
                    this.currentProjectService.goReload();
                    this.stopLoader();
                },
                (error) => {
                    console.error('Erro ao selecionar projeto:', error);
                    let conf = {
                        component: PopUpMessagesComponent,
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            imageUrl: 'assets/images/status/errorMessage.svg',
                            title: `Erro ao selecionar novo projeto`,
                            subTitle: `${MessagesEnum.projectSwitchError}: "${project.name}"`,
                            firstButtonText: 'OK',
                            typePopUp: 'titlePopUpInformationMessage',
                        },
                    };
                    this.stopLoader();
                    this.openDialog(conf);
                }
            )
        );
    }

    updateProjectDefault(project: Project) {
        this.currentProject = this.project.name;
        this.lsService.reset(this.project);
        this.lsService.triggerLocalStorageChanges(project);
        this.currentProjectService.setCurrentProject(project);
    }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
    }

    deploySettings() {
        let conf = {
            component: PopUpMessagesComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Confirmação de Deploy',
                subTitle: MessagesEnum.confirmDeployMessage + this.project.name + '?',
                optionalText: "Clique em 'Confirmar' para prosseguir ou 'Cancelar' para retornar",
                firstButtonText: 'DEPLOY',
                secondButtonText: 'CANCEL',
                typePopUp: 'titlePopUpConfirmationMessage',
            },
        };
        this.openDialog(conf);

        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.startLoader('Realizando deploy, aguarde...');
                this.projectService.deploySettings().subscribe({
                    next: (system) => {
                        this.markQuaterbackAsReload();
                        this.projectService.reloadQuaterback().subscribe((isReload) => {
                            conf.data.typePopUp = 'titlePopUpInformationMessage';
                            conf.data.firstButtonText = '';
                            conf.data.secondButtonText = '';
                            conf.data.imageUrl = isReload
                                ? 'assets/images/status/successMessage.svg'
                                : 'assets/images/status/errorMessage.svg';
                            conf.data.title = isReload ? 'Deploy Realizado' : 'Erro no Deploy';
                            conf.data.subTitle = isReload
                                ? MessagesEnum.deploySuccessMessage
                                : MessagesEnum.quarterbackError;
                            conf.data.optionalText = isReload
                                ? `A nova versão do projeto ${this.currentProject} está agora em execução.`
                                : 'Por favor, verifique os logs para mais detalhes e tente novamente.';
                            this.openDialog(conf);
                        });
                        this.stopLoader();
                        this.updateDeployProjects();
                    },
                    error: (err) => {
                        this.stopLoader();
                        conf.data.typePopUp = 'titlePopUpInformationMessage';
                        conf.data.firstButtonText = '';
                        conf.data.secondButtonText = '';
                        conf.data.imageUrl = 'assets/images/status/errorMessage.svg';
                        conf.data.title = 'Erro no Deploy';
                        conf.data.subTitle = MessagesEnum.deployErrorMessage;
                        conf.data.optionalText = 'Por favor, verifique os logs para mais detalhes e tente novamente.';
                        console.error(err);
                        this.openDialog(conf);
                    },
                });
            }
        });
    }

    updateDeployProjects() {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                let buildingProject = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .find((v) => v?.versionType == 'RUNNING');

                this.currentProjectService.setCurrentProject(buildingProject);
                this.currentProjectService.goReload();
            });
        }
    }

    startLoader(message: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }

    markQuaterbackAsReload() {
        this.lsService.setQuaterbackReload({
            reload: true,
            data: new Date(),
        });
    }

    markQuaterbackAsNotReload() {
        this.lsService.setQuaterbackReload({
            reload: false,
            data: new Date(),
        });
    }

    reloadQuaterback() {
        this.projectService.reloadQuaterback().subscribe((_) => { });
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            minWidth: options.minWidth ? options.minWidth : 'auto',
            height: 'auto',
            data: options.data,
        });
    }

    navigateHome() {
        this.router.navigate(['entradas']);
    }

    changeTheme(theme) {
        this.themeService.changeTheme(this.renderer, theme);
    }

    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
            });
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed',
        });
    }

    onLogout() {
        this.loginService.logout();
        //window.location.assign('/login');
    }

    openUserSettings() {
        let userDialog = {
            component: UserDialogComponent,
            minWidth: '700px',
            data: '',
        };
        this.userService.getUserByUsername(this.user?.userName).subscribe((user) => {
            if (user && this.user && user.userName == this.user?.userName) {
                this.currentUser = user;
                userDialog.data = this.currentUser;
                this.openDialog(userDialog);
            }
        });
    }

    editProjectSettings() {
        const currentProject = this.currentProjectService.getCurrentProject();
        this.projectService.getProjectSettings(currentProject.id).subscribe((response) => {
            this.projectSettings = response;
            let conf = {
                component: ProjectSettingsComponent,
                panelClass: 'custom-dialog-container',
                data: this.projectSettings,
            };
            this.openDialog(conf);
        });
    }

    navigateToUsers() {
        this.router.navigate([Views.usersList.url]);
    }

    isDeployDisabled() {

        const project = this.currentProjectService.getCurrentProject();
        return !this.user?.permissions?.canUpdate || this.projectVersions?.length > 1 && project?.versionType == 'RUNNING';
    }

    isBuilding() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType == 'BUILDING';
    }

    getProjectVersions(updateStatus) {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                this.projectVersions = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');
            });
        }
    }
}
