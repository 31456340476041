import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API = environment.serverUrl;

@Injectable()
export class LoginService {
    constructor(public oidcSecurityService: OidcSecurityService) { }

    isUserLogged(): Observable<boolean> {

        return this.oidcSecurityService.isAuthenticated();

    }


    logout() {
        this.oidcSecurityService.logoff().subscribe(l => console.log("loggout " + l))

    }

    login() {
        this.oidcSecurityService.authorize();
    }

    refreshToken() {
        return this.oidcSecurityService.forceRefreshSession()
    }
}
