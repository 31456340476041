<div class="container" fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div fxLayout="row" class="painel">
        <!-- Left div -->
        <div fxFlex="50%" class="caminho-2" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4%">
                <img mat-card-image class="img simbolo" src="assets/images/logo-nebuluz/nebulus-simbolo.png" />
                <img mat-card-image class="img marca" src="assets/images/logo-nebuluz/nebulus-marca.png" />
                <img mat-card-image class="img bem-vindo" src="assets/images/logo-bem-vindo/bem-vindo-logo@2x.png" />
            </div>
        </div>
        <!-- end :: Left div -->

        <!-- Right div -->
        <div fxFlex="50%" class="caminho-1" fxLayoutAlign="center center">
            <div fxLayout="column" fxFlex="70%">
                <form [formGroup]="loginForm" (ngSubmit)="signin()">
                    <!-- username -->
                    <div fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário</mat-label>
                            <input matInput type="text" name="username" [formControl]="loginForm.controls['username']"
                                data-cy="user" />
                        </mat-form-field>
                        <label *ngIf="
                                loginForm.controls['username'].hasError('required') &&
                                loginForm.controls['username'].touched
                            " class="form-error-msg">
                            Usuário obrigatório
                        </label>
                        <label *ngIf="
                                loginForm.controls['username'].hasError('pattern') &&
                                loginForm.controls['username'].touched
                            " class="form-error-msg">
                            Usuário inválido
                        </label>
                    </div>
                    <!-- username -->
                    <!-- password -->
                    <div fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <input matInput type="password" name="password"
                                [formControl]="loginForm.controls['password']" data-cy="password" autocomplete="on" />
                        </mat-form-field>
                        <label *ngIf="
                                loginForm.controls['password'].hasError('required') &&
                                loginForm.controls['password'].touched
                            " class="form-error-msg">
                            Senha obrigatória
                        </label>
                        <label *ngIf="
                                loginForm.controls['password'].hasError('minlength') &&
                                loginForm.controls['password'].touched
                            " class="form-error-msg">
                            O tamanho mínimo da senha é de 6 caracteres
                        </label>
                    </div>
                    <!-- password -->
                    <div fxLayout="row" fxLayoutAlign="center start">
                        <button mat-raised-button fxLayoutAlign="center center" class="blueButton"
                            [disabled]="loginForm.invalid" data-cy="enter">
                            ENTRAR
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- end :: Right div -->
    </div>
</div>