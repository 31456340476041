import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDialogComponent } from 'src/app/shared/components/views-components/project-dialog/project-dialog.component';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { CurrentProjectService } from '../../shared/service/views-services/current-project.service';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';

@Component({
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
    projectsFound: number = 0;
    projects: Project[];
    dialogRef: any;
    user: UserPermission;

    constructor(private projectService: ProjectService, public dialog: MatDialog, private authService: AuthService,
        public currentProjectService: CurrentProjectService) { }

    async ngOnInit() {

        this.user = await this.authService.getUserPermission();

        this.loadProjects();
        this.getCurrentProjectVersionType()
    }

    loadProjects() {
        this.projectService.getAllProjects().subscribe((projects) => {
            let currentProject = this.currentProjectService.getCurrentProject();
            this.projects = projects.map(p => {
                if (currentProject) {
                    p.projectDefault = p.mainProjectId == currentProject.mainProjectId
                }
                return p;
            }).filter((p) => p.versionType == currentProject.versionType);
            this.projectsFound = this.projects.length;
        });
    }

    reloadRequired(projectChanges) {
        this.loadProjects();
    }

    addProject() {
        this.openDialog(null);
        this.dialogRef.afterClosed().subscribe(() => {
            this.loadProjects();
        });
    }

    openDialog(data: any): void {
        this.dialogRef = this.dialog.open(ProjectDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: 'auto',
            height: 'auto',
            maxWidth: '1200px',
            maxHeight: '800px',
            data: data,
        });
    }

    getCurrentProjectVersionType(): string {
        return this.currentProjectService.getCurrentProject().versionType;
    }
}
