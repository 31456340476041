import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SystemConsts } from 'src/app/shared/models/enum/systemConsts';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Category } from 'src/app/shared/models/views-models/category.model';
import { CurveType } from 'src/app/shared/models/views-models/curveType.model';
import { FuzzySet } from 'src/app/shared/models/views-models/fuzzySet.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { CategoriesService } from 'src/app/shared/service/views-services/categories.service';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
@Component({
    selector: 'app-add-antecedent',
    templateUrl: './add-antecedent.component.html',
    styleUrls: ['./add-antecedent.component.scss'],
})
export class AddAntecedentComponent implements OnInit {
    @Input() title: string;
    @Input() parentForm: UntypedFormGroup;
    @Output() resetEditing = new EventEmitter();
    user: UserPermission;
    isAVG = false;
    @Input() fuzzySets: FuzzySet[];
    @Input() curveTypes: CurveType[];
    @Input() tags: Tag[];
    @Input() parentLoaded: boolean;
    @Input() process: Tag[];
    tagsOptions: any;
    variableTypes: Array<Category>;
    lastFilterApplied: any;
    isAntecedent1 = false;
    maxValue: any;
    minValue: any;
    antecedent: string;
    currentTag: string;
    variableType: string;

    constructor(private categories: CategoriesService, public dialog: MatDialog, private authService: AuthService,
        private currentProjectService: CurrentProjectService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.curveTypes && changes.curveTypes.currentValue) {
            this.setDefaultFields();
        }
        if (changes.parentLoaded && changes.parentLoaded.currentValue) {
            this.loadData();
        }
    }

    async ngOnInit() {

        this.user = await this.authService.getUserPermission();
        this.variableTypes = this.categories
            .listAllCategories()
            .sort((a, b) => a.description.localeCompare(b.description));
        if (this.title == 'Antecedente 1') {
            this.isAntecedent1 = true;
        }
        this.loadData();
    }

    loadData() {
        this.updateTagsOptions(this.parentForm.get('variableType').value);
        this.setMaxMin(this.parentForm.get('entryName').value);
        this.antecedent = this.parentForm.get('entryName').value;
        this.variableType = this.parentForm.get('variableType').value;
        if (!this.user?.permissions.canUpdate) {
            this.parentForm.disable();
        }
        else {
            this.parentForm.enable();
        }
        if (!this.user?.permissions.canCreate) {
            this.parentForm.controls['entryName'].disable();
            this.parentForm.controls['variableType'].disable();
        } else {
            this.parentForm.controls['entryName'].enable();
            this.parentForm.controls['variableType'].enable();

        }
    }

    setDefaultFields() {
        this.parentForm.patchValue({
            curveType: this.curveTypes[0].id,
        });
    }

    clearField(tagName) {
        this.currentTag = tagName;
        this.parentForm.patchValue({
            entryName: '',
        });
    }

    restoreInput() {
        this.checkTendenceOptions();
        this.parentForm.patchValue({
            entryName: this.currentTag,
        });
    }

    checkTendenceOptions() {
        let isFuzzySetThreeOptions =
            this.parentForm.get('fuzzySet').value == SystemConsts.FUZZY_SET_THREE_OPTIONS.id ? true : false;
        let haveAntecedentTypeChanged = this.variableType == this.parentForm.get('variableType').value ? false : true;
        let isCalculatedProcessType =
            this.parentForm.get('variableType').value == VariableTypeEnum.CALCULATED ? true : false;
        if (
            isFuzzySetThreeOptions &&
            (haveAntecedentTypeChanged || isCalculatedProcessType) &&
            (this.antecedent.startsWith(VariableTypeEnum.GRD) || isCalculatedProcessType) &&
            this.antecedent.startsWith(VariableTypeEnum.GRD) != this.currentTag.startsWith(VariableTypeEnum.GRD)
        ) {
            this.fuzzyChange();
        }
    }

    updateTagsOptions(type: string) {
        this.tagsOptions = this.tags
            .filter((tag) => tag.variableType.name == type)
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    setMaxMin(tagName: string) {
        if (tagName) {
            let tag = this.tags.find((tag) => tag.name == tagName);
            this.currentTag = tagName;
            if (tag) {
                this.parentForm.patchValue({
                    min: tag.min,
                    max: tag.max,
                });

                const isCalculatedVariable = this.parentForm.get('variableType').value == 'equation';
                const isAVGTag = tagName.startsWith(VariableTypeEnum.AVG);
                const isGRDTag = tagName.startsWith(VariableTypeEnum.GRD);
                this.isAVG = isAVGTag;

                if ((isCalculatedVariable || isAVGTag) && this.isShowForm()) {
                    this.parentForm.get('min').enable();
                    this.parentForm.get('max').enable();
                }

                if (isAVGTag) {
                    // TODO
                    // let inputTag = this.process.find((tag) => tag.name == tagName.slice(4));
                    // if (inputTag) {
                    //     this.minValue = inputTag.min;
                    //     this.maxValue = inputTag.max;
                    // }
                    this.parentForm.controls['min'].setValidators([
                        Validators.required,
                        // TODO
                        // Validators.min(this.minValue),
                        //Validators.max(this.parentForm.get('max').value),
                    ]);
                    this.parentForm.controls['max'].setValidators([
                        Validators.required,
                        // TODO
                        // Validators.max(this.maxValue),
                        //Validators.min(this.parentForm.get('min').value),
                    ]);
                }

                if (isGRDTag && !isCalculatedVariable) {
                    this.parentForm.get('min').disable();
                    this.parentForm.get('max').disable();
                    this.parentForm.controls['min'].setValidators([Validators.required]);
                    this.parentForm.controls['max'].setValidators([Validators.required]);
                }
            } else {
                this.parentForm.patchValue({
                    min: '',
                    max: '',
                });
            }
            this.fuzzyChange();
        }
    }

    fuzzyChange() {
        this.antecedent = this.currentTag;
        this.feedback();
    }

    feedback() {
        this.resetEditing.emit(false);
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType === 'BUILDING';
    }
}
