import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserPermission } from '../../models/views-models/user.model';
import { UserService } from '../views-services/user.service';

const OWNER = 'ROLE_OWNER';
const ADMIN = 'ROLE_ADMIN';
const MAINTAINER = 'ROLE_MAINTAINER';
const USER = 'ROLE_USER'

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private $userPermission: BehaviorSubject<UserPermission> = new BehaviorSubject<UserPermission>(null)

    constructor(public oidcSecurityService: OidcSecurityService, private userService: UserService) {



    }



    private canCreate(roles: Array<string>): boolean {
        return roles.some(role => role == OWNER || role == ADMIN)
    }


    private canRead(roles: Array<string>): boolean {
        return true

    }

    private canUpdate(roles: Array<string>): boolean {
        return roles.some(role => role == OWNER || role == ADMIN || role == MAINTAINER)
    }


    private canDelete(roles: Array<string>): boolean {
        return roles.some(role => role == OWNER || role == ADMIN)
    }



    private isSuperUser(roles: Array<string>): boolean {
        return roles.some(role => role == OWNER)
    }



    public async getUserPermission(): Promise<UserPermission> {
        const userPermissionService = this.$userPermission.getValue();
        if (userPermissionService) {
            return userPermissionService;
        }

        const userData = await lastValueFrom(this.oidcSecurityService.getUserData());
        const user = await lastValueFrom(this.userService.getUserByUsername(userData.preferred_username));

        const userPermission = {
            ...user,
            isSuperUser: this.isSuperUser(user.roles),
            permissions: {
                canCreate: this.canCreate(user.roles),
                canRead: this.canRead(user.roles),
                canUpdate: this.canUpdate(user.roles),
                canDelete: this.canDelete(user.roles),
                ldapAuthorization: true,
            }
        } as UserPermission

        this.$userPermission.next(userPermission);

        return userPermission;
    }
}
