import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { OpcServer } from '../../models/views-models/opcServer.model';

const API = environment.serverUrl;

@Injectable()
export class OpcServerService {
    constructor(private httpClient: HttpClient) { }

    getAllOpcServers(): Observable<OpcServer[]> {
        return this.httpClient.get<Response>(`${API}opcServer`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const settings: OpcServer[] = response.data;
                return settings;
            })
        );
    }

    saveOpcServer(opcServerSettings: OpcServer): Observable<OpcServer> {
        return this.httpClient
            .post<Response>(`${API}opcServer`, opcServerSettings, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const settings: OpcServer = response.data;
                    return settings;
                })
            );
    }

    removeOpcServer(id: string): Observable<string> {
        return this.httpClient.delete<Response>(`${API}opcServer/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const settings: string = response.data;
                return settings;
            })
        );
    }

    editOpcServer(id: string, opcServerSettings: OpcServer): Observable<OpcServer> {
        return this.httpClient
            .put<Response>(`${API}opcServer/${id}`, opcServerSettings, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const settings: OpcServer = response.data;
                    return settings;
                })
            );
    }

    private getHeaders(): HttpHeaders {

        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',

        });
        return headers;
    }
}
