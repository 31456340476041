import { Injectable } from '@angular/core';
import { BehaviorSubject, last, Observable } from 'rxjs';
import { ProjectParamsObj, ProjectParams, InfraSystemParams } from '../../models/views-models/projectSystemParams';
import { copy } from '../../utils/copy';

@Injectable({
    providedIn: 'root',
})
export class SystemParamService {
    private projectStatusObj$: BehaviorSubject<ProjectParamsObj> = new BehaviorSubject<ProjectParamsObj>({});
    private infraParams$: BehaviorSubject<InfraSystemParams> = new BehaviorSubject<InfraSystemParams>({
        quarterbackStatus: false,
        engineStatus: false,
        workerStatus: false,
    });

    constructor() {}

    observeAllProjectParams(): Observable<ProjectParamsObj> {
        return this.projectStatusObj$.asObservable();
    }

    observeInfraParams(): Observable<InfraSystemParams> {
        return this.infraParams$.asObservable();
    }

    updateSystemParams(message) {
        if (message.role === 'project_status') {
            this.updateProjectParams(message.name, message.mainProjectId, message.lastRun, message.systemParam);
            return;
        }

        if (message.role === 'infra_status') {
            this.updateInfraParams(message);
            return;
        }
    }

    updateProjectParams(projectName: string, projectId: string, lastRun: string, newParam: ProjectParams): void {
        const currentProjectsStatus = copy(this.projectStatusObj$?.getValue());

        if (!currentProjectsStatus[projectId]) {
            currentProjectsStatus[projectId] = {
                name: projectName,
                id: projectId,
                lastRun: lastRun,
                systemParams: {
                    projectOn: newParam.projectOn,
                    processStatus: newParam.processStatus,
                    projectApt: newParam.projectApt,
                    projectOnWebApp: newParam.projectOn
                },
            };
        }

        if (currentProjectsStatus[projectId].systemParams !== newParam) {
            currentProjectsStatus[projectId].systemParams = newParam;
        }

        if (currentProjectsStatus[projectId].name !== projectName) {
            currentProjectsStatus[projectId].name = projectName;
        }

        currentProjectsStatus[projectId].lastRun = lastRun;
        this.projectStatusObj$.next({ ...currentProjectsStatus });
    }

    updateInfraParams(infraStatus): void {
        const infra = { ...infraStatus };

        delete infra.role;
        this.infraParams$.next({ ...infra });
    }

    deleteProject(mainProjectId) {
        const currentProjectStatus = this.projectStatusObj$.getValue();

        if (!currentProjectStatus[mainProjectId]) {
            return;
        }

        delete currentProjectStatus[mainProjectId];
        this.projectStatusObj$.next({ ...currentProjectStatus });
    }
}
