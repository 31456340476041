<mat-card appearance="outlined">
    <div fxLayout="row" fxLayoutGap="40px">
        <div fxLayout="column" fxFlex>
            <div>
                <div fxLayout="row">
                    <div fxFlex="35%" fxLayout="row" fxLayoutAlign="start center">
                        <i class="material-icons layers-icon">layers</i>
                        <span class="title">{{ projectName }}</span>
                        <button mat-icon-button (click)="editProject()" *ngIf="user?.permissions?.canUpdate">
                            <i class="material-icons">{{ getCurrentProjectVersionType() === 'BUILDING'? 'edit' :
                                'visibility' }}</i>
                        </button>
                    </div>
                    <div class="area" fxFlex fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="35px">
                        <span>{{ projectArea }}</span>

                        <div *ngIf="getCurrentProjectVersionType() === 'RUNNING'" class="nbz-status" fxLayout="row"
                            fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                <i class="material-icons {{
                                        currentProjectSysParams?.systemParams?.projectOnWebApp
                                            ? 'icon-on'
                                            : 'icon-off'
                                    }}">fiber_manual_record</i>
                                <span>Projeto</span>
                                <span class="bold">{{
                                    currentProjectSysParams?.systemParams?.projectOnWebApp ? "ligado" : "desligado"
                                    }}</span>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                <i
                                    class="material-icons {{ currentProjectSysParams?.systemParams?.projectApt ? 'icon-on' : 'icon-off' }}">{{
                                    currentProjectSysParams?.systemParams?.projectApt ? "check_circle" : "cancel"
                                    }}</i>
                                <span>Projeto</span>
                                <span class="bold">{{ currentProjectSysParams?.systemParams?.projectApt ? "apto" :
                                    "inapto" }}</span>
                                <a [matMenuTriggerFor]="projectStatusMenu">
                                    <div fxLayout="column" fxLayoutAlign="end">
                                        <i class="material-icons menu bold">arrow_drop_down</i>
                                    </div>
                                </a>
                            </div>
                            <div class="last-date" fxLayout="row" fxFlex fxLayoutAlign="start center">
                                <span>Última Execução:
                                    {{ currentProjectSysParams?.lastRun ? lastRunStr : emptyLastRunAtStr }}</span>
                            </div>
                            <mat-menu #projectStatusMenu="matMenu" xPosition="before">
                                <div fxLayout="column" class="menu-container" fxLayoutGap="10px">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i class="material-icons {{
                                                currentInfraSysParams?.quarterbackStatus ? 'icon-apt' : 'icon-off'
                                            }}">{{ currentInfraSysParams?.quarterbackStatus ? "check_circle" : "cancel"
                                            }}</i>
                                        <span>Status da Coleta de Dados</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i class="material-icons {{
                                                currentInfraSysParams?.engineStatus ? 'icon-apt' : 'icon-off'
                                            }}">{{ currentInfraSysParams?.engineStatus ? "check_circle" : "cancel"
                                            }}</i>
                                        <span>Status do Tratamento de Dados</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i class="material-icons {{
                                                currentInfraSysParams?.workerStatus ? 'icon-apt' : 'icon-off'
                                            }}">{{ currentInfraSysParams?.workerStatus ? "check_circle" : "cancel"
                                            }}</i>
                                        <span>Status dos Cálculos</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i class="material-icons {{
                                                currentProjectSysParams?.systemParams?.processStatus ? 'icon-apt' : 'icon-off'
                                            }}">{{ currentProjectSysParams?.systemParams?.processStatus ?
                                            "check_circle" : "cancel" }}</i>
                                        <span>Status do Processo</span>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <span class="description">
                {{ projectDescription }}
            </span>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
            <button class="customButton add-button" mat-raised-button type="button" (click)="setProjectDefault()"
                [disabled]="project.projectDefault" data-cy="set-default">
                {{ project.projectDefault ? "PROJETO SELECIONADO" : "SELECIONAR PROJETO" }}
            </button>
            <div fxLayout="row" fxLayoutGap="5px"
                [fxLayoutAlign]="getCurrentProjectVersionType() === 'BUILDING'? 'center center' : 'end center'">
                <span class="project-status"
                    *ngIf="user?.permissions?.canUpdate && this.getCurrentProjectVersionType() === 'BUILDING'">
                    {{ projectSettings.projectOnWebapp ? "Ligado" : "Desligado" }}
                    <mat-slide-toggle color="primary" (change)="changeProjectStatus()"
                        checked="{{ projectSettings.projectOnWebapp }}" data-cy="toggle-on"></mat-slide-toggle>
                </span>
                <button mat-icon-button (click)="editProjectSettings()" data-cy="project-settings">
                    <i *ngIf="getCurrentProjectVersionType() === 'BUILDING'" class="material-icons">settings</i>
                    <img *ngIf="getCurrentProjectVersionType() === 'RUNNING'"
                        src="assets\images\icons\visibility-settings\visibility-settings.svg" />
                </button>
                <div matTooltipPosition="right"
                    [matTooltip]="project.projectDefault ? 'Este projeto está em uso e não pode ser excluído. Para concluir a ação, selecione outro projeto e tente novamente.' : ''"
                    [matTooltipDisabled]="!project.projectDefault">
                    <button mat-icon-button [disabled]="project.projectDefault" (click)="deleteProject()"
                        *ngIf="user?.permissions?.canDelete && this.getCurrentProjectVersionType() === 'BUILDING'"
                        data-cy="delete">
                        <i class="material-icons" [ngClass]="project.projectDefault ? 'noDelete' : ''">delete</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-card>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>