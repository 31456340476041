<mat-sidenav-container class="sidenav-main" [hasBackdrop]="false" autosize>
    <div fxFill fxLayout="row" fxLayoutAlign="center center">
        <mat-card *ngIf="standardSearchTime > 0" class="container">
            <div class="card">
                <!-- <app-date-navigator
                    [standardSearchTime]="standardSearchTime"
                    [cycleTime]="cycleTime"
                    [navigatorPlaceholder]="navigatorPlaceholder"
                    [tagNames]="tagNames"
                    [redirectedTag]="redirectedTag"
                    (dateChanges)="updateChartInfo($event)"
                    [isSetpointTracking]="true"
                ></app-date-navigator> -->
                <app-date-navigator [standardSearchTime]="standardSearchTime"
                    [navigatorPlaceholder]="navigatorPlaceholder" [tagNames]="tagNames" [redirectedTag]="redirectedTag"
                    (dateChanges)="updateChartInfo($event)"></app-date-navigator>
                <div *ngIf="axisConfig?.output">
                    <mat-divider></mat-divider>
                </div>
                <div class="mt-25" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center"
                    *ngIf="axisConfig?.output">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
                        <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center" class="sp-info">
                            <span class="title">Parâmetros</span>
                            <span>Ciclos: {{ numberOfCycles }} = {{ cycleDuration }}s</span>
                            <span [matTooltip]="getSetpointConditionsTooltip()" matTooltipClass="tooltip-multilines"
                                *ngIf="isSetpointTag()">
                                Incremento: {{ axisConfig?.output?.increment_tag.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="axisConfig?.output">
                <div fxLayout="row">
                    <div fxFlex>
                        <app-setpoint-chart *ngIf="dateInterval" [(axisConfig)]="axisConfig"
                            [(dateInterval)]="dateInterval" (selectCycle)="updateSelectedCycle($event)"
                            class="chart-container"></app-setpoint-chart>
                    </div>

                    <div fxFlex="110px" fxLayoutAlign="end center">
                        <app-side-panel-info [(panelConfig)]="openPanel"> </app-side-panel-info>
                    </div>
                </div>
            </div>
            <div class="blue-button-container">
                <a mat-fab *ngIf="axisConfig?.output" class="blue-button-settings" (click)="openSettings()"
                    data-cy="axisSettings">
                    <mat-icon class="icon-settings">settings</mat-icon>
                </a>
            </div>
        </mat-card>
    </div>
</mat-sidenav-container>