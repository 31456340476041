<form [formGroup]="dateSelectionForm">
    <div class="navigation-card" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start start">
                <span>Tag</span>
                <mat-form-field appearance="outline" class="data-select no-padding">
                    <mat-label>{{ navigatorPlaceholder }}</mat-label>
                    <input
                        type="text"
                        matInput
                        list="tagList"
                        autocomplete="off"
                        (change)="applyDateInterval()"
                        (focus)="resetTag()"
                        (focusout)="checkPreviousTag()"
                        formControlName="tag"
                    />
                </mat-form-field>
                <datalist id="tagList">
                    <option *ngFor="let name of tagNames" [value]="name"></option>
                </datalist>
            </div>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start start">
            <span>Período</span>
            <div fxFlex fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" class="date no-padding">
                    <mat-label>Data Início</mat-label>
                    <input
                        matInput
                        type="date"
                        formControlName="startDate"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown.enter)="applyDateInterval()"
                    />
                </mat-form-field>
                <mat-form-field appearance="outline" class="time no-padding">
                    <mat-label>Hora Início</mat-label>
                    <input
                        matInput
                        type="time"
                        formControlName="startTime"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown.enter)="applyDateInterval()"
                    />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding">
                    <mat-label>Data Fim</mat-label>
                    <input
                        matInput
                        type="date"
                        formControlName="endDate"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown.enter)="applyDateInterval()"
                    />
                </mat-form-field>
                <mat-form-field appearance="outline" class="time no-padding">
                    <mat-label>Hora Fim</mat-label>
                    <input
                        matInput
                        type="time"
                        formControlName="endTime"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown.enter)="applyDateInterval()"
                    />
                </mat-form-field>
                <!-- <div *ngIf="isSetpointTracking">
                    <button
                        class="reload"
                        type="button"
                        (click)="setIntervalToNow()"
                        [disabled]="isShowForm()"
                    >
                        <img class="play-pause" src="{{ iconControlOnline }}" />
                    </button>
                </div> -->
                <div>
                    <button
                        class="reload"
                        mat-icon-button
                        type="button"
                        (click)="setIntervalToNow()"
                        [disabled]="isShowForm()"
                    >
                        <i class="material-icons">update</i>
                    </button>
                </div>
            </div>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start start">
            <span>Intervalo de Tempo</span>
            <div fxFlex fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                <button
                    mat-mini-fab
                    color="basic"
                    class="arrow"
                    (click)="shiftChart(false)"
                    [disabled]="!enableLeft || isShowForm()"
                    [ngClass]="!enableLeft || isShowForm() ? 'disable-shift' : ''"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="data-offset no-padding">
                    <mat-label>Deslocar em</mat-label>
                    <input matInput type="string" step="0" formControlName="timeInterval" />
                </mat-form-field>
                <button
                    mat-mini-fab
                    color="basic"
                    class="arrow"
                    (click)="shiftChart(true)"
                    [disabled]="!enableRight || isShowForm()"
                    [ngClass]="!enableRight || isShowForm() ? 'disable-shift' : ''"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
                <button mat-button class="blueButton" (click)="applyDateInterval()" [disabled]="isShowForm()">
                    APLICAR
                </button>
                <!-- <button mat-button class="blueButton" (click)="applyInterval()" [disabled]="isShowForm()">
                    APLICAR
                </button> -->
            </div>
        </div>
    </div>
</form>
