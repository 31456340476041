import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    confirmTitle: string;
    dialogOrigin: string;
    confirmButton: string = 'OK';
    linkedMessages: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router
    ) { }

    ngOnInit() {
        if (this.data.title) {
            this.confirmTitle = this.data.title;
        }
        if (this.data.confirmButton) {
            this.confirmButton = this.data.confirmButton;
        }

        if (this.data.linkMessages && this.data.linkMessages.length > 0) {
            this.linkedMessages = this.data.linkMessages;
        }

        if (this.data.extraMessage) {
            this.data.message_second = this.data.extraMessage;
        }
    }

    close(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    openNewTab(route: string) {
        window.open(window.location.origin + '/' + route, '_blank');
    }
}
