<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Variáveis calculadas</mat-card-title>
        <span class="data-info">Dados atualizados em {{ lastUpdate }}</span>
    </mat-card-header>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <div *ngIf="selectedList.length == 0; else deleteMarked" fxFlexFill fxLayout="row"
            fxLayoutAlign="space-between">
            <div fxFlex="70%" fxLayout="row" fxLayoutGap="25px">
                <div fxFlex="40%">
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar Entradas</mat-label>
                        <input matInput (keyup)="applyFilter($event.target.value)" data-cy="search-input" />
                        <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <button class="customButton add-button" mat-raised-button type="button" (click)="add()"
                    *ngIf="user?.permissions?.canCreate && currentProjectService.isBuildingMode()" data-cy="add">
                    ADICIONAR
                </button>
                <button class="reload" mat-icon-button type="button" (click)="loadDatasource()">
                    <i class="material-icons">refresh</i>
                </button>
            </div>
        </div>
        <ng-template #deleteMarked>
            <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                <button class="customButton add-button" mat-raised-button type="button"
                    (click)="deleteSelectedElements()"
                    [disabled]="!user?.permissions?.canDelete || currentProjectService.isRunningMode()"
                    data-cy="delete">
                    DELETAR
                </button>
            </div>
        </ng-template>

        <div class="table-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox color="primary" [checked]="checkStatus(element)" (change)="markElement(element)"
                            data-cy="checkbox"></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text><a (click)="selectElement(element)">{{ element.name
                            }}</a></app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{ element.description }}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="value">
                    <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.tag_value  && currentProjectService.isRunningMode(); else noData">
                            <app-ellipsis-text>
                                {{ getTagValue(element.tag_value.value) }}
                            </app-ellipsis-text>
                        </div>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="timestamp">
                    <mat-header-cell *matHeaderCellDef> Último cálculo </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div fxFlex="95px"
                            *ngIf="element.tag_value  && currentProjectService.isRunningMode(); else noData"
                            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <app-ellipsis-text>
                                <span>{{ getTimestamp(element.tag_value.timestamp) }}</span>
                            </app-ellipsis-text>
                            <span *ngIf="element.isOldData">
                                <mat-icon class="warning">warning</mat-icon>
                            </span>
                        </div>
                        <ng-template #noData> - </ng-template>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions" class="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-button (click)="selectElement(element)"
                            *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode(); else visibilityButton"
                            matTooltip="Editar" matTooltipPosition="above">
                            <i class="material-icons">edit</i>
                        </button>
                        <button mat-button (click)="delete(element)"
                            *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()"
                            matTooltip="Excluir" matTooltipPosition="above">
                            <i class="material-icons">delete</i>
                        </button>
                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)" matTooltip="Visualizar"
                                matTooltipPosition="above">
                                <i class="material-icons-outlined">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>