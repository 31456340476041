import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectOption } from '../../models/views-models/user.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-error-flow',
  templateUrl: './select-error-flow.component.html',
  styleUrls: ['./select-error-flow.component.scss']
})
export class SelectErrorFlowComponent {
  @Input() options: SelectOption[] = [];
  @Input() selectedValue: any;
  @Input() placeholder: string = '';
  @Input() hint: string;
  @Input() showDescriptions: boolean = true;
  @Input() title: string = '';
  @Input() control: FormControl = new FormControl();
  @Output() selectedValueChange = new EventEmitter<any>();


  onSelectionChange(value: any): void {
    this.selectedValueChange.emit(value);
  }

  get selectedLabel(): string {
    if (this.selectedValue === null || this.selectedValue === undefined) {
      return this.placeholder;
    }
    return this.options.find(opt => opt.value === this.selectedValue)?.label || this.placeholder;
  }
}