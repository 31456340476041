<span class="span-icon" (click)="drawer.toggle(); updateProjects()"> <img class="span-image"
        src="assets/images/icons/history-icon/history.svg"></span>

<mat-drawer #drawer class="history" mode="side" position="end">
    <h4 class="history-title">
        Histórico
        <span class="material-symbols-outlined history-close-button" (click)="drawer.close()"> close </span>
    </h4>

    <div *ngFor="let version of versions">
        <span class="history-version-title" fxLayout="100">Versão {{ getVersionType(version) }}</span>
        <div fxLayout="row" fxLayoutAlign="start center"
            [ngClass]="getVersionType(version) === verifyVersion() ? 'history-content-active' : 'history-content'">
            <div class="history-box">
                <div class="last_change">
                    <span class="change-label">Última alteração em: {{ version?.audit?.updatedDate ?
                        fixDate(version?.audit?.updatedDate) : version.created }}</span>
                    <span class="creator">Por: {{ version.creator }}</span>
                </div>
                <div class="buttons-history">
                    <button class="visibility" (click)="apply(drawer, version)" data-cy="project-menu-restore"
                        *ngIf="getVersionType(version) != verifyVersion()"
                        [ngClass]="getVersionType(version) === verifyVersion()  ? 'visibility-rounded-borders' : ''"
                        matTooltip="Visualizar Versão" matTooltipPosition="above" matTooltipClass="tooltipStyle">
                        <span fxLayoutAlign="center center">
                            <span class="material-icons-outlined menu-icon">visibility</span>
                        </span>
                    </button>
                    <button class="restart_alt" (click)="restore(drawer)" data-cy="project-menu-restore"
                        *ngIf="enableUpload(version)" matTooltip="Restaurar para versão Running"
                        matTooltipPosition="above" matTooltipClass="tooltipStyle">
                        <span fxLayoutAlign="center center">
                            <span class="material-icons-outlined menu-icon"> restart_alt </span>
                        </span>
                    </button>
                    <button class="history-upload" fxLayout="row" fxLayoutAlign="center center"
                        (click)="fileInput.click()" *ngIf="enableUpload(version)" matTooltip="Importar Configurações"
                        matTooltipPosition="above" matTooltipClass="tooltipStyle">
                        <span fxLayoutAlign="center center">
                            <span class="material-symbols-outlined menu-icon"> upload</span>
                        </span>
                    </button>
                    <button (click)="download(version,drawer)" data-cy="project-menu-download" class="download_offline"
                        matTooltip="Exportar Configurações" matTooltipPosition="above" matTooltipClass="tooltipStyle"
                        [ngClass]="getVersionType(version) === verifyVersion() && verifyVersion() == 'Running' ? 'rounded-borders' : 'stretch-borders'">
                        <span fxLayoutAlign="center center">
                            <span class="material-symbols-outlined menu-icon"> download</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <input type="file" (change)="onFileSelected($event, drawer)" #fileInput accept=".json" style="display: none" />
    <app-loader *ngIf="isSpinnerActive" [loaderText]="loaderMessage"></app-loader>
</mat-drawer>