import { FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Fuzzy } from 'src/app/shared/models/views-models/fuzzy.model';
import { FuzzySet } from 'src/app/shared/models/views-models/fuzzySet.model';
import { MessageService } from 'src/app/shared/service/message/message.service';
import { BaseMatrixService } from 'src/app/shared/service/views-services/base-matrix.service';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { last, lastValueFrom, Observable, take } from 'rxjs';
import { Matrix } from 'src/app/shared/models/views-models/matrix.model';
import { CurrentProjectService } from './../../../service/views-services/current-project.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';

@Component({
    selector: 'app-control-matrix',
    templateUrl: './control-matrix.component.html',
    styleUrls: ['./control-matrix.component.scss'],
})
export class ControlMatrixComponent implements OnInit, OnChanges {
    successMessage = MessagesEnum.SuccessMessage;
    invalidMessage = MessagesEnum.invalidFormMessage;

    fuzzy: Fuzzy;
    @Input() $fuzzy: Observable<Fuzzy>;
    @Input() antecedentsNames: string[];

    @Input() matrixForm: FormGroup;

    @Input() matrix = new Matrix();
    ant1: String;
    ant2: String;
    matrixAvailable: boolean = false;
    isMatrixDisabled = true;

    fuzzySet: FuzzySet[] = [];
    antecedents: string[] = [];

    user: UserPermission;

    constructor(
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<ControlMatrixComponent>,
        private messageService: MessageService,
        public snackBar: MatSnackBar,
        private baseMatrixService: BaseMatrixService,
        private authService: AuthService,

        public currentProjectService: CurrentProjectService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    async ngOnInit() {


        this.user = await this.authService.getUserPermission();
        this.$fuzzy.subscribe(fuzzy => {
            this.matrixAvailable = false;
            this.fuzzy = fuzzy;
            this.loadData();
            this.matrixAvailable = true;
        })

    }

    loadData() {

        this.fuzzySet = [];
        this.antecedents = [];
        this.matrix = new Matrix();

        const antecedent1TagName = this.fuzzy?.antecedent1?.tag?.name || this.antecedentsNames[0];
        const antecedent2TagName = this.fuzzy?.antecedent2?.tag?.name || this.antecedentsNames[1]



        this.antecedents.push(antecedent1TagName);
        this.antecedents.push(antecedent2TagName);
        this.ant1 = antecedent1TagName;
        this.ant2 = antecedent2TagName;
        this.fuzzySet.push(this.fuzzy.antecedent1.fuzzySet);
        this.fuzzySet.push(this.fuzzy.antecedent2.fuzzySet);
        this.structureMatrix(this.fuzzySet, this.matrix);
        if (this.currentProjectService.isBuildingMode()) {
            this.isMatrixDisabled = false;
        }

    }



    ngOnChanges(changes: SimpleChanges) {
        if (changes.antecedentsNames) {
            this.antecedents = this.antecedentsNames;
            this.ant1 = this.antecedents[0];
            this.ant2 = this.antecedents[1];
        }
    }

    close() {
        this.dialogRef.close();
    }

    structureMatrix(antecedents: FuzzySet[], matrix: Matrix) {
        const ant1 =
            antecedents[0] !== undefined && antecedents[0].description
                ? antecedents[0].description
                : '5 Funções de Pertinência';
        const ant2 =
            antecedents[1] !== undefined && antecedents[1].description
                ? antecedents[1].description
                : '5 Funções de Pertinência';
        let form: string[];

        if (ant2 && ant2 === '5 Funções de Pertinência') {
            form = []; // clear the control names array
            form = this.formDefiner(ant1); // get form control names
            const fiveValor = this.baseMatrixService.getFiveValues(); // get array of value to antecedent1
            form.forEach((control) => {
                const tag1 = this.baseMatrixService.findValorByForm(control);
                fiveValor.forEach((tag2) => {
                    matrix[control].push(this.createObject(tag1.desc, tag2));
                });
            });
        }

        if (ant2 && ant2 === '3 Funções de Pertinência') {
            form = []; // clear the control names array
            form = this.formDefiner(ant1); // get form control names
            const threeValor = this.baseMatrixService.getThreeValues(); // get array of value to antecedent1
            const threeTendence = this.baseMatrixService.getThreeTendenceValues(); // get array of value to antecedent1
            const matrixValues = this.ant1.startsWith(VariableTypeEnum.GRD) ? threeTendence : threeValor;
            form.forEach((control) => {
                const tag1 = this.baseMatrixService.findValorByForm(control);
                matrixValues.forEach((tag2) => {
                    matrix[control].push(this.createObject(tag1.desc, tag2));
                });
            });
        }
        this.matrix = matrix;
    }

    formDefiner(control: string) {
        if (control && control === '5 Funções de Pertinência') {
            return this.baseMatrixService.getFiveForm();
        } else if (control && control === '3 Funções de Pertinência') {
            const threeValor = this.baseMatrixService.getThreeValorForm(); // get array of value to antecedent1
            const threeTendence = this.baseMatrixService.getThreeTendenceForm(); // get array of value to antecedent1
            const form = this.ant1.startsWith(VariableTypeEnum.GRD) ? threeTendence : threeValor;
            return form;
        } else {
            return null;
        }
    }

    createObject(tag1: string, tag2: string) {
        return {
            tagAntecedent1: tag1,
            tagAntecedent2: tag2,
            value: '0',
        };
    }

    openDialog(options: any): void {
        const dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        dialogRefMsg.afterClosed().subscribe((result) => {
            this.close();
        });
    }

    openSnackBar(message: string) {
        this.messageService.setMessage(message);
        this.snackBar.open(message, `Fechar`, this.messageService.getConfigMessage());
    }
}
