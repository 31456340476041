<div class="select-component">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="error-flow-select-input">
    <mat-form-field class="custom-select">
      <mat-select 
        [panelClass]="'custom-panel'"
        [value]="selectedValue"
        (selectionChange)="onSelectionChange($event.value)"
        [placeholder]="placeholder"
        [formControl]="control">
        
        <mat-option *ngFor="let option of options" [value]="option.value">
          <div class="option-content">
            <div class="mainText">{{ option.label }}</div>
            <div class="subtitle" *ngIf="showDescriptions && option.description">
              {{ option.description }}
            </div>
          </div>
        </mat-option>
        
        <mat-select-trigger>
          {{ selectedLabel }}
        </mat-select-trigger>
      </mat-select>      
      <mat-hint>
          {{ hint }}
      </mat-hint>
    </mat-form-field>
  </div>
</div>