import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as math from 'mathjs';
import { combineLatest, lastValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { CalculationAVG } from 'src/app/shared/models/views-models/calculationAVG.model';
import { CalculationGRD } from 'src/app/shared/models/views-models/calculationGRD.model';
import { CalculationSTD } from 'src/app/shared/models/views-models/calculationSTD.model';
import { DatetimeInterval } from 'src/app/shared/models/views-models/dateInterval.model';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { CalculationService } from 'src/app/shared/service/views-services/calculation.service';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { TagValueService } from 'src/app/shared/service/views-services/tagValue.service';
import { isNumber } from 'src/app/shared/utils/common.utils';

@Component({
    templateUrl: './data-treatment.component.html',
    styleUrls: ['./data-treatment.component.scss'],
})
export class DataTreatmentComponent implements OnInit {
    mergeOptions: any;
    allSeries = [{ name: 'Processo' }, { name: 'Filtro' }, { name: 'Gradiente' }, { name: 'Desvio Padrão' }];

    chartOption = {
        legend: {
            data: this.allSeries,
        },
        color: ['#AAA', '#36A1EB', '#00D99D', '#855CF8'],
        toolbox: {
            feature: {
                dataZoom: { yAxisIndex: 'none' },
                saveAsImage: {
                    title: 'Salvar',
                },
            },
            right: 40,
        },
        xAxis: [
            {
                type: 'time',
                splitLine: {
                    show: true,
                },
                boundaryGap: false,
            },
        ],
        yAxis: [
            {
                name: 'Filtro',
                type: 'value',
                splitLine: {
                    show: true,
                },
            },
            {
                name: 'Gradiente',
                type: 'value',
                max: 1,
                min: -1,
                splitLine: {
                    show: false,
                },
            },
        ],
        series: [
            {
                name: 'Processo',
                data: [],
                type: 'line',
                showSymbol: false,
                z: 2,
                smooth: true,
                connectNulls: false,
            },
            {
                name: 'Filtro',
                data: [],
                type: 'line',
                showSymbol: false,
                z: 3,
                smooth: true,
                connectNulls: false,
            },
            {
                name: 'Gradiente',
                data: [],
                type: 'line',
                showSymbol: false,
                yAxisIndex: 1,
                z: 1,
                smooth: true,
                connectNulls: false,
            },
            {
                name: 'Desvio Padrão',
                data: [],
                type: 'line',
                showSymbol: false,
                z: 0,
                smooth: true,
                connectNulls: false,
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            top: 75,
            left: 50,
            right: 50,
            bottom: 30,
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
                xAxisIndex: [0],
                filterMode: 'none',
            },
            {
                type: 'inside',
                start: 0,
                end: 100,
                yAxisIndex: [0, 1],
                filterMode: 'none',
            },
        ],
    };

    initOpts = {
        height: 600,
    };

    user: UserPermission;
    colectorTime: any;
    samplesOffset: any;
    samplesOffsetStd: any;
    toggleButton = false;
    loadChart = false;
    isDataPresent = false;
    showMaxLabel = false;
    showMinLabel = false;

    tagValues: TagValue[];
    tagValuesRaw: TagValue[];

    dialogRefMsg: any;
    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };
    invalidParameters = MessagesEnum.invalidParameters;
    sucessMessage = MessagesEnum.SuccessMessage;
    invalidDate = MessagesEnum.invalidDate;

    processes: Process[];
    process: Process;
    avgCalculations: CalculationAVG[];
    grdCalculations: CalculationGRD[];
    stdCalculations: CalculationSTD[];

    dataTreatmentForm: UntypedFormGroup;
    currentProcessTag: Tag;
    lastProcessTagId: string;
    currentAvgCalculation: CalculationAVG;
    currentGrdCalculation: CalculationGRD;
    currentStdCalculation: CalculationSTD;

    unit = '';
    alpha: number;
    delta: number;
    max: number;
    min: number;
    delta_std: number;
    max_std: number;
    min_std: number;
    tagMax: string;
    tagMin: string;
    max_variation: number;
    currentStartDate: any;
    currentStartDateStd: any;
    currentEndDate: any;
    maxGapAllowed = 5;

    retentionDays = 15;
    minDate: any;
    maxDate: any;

    avgValues = [];
    avg_unlimited = [];
    gradientValues = [];
    stdValues = [];
    processDataset = [];
    rawDatasetValues = [];
    filterInterval: string;

    navigatorPlaceholder: string = 'Selecione a TAG';
    tagNames: string[];
    startDatetime: string;
    endDatetime: string;
    processName: string;
    selectedTagId: string;
    selectedTagName: string;
    dateInterval: DatetimeInterval;
    isMinimized: boolean = true;
    isFilterActive: boolean = true;
    isGradientActive: boolean = true;
    isStandardDeviationActive: boolean = true;
    isSimulated: boolean = false;

    constructor(
        private calculationService: CalculationService,
        private processService: ProcessInputService,
        private tagValueService: TagValueService,
        private systemParService: SystemParService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private currentProjectService: CurrentProjectService,
        private authService: AuthService,

        private activeRoute: ActivatedRoute
    ) { }

    async ngOnInit() {


        this.user = await this.authService.getUserPermission();
        this.initForm();
        this.dataTreatmentForm.valueChanges.subscribe(() => this.fieldHasChanged());
        this.systemParService.getCollectorTime().subscribe((colectorTime) => {
            this.colectorTime = colectorTime.value;
            this.selectedTagId = this.activeRoute.snapshot?.fragment;
            this.loadData();
        });
        if (!this.user?.permissions.canUpdate) {
            this.disableParameters();
        }
    }

    loadData() {
        const avgCalculationObservable = this.calculationService.getAllAVGCalculations();
        const grdCalculationObservable = this.calculationService.getAllGRDCalculations();
        const stdCalculationObservable = this.calculationService.getAllSTDCalculations();
        const processesObservable = this.processService.getAllProcessesByType(VariableTypeEnum.ANALOGIC);

        combineLatest([
            avgCalculationObservable,
            grdCalculationObservable,
            stdCalculationObservable,
            processesObservable,
        ]).subscribe(([avgCalculations, grdCalculations, stdCalculationObservable, processes]) => {
            this.avgCalculations = avgCalculations;
            this.grdCalculations = grdCalculations;
            this.stdCalculations = stdCalculationObservable;
            this.processes = processes.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
            this.tagNames = processes.map((process) => process.tag.name);
            this.selectedTagName = processes.find((process) => process.id == this.selectedTagId)?.tag?.name;
        });
    }

    initForm() {
        this.dataTreatmentForm = this.formBuilder.group({
            alpha: [null, [Validators.required, Validators.min(0), Validators.max(1)]],
            min: [null, [Validators.required]],
            max: [null, [Validators.required]],
            delta: [null, [Validators.required, Validators.min(0)]],
            delta_std: [null, [Validators.required, Validators.min(0)]],
            min_std: [null, [Validators.required]],
            max_std: [null, [Validators.required]],
            max_variation: [null, [Validators.required, Validators.min(0)]],
            isFilterActive: [true, [Validators.required]],
            isGradientActive: [true, [Validators.required]],
            isStandardDeviationActive: [true, [Validators.required]],
        });
    }

    disableParameters() {
        this.dataTreatmentForm.controls['alpha'].disable();
        this.dataTreatmentForm.controls['min'].disable();
        this.dataTreatmentForm.controls['max'].disable();
        this.dataTreatmentForm.controls['delta'].disable();
        this.dataTreatmentForm.controls['delta_std'].disable();
        this.dataTreatmentForm.controls['min_std'].disable();
        this.dataTreatmentForm.controls['max_std'].disable();
        this.dataTreatmentForm.controls['max_variation'].disable();
    }

    setTagParameters(tag) {
        let process = this.processes.find((process) => process.tag.name == tag);
        if (process) {
            this.process = process;
            if (this.currentProcessTag != process.tag) {
                this.loadChart = false;
            }
            this.unit = process.unit.description;
            this.currentProcessTag = process.tag;
            //TODO: validate if we should remove lines commented below
            // this.tagMin = (process.tag.min).toString()
            // this.tagMax = (process.tag.max).toString()
            this.currentAvgCalculation = this.avgCalculations.find((avg) => avg.process_tag.name == tag);
            this.currentGrdCalculation = this.grdCalculations.find((grd) => grd.process_tag.name == tag);
            this.currentStdCalculation = this.stdCalculations.find((std) => std.process_tag.name == tag);
            this.dataTreatmentForm.controls['max'].setValidators([
                Validators.required,
                //TODO: validate if we should remove lines commented below
                // Validators.max(process.tag.max),
            ]);
            this.dataTreatmentForm.controls['min'].setValidators([
                Validators.required,
                //TODO: validate if we should remove line commented below
                // Validators.min(process.tag.min),
            ]);
            this.dataTreatmentForm.controls['max_std'].setValidators([Validators.required]);
            this.dataTreatmentForm.controls['min_std'].setValidators([Validators.required]);
            this.dataTreatmentForm.patchValue({
                alpha: this.currentAvgCalculation.alpha,
                min: this.currentAvgCalculation.tag.min,
                max: this.currentAvgCalculation.tag.max,
                delta: this.currentGrdCalculation.delta,
                delta_std: this.currentStdCalculation.delta,
                min_std: this.currentStdCalculation.tag.min,
                max_std: this.currentStdCalculation.tag.max,
                max_variation: this.currentGrdCalculation.max_variation,
            });
        }
    }

    applySetup() {
        if (this.validateForm()) {
            this.getParametersFromForm();
            let tagId = this.currentProcessTag.id;
            const maxDeltaValue = Math.max(this.delta, this.delta_std);
            this.currentStartDate = new Date(
                new Date(this.startDatetime).valueOf() - maxDeltaValue * 1000
            ).toISOString();

            this.currentEndDate = this.endDatetime;
            this.tagValueService.getTagValues(tagId, this.currentStartDate, this.currentEndDate).subscribe((result) => {
                this.lastProcessTagId = tagId;
                this.loadChart = true;
                this.isDataPresent = result.length > 0;
                this.processDataset = [];
                if (this.isDataPresent) {
                    this.tagValuesRaw = this.checkDatasetGaps(result);
                    this.getLabel(this.tagValuesRaw);
                    this.rawDatasetValues = this.tagValuesRaw.map((processValue) => [
                        processValue.timestamp,
                        processValue.value,
                    ]);

                    this.processDataset = this.rawDatasetValues
                        .filter((f) => this.filterChartValues(f))
                        .map((value) =>
                            isNumber(value[1]) ? [value[0], +Number(value[1]).toFixed(4)] : [value[0], null]
                        );

                    this.calculateAVGCurve(this.rawDatasetValues, this.alpha, this.max, this.min);
                    this.calculateGRDCurve(this.avg_unlimited, this.max_variation, this.delta);
                    this.calculateSTDCurve([...this.rawDatasetValues]);
                    this.updateChartData();
                }
            });
        } else {
            this.openDialog(this.defaultDialog);
            this.fieldHasChanged();
        }
    }

    calculateSTDCurve(values) {
        let stdValuesAux = [];
        let deltaStd = this.delta_std;
        let start = new Date(this.startDatetime).getTime();
        const startDateTime = new Date(this.startDatetime).valueOf();
        values
            .filter((f) => f[0] >= startDateTime)
            .forEach(function (value, index) {
                let valuesStd = values.filter(
                    (e) => value[0] / 1000 - e[0] / 1000 <= deltaStd && value[0] / 1000 - e[0] / 1000 >= 0
                );
                let std;
                const time = valuesStd.map((value) => value[0]);
                const numbers = [];
                let calculate = valuesStd.every((s) => isNumber(s[1]));
                valuesStd.forEach((value) => {
                    if (isNumber(value[1])) {
                        return numbers.push(value[1]);
                    }
                });

                if (valuesStd.length > 0 && numbers.length > 0 && calculate) {
                    std = math.std(numbers);
                    stdValuesAux.push([time[time.length - 1], std]);
                } else stdValuesAux.push([time[time.length - 1], null]);
            });
        this.stdValues = stdValuesAux
            .filter((e) => e[0] / 1000 >= start / 1000 - deltaStd)
            .map((std) => (isNumber(std[1]) ? [std[0], +Number(std[1]).toFixed(4)] : [std[0], null]));
    }

    calculateAVGCurve(processValues, alpha, max, min) {
        let avgValues = [];
        let unlimited = [];
        processValues.forEach(function (processValue, index) {
            let avg;
            let avgUnlimited;
            if (index == 0 || (index > 0 && !processValues[index - 1][1])) {
                avg = processValue[1] * (1 - alpha) + processValue[1] * alpha;
                avgUnlimited = avg;
            } else {
                avg = avgValues[index - 1][1] * (1 - alpha) + processValue[1] * alpha;
                avgUnlimited = unlimited[index - 1][1] * (1 - alpha) + processValue[1] * alpha;
            }
            unlimited.push(isNumber(processValue[1]) ? [processValue[0], avgUnlimited] : [processValue[0], null]);
            if (avg > max) {
                avg = max;
            } else if (avg < min) {
                avg = min;
            }
            avgValues.push(isNumber(processValue[1]) ? [processValue[0], avg] : [processValue[0], null]);
        });
        this.avg_unlimited = unlimited;
        this.avgValues = avgValues
            .filter((f) => this.filterChartValues(f))
            .map((avg) => (isNumber(avg[1]) ? [avg[0], +Number(avg[1]).toFixed(4)] : [avg[0], null]));
    }

    calculateGRDCurve(values, max_variation, delta) {
        const startDateTime = new Date(this.startDatetime).valueOf();
        const calculatedGrdValues = values
            .filter((f) => f[0] >= startDateTime)
            .map(([timestamp, value]) => {
                const startGRDTimestamp = timestamp - delta * 1000;
                let [deltaTimestamp, deltaValue] =
                    values.find(([dTimestamp, _]) => dTimestamp == startGRDTimestamp) || [];
                let grd = null;

                if (!deltaTimestamp || deltaValue == undefined) {
                    const colectorLimitValue = startGRDTimestamp - this.colectorTime * 1000;
                    const nearsValues = values.filter(
                        ([dTimestamp, _]) => dTimestamp <= startGRDTimestamp && dTimestamp >= colectorLimitValue
                    );
                    const [nearestTimestamp, nearestValue] = nearsValues[nearsValues.length - 1] || [];
                    if (nearestTimestamp >= colectorLimitValue) {
                        deltaTimestamp = nearestTimestamp;
                        deltaValue = nearestValue;
                    }
                }

                if (deltaTimestamp && deltaValue != undefined && value != null) {
                    grd = Math.min(Math.max((value - deltaValue) / max_variation, -1), 1).toFixed(4);
                }
                return [timestamp, grd];
            });

        this.gradientValues = calculatedGrdValues;
    }

    checkDatasetGaps(processValues: TagValue[]) {
        let filledDataset = [];
        let startDate = new Date(this.currentStartDate).getTime();
        let firstDate = new Date(processValues[0].timestamp.trim().replace(' ', 'T') + 'Z').getTime();
        let endDate = new Date(this.currentEndDate).getTime();
        let lastDate = new Date(
            processValues[processValues.length - 1].timestamp.trim().replace(' ', 'T') + 'Z'
        ).getTime();
        if ((firstDate - startDate) / 1000 > this.colectorTime * this.maxGapAllowed) {
            let tagValue = new TagValue();
            tagValue.value = null;
            tagValue.timestamp = this.currentStartDate.replace('T', ' ').replace('Z', '');
            filledDataset.push(tagValue);
        }
        filledDataset = this.fillDatasetGap(startDate, firstDate, filledDataset);
        for (let i = 0; i < processValues.length; i++) {
            let processValue = processValues[i];
            let nextProcessValue = processValues[i + 1];
            filledDataset.push(processValue);
            if (i != processValues.length - 1) {
                let timestamp = new Date(processValue.timestamp.trim().replace(' ', 'T') + 'Z').getTime();
                let nextTimestamp = new Date(nextProcessValue.timestamp.trim().replace(' ', 'T') + 'Z').getTime();
                filledDataset = this.fillDatasetGap(timestamp, nextTimestamp, filledDataset);
            }
        }
        filledDataset = this.fillDatasetGap(lastDate, endDate, filledDataset);
        return filledDataset;
    }

    fillDatasetGap(startDate, endDate, dataset) {
        let timeGap = (endDate - startDate) / 1000;
        if (timeGap > this.colectorTime * this.maxGapAllowed) {
            let samplesToFill = timeGap / this.colectorTime;
            for (let i = 1; i < samplesToFill; i++) {
                let sampleTimestamp = new Date(startDate + this.colectorTime * i * 1000);
                let tagValue = new TagValue();
                tagValue.value = null;
                tagValue.timestamp = sampleTimestamp.toISOString().replace('T', ' ').replace('Z', '');
                dataset.push(tagValue);
            }
        }
        return dataset;
    }

    getLabel(processValues) {
        processValues.forEach((processValue) => {
            let dataDate = new Date(processValue.timestamp.trim().replace(' ', 'T') + 'Z').getTime();
            processValue.timestamp = dataDate;
        });
    }

    updateChartData() {
        this.mergeOptions = {
            series: [
                {
                    data: this.processDataset,
                },
                {
                    data: this.avgValues,
                },
                {
                    data: this.gradientValues,
                },
                {
                    data: this.stdValues,
                },
            ],
        };
    }

    showPreviousData() {
        if (!this.toggleButton && this.currentAvgCalculation && this.currentGrdCalculation) {
            this.maxDate = '';
            this.minDate = '';
            this.dataTreatmentForm.disable();
            this.dataTreatmentForm.patchValue({
                alpha: this.currentAvgCalculation.alpha,
                max: this.currentAvgCalculation.tag.max,
                min: this.currentAvgCalculation.tag.min,
                delta: this.currentGrdCalculation.delta,
                max_variation: this.currentGrdCalculation.max_variation,
            });
            this.samplesOffset = Math.ceil(this.currentGrdCalculation.delta / this.colectorTime);
            this.validateOffset();
            this.getLabel(this.tagValuesRaw);
            this.calculateAVGCurve(
                this.rawDatasetValues,
                this.currentAvgCalculation.alpha,
                this.currentAvgCalculation.tag.max,
                this.currentAvgCalculation.tag.min
            );
            this.calculateGRDCurve(
                this.avg_unlimited,
                this.currentGrdCalculation.max_variation,
                this.currentGrdCalculation.delta
            );
        } else {
            this.dataTreatmentForm.patchValue({
                alpha: this.alpha,
                max: this.max,
                min: this.min,
                delta: this.delta,
                max_variation: this.max_variation,
            });
            this.getParametersFromForm();
            this.getLabel(this.tagValuesRaw);
            this.calculateAVGCurve(this.rawDatasetValues, this.alpha, this.max, this.min);
            this.calculateGRDCurve(this.avg_unlimited, this.max_variation, this.delta);
            this.dataTreatmentForm.enable();
        }
        this.processDataset = this.rawDatasetValues.slice(this.samplesOffset);
        this.updateChartData();
        this.toggleButton = !this.toggleButton;
    }

    getParametersFromForm() {
        let formData = this.dataTreatmentForm.getRawValue();
        this.alpha = formData.alpha;
        this.delta = formData.delta;
        this.max = formData.max;
        this.min = formData.min;
        this.delta_std = formData.delta_std;
        this.max_std = formData.max_std;
        this.min_std = formData.min_std;
        this.max_variation = formData.max_variation;
        this.samplesOffset = Math.ceil(this.delta / this.colectorTime);
        this.samplesOffsetStd = Math.ceil(this.delta_std / this.colectorTime);
        this.validateOffset();
    }

    validateForm() {
        let formData = this.dataTreatmentForm.getRawValue();
        this.dataTreatmentForm.controls['max'].setErrors(null);
        this.dataTreatmentForm.controls['min'].setErrors(null);
        this.dataTreatmentForm.controls['max_std'].setErrors(null);
        this.dataTreatmentForm.controls['min_std'].setErrors(null);

        const isFormValid =
            this.dataTreatmentForm.valid || Object.values(this.dataTreatmentForm.controls).every((c) => c.disabled);

        if (
            isFormValid &&
            formData.max > formData.min &&
            formData.max_std > formData.min_std
            //TODO: validate if we should remove lines commented below
            // formData.max <= this.currentProcessTag.max &&
            // formData.min >= this.currentProcessTag.min
        ) {
            return true;
        } else {
            if (formData.max <= formData.min) {
                this.dataTreatmentForm.controls['max'].setErrors({ incorrect: true });
                this.dataTreatmentForm.controls['min'].setErrors({ incorrect: true });
                this.defaultDialog.data.message = MessagesEnum.invalidLimits;
            }
            if (formData.max_std <= formData.min_std) {
                this.dataTreatmentForm.controls['max_std'].setErrors({ incorrect: true });
                this.dataTreatmentForm.controls['min_std'].setErrors({ incorrect: true });
                this.defaultDialog.data.message = MessagesEnum.invalidLimits;
            } else {
                this.defaultDialog.data.message = MessagesEnum.invalidFormMessage;
            }
            return false;
        }
    }

    resetParameters() {
        if (this.currentProcessTag) {
            this.dataTreatmentForm.patchValue({
                alpha: this.currentAvgCalculation.alpha,
                min: this.currentAvgCalculation.tag.min,
                max: this.currentAvgCalculation.tag.max,
                delta: this.currentGrdCalculation.delta,
                min_std: this.currentStdCalculation.tag.min,
                max_std: this.currentStdCalculation.tag.max,
                delta_std: this.currentStdCalculation.delta,
                max_variation: this.currentGrdCalculation.max_variation,
            });
            this.alpha = this.currentAvgCalculation.alpha;
            this.max = this.currentAvgCalculation.tag.max;
            this.min = this.currentAvgCalculation.tag.min;
            this.delta = this.currentGrdCalculation.delta;
            this.max_std = this.currentStdCalculation.tag.max;
            this.min_std = this.currentStdCalculation.tag.min;
            this.delta_std = this.currentStdCalculation.delta;
            this.max_variation = this.currentGrdCalculation.max_variation;
            this.samplesOffset = Math.ceil(this.currentGrdCalculation.delta / this.colectorTime);
            this.validateOffset();
            this.calculateAVGCurve(
                this.rawDatasetValues,
                this.currentAvgCalculation.alpha,
                this.currentAvgCalculation.tag.max,
                this.currentAvgCalculation.tag.min
            );
            this.calculateGRDCurve(
                this.avg_unlimited,
                this.currentGrdCalculation.max_variation,
                this.currentGrdCalculation.delta
            );
            this.calculateSTDCurve(this.rawDatasetValues);
            this.processDataset = this.rawDatasetValues.slice(this.samplesOffset);
            this.updateChartData();
            this.isSimulated = false;
        }
    }

    async confirmFuzzyParameters() {
        const fuzzyDependencies = await lastValueFrom(
            this.calculationService.getFuzzyDependencies([
                this.currentAvgCalculation.tag.id,
                this.currentStdCalculation.id,
            ])
        );

        if (fuzzyDependencies.length == 0) {
            return true;
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            autoFocus: false,
            data: {
                message: `Se houver alteração dos limites fuzzy, ela será aplicada a todas regras que os utilizam.`,
                message_second: `Verifique as regras que poderão ser afetadas antes de prosseguir. Deseja continuar?`,
                linkMessages: fuzzyDependencies.map((f) => ({
                    message: f.tag.name,
                    route: `salvar-regras#${f.id}`,
                    icon: 'link',
                })),
                cancelAvailable: true,
                confirmButton: 'AVANÇAR'
            },
        });

        const afterClosed = await lastValueFrom(dialogRef.afterClosed());
        return afterClosed;
    }

    async saveParameters() {
        if (!(await this.confirmFuzzyParameters())) {
            return;
        }
        if (this.setNewParameters()) {
            this.defaultDialog.data.message = this.sucessMessage;
        } else {
            this.defaultDialog.data.message = this.invalidParameters;
        }
        this.isToExtend(false);
        this.openDialog(this.defaultDialog);
    }

    setNewParameters() {
        if (
            this.currentAvgCalculation &&
            this.currentGrdCalculation &&
            this.currentStdCalculation &&
            this.validateLimits()
        ) {
            this.getParametersFromForm();

            if (
                this.currentAvgCalculation.alpha !== this.alpha ||
                this.max !== this.currentAvgCalculation.tag.max ||
                this.currentAvgCalculation.tag.min !== this.min
            ) {
                this.currentAvgCalculation.tag.max = this.max;
                this.currentAvgCalculation.tag.min = this.min;
                this.currentAvgCalculation.alpha = this.alpha;
                this.saveAvgTag(this.currentAvgCalculation);
            }

            if (
                this.delta !== this.currentGrdCalculation.delta ||
                this.max_variation !== this.currentGrdCalculation.max_variation
            ) {
                this.currentGrdCalculation.delta = this.delta;
                this.currentGrdCalculation.max_variation = this.max_variation;
                this.saveGrdTag(this.currentGrdCalculation);
            }

            if (
                this.max_std !== this.currentStdCalculation.tag.max ||
                this.currentStdCalculation.tag.min !== this.min_std ||
                this.delta_std !== this.currentStdCalculation.delta
            ) {
                this.currentStdCalculation.tag.max = this.max_std;
                this.currentStdCalculation.tag.min = this.min_std;
                this.currentStdCalculation.delta = this.delta_std;
                this.saveStdTag(this.currentStdCalculation);
            }

            return true;
        } else {
            return false;
        }
    }

    saveStdTag(tagSTD) {
        this.calculationService.updateSTDCalculation(tagSTD).subscribe((std) => {
            this.currentStdCalculation = std;
        });
    }

    saveGrdTag(tagGRD) {
        this.calculationService.updateGRDCalculation(tagGRD).subscribe((grd) => {
            this.currentGrdCalculation = grd;
        });
    }

    saveAvgTag(tagAVG) {
        this.calculationService.updateAVGCalculation(tagAVG).subscribe((avg) => {
            this.currentAvgCalculation = avg;
        });
    }

    validateLimits() {
        let form = this.dataTreatmentForm.getRawValue();
        if (
            form.max > form.min &&
            form.delta >= this.colectorTime &&
            form.delta_std >= this.colectorTime &&
            this.dataTreatmentForm.controls['min'].valid &&
            this.dataTreatmentForm.controls['max'].valid &&
            this.dataTreatmentForm.controls['min_std'].valid &&
            this.dataTreatmentForm.controls['max_std'].valid
        ) {
            return true;
        } else {
            return false;
        }
    }

    validateOffset() {
        this.samplesOffset = this.samplesOffset > 0 ? this.samplesOffset : 1;
        this.samplesOffsetStd = this.samplesOffsetStd > 0 ? this.samplesOffsetStd : 1;
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
    }

    getChartData(dateInterval: DatetimeInterval) {
        this.dateInterval = dateInterval;

        if (dateInterval.validDateInterval) {
            this.startDatetime = dateInterval.startDatetime;
            this.endDatetime = dateInterval.endDatetime;
            this.processName = dateInterval.tagName;
            this.setTagParameters(this.processName);
            this.applySetup();
        } else {
            this.defaultDialog.data.message = !dateInterval.validDateInterval
                ? this.invalidDate
                : MessagesEnum.invalidSP;
            this.openDialog(this.defaultDialog);
        }
    }

    isToExtend(state: boolean) {
        this.isMinimized = !state;
        this.isSimulated = false;
    }

    simulateChart() {
        this.isSimulated = true;
        this.applySetup();
    }

    fieldHasChanged() {
        this.isSimulated = false;
    }

    isShow() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType === 'BUILDING';
    }

    filterChartValues(f) {
        const startDateTime = new Date(this.startDatetime).valueOf();
        return f[0] >= startDateTime;
    }
}
