import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Project } from '../../models/views-models/project.model';
const DEFAULT_PROJECT = 'default_project';
const QUATERBACK = 'quaterback';

@Injectable()
export class LocalStorageService {
    private localStorageChanges = new Subject<Project>();
    newLocalStorageData = this.localStorageChanges.asObservable();

    triggerLocalStorageChanges(project: Project) {
        this.localStorageChanges.next(project);
    }

    clean(project: Project) {

        localStorage.setItem(DEFAULT_PROJECT, JSON.stringify(project))
        this.triggerLocalStorageChanges(project);
    }

    reset(project: Project) {
        localStorage.setItem(DEFAULT_PROJECT, JSON.stringify(project))
    }

    setQuaterbackReload(reload: any) {
        localStorage.setItem(QUATERBACK, JSON.stringify(reload));
    }

    getQuaterbackReload() {
        return JSON.parse(localStorage.getItem(QUATERBACK));
    }

    hasDefaultProject() {
        return Boolean(localStorage.getItem(DEFAULT_PROJECT));
    }

    getDefaultProject(): Project {
        return JSON.parse(localStorage.getItem(DEFAULT_PROJECT));

    }
}
