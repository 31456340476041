import { Component, OnInit } from '@angular/core';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';

@Component({
    selector: 'app-setpoints',
    templateUrl: './setpoints.component.html',
    styleUrls: ['./setpoints.component.scss'],
})
export class SetpointsComponent implements OnInit {
    user: UserPermission;

    constructor(private authService: AuthService) { }

    async ngOnInit() {

        this.user = await this.authService.getUserPermission();



    }
}
