<div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="15px" class="subCard">
    <div fxFlex="7%" class="badge" fxLayoutAlign="center center">
        <i class="material-icons itemAlign">functions</i>
    </div>

    <div fxFlex class="title">
        {{ title }}
    </div>
</div>

<form [formGroup]="formulaForm" (ngSubmit)="onSubmit()">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="getConditions()" [selectedIndex]="activeTab">
        <mat-tab label="CONDIÇÕES">
            <div formArrayName="conditions" *ngFor="let item of getControls(); let i = index">
                <div [formGroupName]="i" class="subCard">
                    <div fxLayoutAlign="space-between center" fxLayout="row">
                        <div fxFlex="20%">
                            <span>{{ item.value.conditionName }}</span>
                        </div>
                        <div fxFlex="10%" fxLayoutAlign="end">
                            <button mat-button type="button" (click)="removeCondition(i)"
                                *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()">
                                <i class="material-icons remove">delete</i>
                            </button>
                        </div>
                    </div>

                    <div fxLayoutAlign="start" fxLayout="row" fxLayoutGap="20px">
                        <div fxFlex="30%">
                            <p>&nbsp;</p>
                            <mat-form-field appearance="outline">
                                <mat-label>Atributo</mat-label>
                                <input type="search" matInput placeholder="Atributo" list="tagInputList"
                                    (focus)="resetTagName(i)" (focusout)="setTagName(i)" formControlName="tag_name"
                                    autocomplete="disabled" data-cy="increment-condition-attribute" />
                            </mat-form-field>
                            <datalist id="tagInputList">
                                <option *ngFor="let tag of tagNames" [value]="tag"></option>
                            </datalist>
                        </div>

                        <div fxFlex="15%">
                            <p>Operador</p>
                            <div class="mat-group-align">
                                <mat-button-toggle-group formControlName="equation_operator" name="operatorSelector"
                                    aria-label="Font Style" [ngClass]="!conditionsStatus[i].valid ? 'empty' : ''"
                                    (change)="resetOperator(i)" data-cy="increment-condition-operator">
                                    <mat-button-toggle value=">=" data-cy="greater-than-equal">>=</mat-button-toggle>
                                    <mat-button-toggle value="==" data-cy="equal">=</mat-button-toggle>
                                    <mat-button-toggle value="<=" data-cy="less-than-equal">
                                        <= </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>

                        <div fxFlex="25%">
                            <p>&nbsp;</p>
                            <mat-form-field appearance="outline">
                                <mat-label>Valor</mat-label>
                                <input type="{{ getInputType(i) }}" matInput placeholder="Valor" [attr.list]="datalist"
                                    class="hideArrow" (focus)="resetConditionValue(i)" (focusout)="setConditionValue(i)"
                                    formControlName="equation_value" autocomplete="off"
                                    data-cy="increment-condition-value" />
                            </mat-form-field>
                            <datalist id="conditionValueList">
                                <option *ngFor="let tag of valueOptions" [value]="tag"></option>
                            </datalist>

                            <div class="radioDiv" fxLayoutAlign="start" fxLayout="row">
                                <mat-radio-group color="primary" formControlName="constRadio"
                                    (change)="clearConditionValue(i)">
                                    <div fxFlex>
                                        <mat-radio-button value="true">Constante</mat-radio-button>
                                    </div>
                                    <div fxFlex="35%">
                                        <mat-radio-button value="false">Variável</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <mat-divider></mat-divider>
                </div>
            </div>

            <div *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()">
                <button mat-button class="cancelButton subCard" type="button" (click)="addCondition()">
                    <i class="material-icons" data-cy="add-condition"> add_circle </i> Adicionar Condição
                </button>
            </div>
        </mat-tab>

        <mat-tab [disabled]="conditions.length < 1">
            <ng-template mat-tab-label>
                <span data-cy="equation-tab">EQUAÇÃO</span>
                <!-- <span
                    *ngIf="conditions.length <= 1"
                    matTooltip="É necessário cadastrar pelo menos 2 condições para criar uma equação"
                    >EQUAÇÃO</span
                > -->
            </ng-template>
            <div fxLayoutAlign="end center" fxLayout="row">
                <button mat-button type="button" (click)="clearText()"
                    *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()">
                    <i class="material-icons remove">delete</i>
                </button>
            </div>
            <div class="subCard" fxFlex="100%" fxLayout="row">
                <mat-form-field appearance="outline">
                    <mat-label>Equação</mat-label>
                    <textarea class="md-textarea" matInput placeholder="Equação" formControlName="equation"
                        (click)="getCursor($event.target)" (keyup)="getCursor($event.target)"
                        data-cy="equation-field"></textarea>
                </mat-form-field>
            </div>

            <div class="subCard" fxLayout="row" fxLayoutGap="10px" *ngIf="user?.permissions?.canUpdate">
                <div fxFlex="60%" fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline">
                        <mat-label>Condição</mat-label>
                        <mat-select formControlName="conditionSelect">
                            <mat-option *ngFor="let condition of conditionList" [value]="condition.conditionName">
                                {{ condition.conditionName }}: {{ condition.tag_name }}
                                {{ condition.equation_operator == "==" ? "=" : condition.equation_operator }}
                                {{ condition.equation_value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button type="button" mat-stroked-button (click)="writeCondition()"
                        [disabled]="currentProjectService.isRunningMode()">
                        <i class="material-icons plus-icon">add</i>
                    </button>
                </div>
                <div fxFlex="20%" fxLayoutAlign="center" class="btn-group">
                    <button type="button" mat-stroked-button (click)="writeEquation('AND ')"
                        [disabled]="currentProjectService.isRunningMode()">AND</button>
                    <button type="button" mat-stroked-button (click)="writeEquation('OR ')"
                        [disabled]="currentProjectService.isRunningMode()">OR</button>
                </div>
                <div fxFlex="20%" fxLayoutAlign="center" class="btn-group">
                    <button type="button" mat-stroked-button (click)="writeEquation('(')"
                        [disabled]="currentProjectService.isRunningMode()">(</button>
                    <button type="button" mat-stroked-button (click)="writeEquation(')')"
                        [disabled]="currentProjectService.isRunningMode()">)</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <mat-divider *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()"></mat-divider>

    <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <div fxFlex>
            <button mat-button class="whiteButton" type="button" (click)="deleteFormula()"
                *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()">
                <i class="material-icons">delete</i>&nbsp;EXCLUIR CONDIÇÕES
            </button>
        </div>
        <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
        <button mat-button class="blueButton" type="submit"
            *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()"
            data-cy=save-increment-codition>SALVAR</button>
    </div>
</form>