<mat-card appearance="outlined">
    <div class="header-output">
        <div>
            <mat-card-header>
                <mat-card-title>Saídas de Variáveis</mat-card-title>
                <span class="data-info">Dados atualizados em {{ lastUpdate }}</span>
            </mat-card-header>
        </div>
        <div>
            <button class="customButton add-button" mat-raised-button type="button" (click)="add()"
                *ngIf="user?.permissions?.canCreate && currentProjectService.isBuildingMode()" data-cy="add">
                ADICIONAR
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <div *ngIf="selectedList.length == 0" fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
            <div fxFill>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar Saídas</mat-label>
                        <input matInput (keyup)="applyFilter($event.target.value)" data-cy="search-input" />
                        <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="table-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-slide-toggle color="primary" (change)="changeOutputStatus(element)"
                            [disabled]="!user?.permissions?.canUpdate || currentProjectService.isRunningMode()"
                            [checked]="element.status"></mat-slide-toggle>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{ element.name }}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{ element.description }}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef> Tipo </mat-header-cell>
                    <mat-cell *matCellDef="let element"><app-ellipsis-text>{{ element.tagVariable.variableType.description }}</app-ellipsis-text></mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions" class="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-button (click)="trackSetpoint(element)"
                            *ngIf="currentProjectService.isRunningMode();" matTooltip="Acompanhar"
                            matTooltipPosition="above">
                            <img mat-card-image class="material-symbols-outlined tracking-setpoint"
                                src="assets/images/icons/tracking-setpoint-icon/tracking-setpoint-blue.svg" />
                        </button>
                        <button mat-button (click)="editModal(element)"
                            *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode(); else visibilityButton"
                            matTooltip="Editar" matTooltipPosition="above">
                            <i class="material-icons">edit</i>
                        </button>
                        <button mat-button (click)="deleteElement(element)"
                            *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()"
                            matTooltip="Excluir" matTooltipPosition="above">
                            <i class="material-icons">delete</i>
                        </button>
                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)" matTooltip="Visualizar"
                                matTooltipPosition="above">
                                <i class="material-icons-outlined">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>