import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from './../../shared/service/message/message.service';
import { LoginService } from './login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    color = '#f3f3f3';
    loginForm: UntypedFormGroup;

    loginSubscription: Subscription = new Subscription();

    message = '';

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
        public snackBar: MatSnackBar,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.loginForm = new UntypedFormGroup({
            username: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        });
    }

    ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    signin() {

        this.loginService.login();


    }
}
