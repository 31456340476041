import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { MessageService } from 'src/app/shared/service/message/message.service';
import { FuzzyService } from 'src/app/shared/service/views-services/fuzzy.service';
import { Views } from 'src/app/shared/utils/views-urls';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-table-control-rule',
    templateUrl: './table-control-rule.component.html',
    styleUrls: ['./table-control-rule.component.scss'],
})
export class TableControlRuleComponent implements OnInit {
    @Input() user;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns = ['name', 'description', 'actions'];
    fuzzyData = [];
    fuzzy = [];

    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    message = '';
    dialogRef: any;
    dialogRefMsg: any;
    deleteMessage = MessagesEnum.DeleteMessage;
    returnUrl: string;

    constructor(
        private messageService: MessageService,
        private fuzzyService: FuzzyService,
        private router: Router,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public currentProjectService: CurrentProjectService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/salvar-regras';
        this.loadData();
    }

    loadData() {
        this.fuzzyService.getAllFuzzys().subscribe((fuzzys) => {
            this.fuzzy = fuzzys.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
            this.fuzzyData = [];
            this.fuzzy.forEach((fuzzy) => {
                this.fuzzyData.push({
                    name: fuzzy.tag.name,
                    description: fuzzy.tag.description,
                    fuzzy: fuzzy.tag.name,
                });
            });
            this.dataSource = new MatTableDataSource(this.fuzzyData);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };


        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    add() {
        this.router.navigate([this.returnUrl], { state: { rule: null } });
    }

    deleteElement(element: any) {
        let fuzzy = this.getFuzzyByName(element);
        const conf = {
            component: ConfirmDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        const confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };

        this.openConfirmDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.fuzzyService.deleteFuzzy(fuzzy.id).subscribe((dependencies) => {
                    if (dependencies?.length > 0) {
                        conf.width = 'auto';
                        conf.height = 'auto';
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            dependencieNames += tag.name + ', ';
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message =
                            'Conflito ao deletar. Esta regra é usada como entrada em: ' + dependencieNames;
                    } else {
                        conf.data.message = this.deleteMessage;
                    }
                    this.openConfirmDialog(conf);
                });
            }
        });
    }

    getFuzzyByName(fuzzyData) {
        let fuzzyName = fuzzyData.fuzzy;
        let fuzzy = this.fuzzy.find((fuzzy) => fuzzy.tag.name == fuzzyName);
        return fuzzy;
    }

    selectElement(element: any) {
        let fuzzy = this.getFuzzyByName(element);
        this.router.navigate([this.returnUrl], { fragment: fuzzy.id });
    }

    trackRule(element: any) {
        let fuzzy = this.getFuzzyByName(element);
        this.router.navigate([Views.rulesTracking.url], { fragment: fuzzy.id });
    }

    openConfirmDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (options.data.message == this.deleteMessage) {
                this.loadData();
            }
        });
    }

    openSnackBar(message: string) {
        this.messageService.setMessage(message);
        this.snackBar.open(message, `Fechar`, this.messageService.getConfigMessage());
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType !== 'BUILDING';
    }
}
