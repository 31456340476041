import { User } from "../models/views-models/user.model";

export function enrichArray() {
    //Array.prototype['first'] = Array.prototype.find(firstFromArray);

    Object.defineProperty(Array.prototype, 'first', {
        value: function () {
            return [].concat(this).find((t) => firstFromArray(t));
        },
    });
}

export function firstFromArray(t: any) {
    return t;
}

export function isNumber(param) {
    return param != undefined && param != null && !isNaN(parseFloat(param));
}

export function verifyCanShowTextTooltip(text: string, size: number) {
    return text && text.length >= size ? text : '';
}

export function getUserClaim(user: User, claim: string) {
    return user?.claims?.find(c => c.type == claim)?.value
}