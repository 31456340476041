import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { User } from '../../models/views-models/user.model';

const API = environment.serverUrl;

@Injectable()
export class UserService {
    constructor(private httpClient: HttpClient) { }

    getAllUsers(): Observable<User[]> {
        return this.httpClient.get<Response>(`${API}users/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const users: User[] = response.data.sort((a, b) => a.name.localeCompare(b.name));
                return users;
            })
        );
    }

    newUser(user: User): Observable<User> {
        return this.httpClient.post<Response>(`${API}auth/signup`, user, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newUser: User = response.data;
                return newUser;
            })
        );
    }

    getUserByUsername(username: String): Observable<User> {
        return this.httpClient.get<Response>(`${API}users/username/${username}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const user: User = response.data;
                return user;
            })
        );
    }


    getUserDetails(username: String): Observable<User> {
        return this.httpClient.get<Response>(`${API}users/details/${username}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const user: User = response.data;
                return user;
            })
        );
    }

    deleteUser(username: String): Observable<User> {
        return this.httpClient.delete<Response>(`${API}users/delete/${username}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const user: User = response.data;
                return user;
            })
        );
    }

    updateUser(user: User): Observable<User> {
        return this.httpClient.patch<Response>(`${API}users/update`, user, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const user: User = response.data;
                return user;
            })
        );
    }

    getCurrentUser(): Observable<User> {
        return this.httpClient.get<Response>(`${API}users/current`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const user: User = response.data;
                return user;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }


}
