import { Component, OnInit } from '@angular/core';
import { UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';

@Component({
    selector: 'app-fuzzy-and-weighted',
    templateUrl: './fuzzy-and-weighted.component.html',
    styleUrls: ['./fuzzy-and-weighted.component.scss'],
})
export class FuzzyAndWeightedComponent implements OnInit {
    user: UserPermission;

    constructor(private authService: AuthService) { }

    async ngOnInit() {
        this.user = await this.authService.getUserPermission();

    }
}
