import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { RolesEnum } from 'src/app/shared/models/enum/roles.enum';
import { User, UserFormData, UserPermission } from 'src/app/shared/models/views-models/user.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { UserService } from 'src/app/shared/service/views-services/user.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { getUserClaim } from 'src/app/shared/utils/common.utils';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
    permissions = RolesEnum.roles;
    userForm: UntypedFormGroup;
    currentUser: UserPermission;
    dialogRefMsg: any;
    isEditing = false;
    passwordUpdate = false;
    validPassword: boolean;
    setPassword: boolean;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
            cancelAvailable: false,
        },
    };


    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<UserDialogComponent>,
        private authService: AuthService,

        @Inject(MAT_DIALOG_DATA) public data: User
    ) { }

    async ngOnInit() {
        this.userForm = this.formBuilder.group({
            name: [null, [Validators.required, Validators.minLength(3)]],
            company: [null],
            sector: [null],
            registry: [null],
            email: [null, [Validators.required, Validators.email]],
            userName: [null, [Validators.required, Validators.minLength(3)]],
            password: [null, [Validators.required, Validators.minLength(6)]],
            matchPassword: [null, [Validators.required, Validators.minLength(6)]],
            roles: [null, Validators.required],
        });

        this.currentUser = await this.authService.getUserPermission()
        this.loadData();
        this.initForm();


    }

    getUser(): User {
        return this.data
    }

    loadData() {
        this.filterPermissions();
    }



    getUserFormData(user: User): UserFormData {

        return {
            name: user?.name,
            company: getUserClaim(user, 'company'),
            sector: getUserClaim(user, 'sector'),
            registry: getUserClaim(user, 'registry'),
            email: user?.email,
            userName: user?.userName,
            password: 'password',
            matchPassword: 'password',
            roles: user.roles,
        }

    }



    initForm() {

        const user = this.getUser();
        if (user) {

            this.isEditing = true;

            this.userForm.patchValue(this.getUserFormData(user));
            this.userForm.controls['matchPassword'].disable();
            this.userForm.controls['userName'].disable();

            let isToDisableEmail = !user.local;
            if (isToDisableEmail) {
                this.userForm.controls['name'].disable();
                this.userForm.controls['email'].disable();
            }

            let isToDisableRoles =
                user?.userName == this.currentUser.userName ||
                !user.local;
            if (isToDisableRoles) {
                this.userForm.controls['roles'].disable();
            }

            if (this.currentUser?.isSuperUser) {
                this.userForm.controls['userName'].enable();
            }
            this.userForm.get('password').valueChanges.subscribe((newPassword) => {
                if (newPassword) {
                    this.passwordUpdate = true;
                    this.userForm.patchValue({ matchPassword: '' });
                    this.userForm.controls['matchPassword'].enable();
                }
            });
        } else {
            this.passwordUpdate = true;
        }
        this.setPassword = !this.data || user?.local;
    }

    filterPermissions() {

        this.permissions = this.currentUser?.isSuperUser
            ? this.permissions
            : this.permissions.filter((permission) => permission.role != RolesEnum.ROLE_OWNER);
        this.permissions = this.currentUser?.permissions?.canCreate
            ? this.permissions
            : this.permissions.filter((permission) => permission.role != RolesEnum.ROLE_ADMIN);
    }

    onSubmit() {
        this.validPassword = true;
        this.defaultDialog.data.cancelAvailable = false;
        if (this.userForm.valid && this.validatePassword()) {
            if (this.isEditing) {
                this.defaultDialog.data.cancelAvailable = true;
                this.defaultDialog.data.message = this.passwordUpdate ? MessagesEnum.passwordChangeConfirm : MessagesEnum.userChangeConfirm;
                this.openDialog(this.defaultDialog);
                this.dialogRefMsg.afterClosed().subscribe((confirm) => {
                    if (confirm) {
                        console.log(this.userForm.getRawValue())

                        this.updateUser(this.getUpdateUser(this.userForm.getRawValue()));
                    }
                });
            } else {
                this.createUser(this.getCreationUser(this.userForm.getRawValue()));
            }
        } else {
            if (!this.userForm.controls['password'].value || !this.validPassword) {
                this.userForm.controls['password'].setErrors({ incorrect: true });
                this.userForm.controls['matchPassword'].setErrors({ incorrect: true });
            }
            if (!this.validPassword) {
                this.defaultDialog.data.message = MessagesEnum.passwordMatchFail;
            } else {
                this.defaultDialog.data.message = MessagesEnum.invalidFormMessage;
                if (this.userForm.controls['password'].invalid) {
                    this.defaultDialog.data.message += '. A senha deve conter no mínimo 6 caraceteres.';
                }
            }
            this.openDialog(this.defaultDialog);
        }
    }




    validatePassword() {
        let formData = this.userForm.getRawValue();
        let password = formData.password;
        let matchPassword = formData.matchPassword;
        if (password == matchPassword && this.userForm.controls['password'].valid) {
            return true;
        } else {
            this.validPassword = false;
            return false;
        }
    }


    clearPasswordField() {
        this.userForm.patchValue({ password: '' });
    }

    deleteUser() {
        let confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        this.openDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((response) => {
            if (response) {
                const user = this.getUser();

                this.userService.deleteUser(user?.userName).subscribe(() => {
                    this.data = new User();
                    this.defaultDialog.data.message = MessagesEnum.DeleteMessage;
                    this.openDialog(this.defaultDialog);
                });
            }
        });
    }

    openDialog(options: any): void {
        let data = options.data;
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (data.message == MessagesEnum.SuccessMessage || data.message == MessagesEnum.DeleteMessage) {
                this.dialogRef.close(this.getUser());
            }
        });
    }



    close() {
        this.dialogRef.close();
    }


    private getCreationUser(form: UserFormData): User {

        let user = new User();

        user.name = form.name;
        user.userName = form.userName;
        user.password = this.passwordUpdate ? form.password : user?.password;
        user.email = form.email;
        user.roles = form.roles;

        user.claims = [{
            type: 'company',
            value: form.company
        }, {
            type: 'sector',
            value: form.sector
        },
        {
            type: 'registry',
            value: form.registry
        }
        ]
        return user;
    }
    private getUpdateUser(form: UserFormData): User {

        let user = new User();

        user.name = form.name;
        user.userName = form.userName;
        user.password = this.passwordUpdate ? form.password : user?.password;
        user.email = form.email;
        user.roles = form.roles;

        user.claims = [{
            type: 'company',
            value: form.company
        }, {
            type: 'sector',
            value: form.sector
        },
        {
            type: 'registry',
            value: form.registry
        }
        ]
        return user;


    }


    private updateUser(user: User) {
        this.userService.updateUser(user).subscribe(() => {
            this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
            this.openDialog(this.defaultDialog);
        });
    }

    private createUser(user: User) {
        this.defaultDialog.data.cancelAvailable = false;
        this.userService.newUser(user).subscribe(() => {
            this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
            this.openDialog(this.defaultDialog);
        });

    }

}
