<div class="container">
    <ihm-card-header data-cy="title" icon="add_circle" [title]="isEditing ? 'Editar' : 'Adicionar' + ' Saída'">
    </ihm-card-header>

    <div class="sub-title">Identificação da Saída</div>

    <form *ngIf="processOutputForm" [formGroup]="processOutputForm" (ngSubmit)="onSubmit()">
        <div fxLayout="column" fxLayoutAlign="start" data-cy="input-form">
            <div fxFill>
                <mat-form-field appearance="outline" fxFill>
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome da Saída" type="text" formControlName="name" autocomplete="off"
                        data-cy="name" />
                </mat-form-field>
            </div>
            <div fxFill>
                <mat-form-field appearance="outline" fxFill>
                    <mat-label>Descrição</mat-label>
                    <input matInput placeholder="Descrição da Saída" type="text" formControlName="description"
                        autocomplete="off" data-cy="description" />
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>
            <div class="error-flow-select-input">
                    <app-select-error-flow class="select-app"
                        [title]="'Fluxo de Erro'"
                        [options]="errorFlowOptions"
                        [selectedValue]="processOutputForm.get('selectErrorFlow').value"
                        [placeholder]="'Modo de Falha'"
                        [showDescriptions]="true"
                        (selectedValueChange)="onErrorFlowChange($event)"
                        [hint]="processOutputForm.get('selectErrorFlow').value === 'last_value' ? 'Caso não exista um último valor disponível, será usado o valor default informado no campo ao lado.' : ''">
                    </app-select-error-flow>
                <mat-form-field appearance="outline" class="input-flow-error">
                    <input matInput placeholder="Valor*" type="text" formControlName="defaultValue"
                    autocomplete="off" data-cy="defaultValue" />
                </mat-form-field>
            </div>    
            <mat-divider></mat-divider>

            <div class="sub-title">Configuração da Saída</div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFill>
                <div fxFlex="40">
                    <mat-form-field class="tag-type-container" appearance="outline" fxFill>
                        <mat-label>Tipo</mat-label>
                        <mat-select placeholder="Tipo de Saída" formControlName="tagType" data-cy="type"
                            (selectionChange)="onTagTypeSelected($event.value)">
                            <mat-option *ngFor="let item of tagTypes" [value]="item.name">
                                {{ item.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="60">
                    <mat-form-field class="tags-container" appearance="outline" fxFill>
                        <mat-label>Variável</mat-label>
                        <mat-select placeholder="Selecione a Variável" formControlName="tagOption" data-cy="type">
                            <mat-option *ngFor="let tag of tagsByType" [value]="tag.id">
                                {{ tag.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Condição de Escrita (Opcional)</mat-label>
                    <mat-select placeholder="Selecione a Condição" formControlName="condition" data-cy="type">
                        <mat-option *ngFor="let tag of conditionTags | keyvalue" [value]="tag.value.id">
                            {{ tag.value.name }}
                        </mat-option>
                        <mat-option (click)="onClearCondition()" *ngIf="processOutputForm.get('condition').value !== ''"
                            [value]="null">Limpar</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFill>
                <div fxFlex="40">
                    <mat-form-field class="opc-server-container" appearance="outline" fxFill>
                        <mat-label>Servidor</mat-label>
                        <mat-select placeholder="Selecione o Servidor" formControlName="opcServer" data-cy="type">
                            <mat-option *ngFor="let opcServer of opcServers" [value]="opcServer.id">
                                {{ opcServer.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="60">
                    <mat-form-field class="opc-address-container" appearance="outline" fxFill>
                        <mat-label>Endereço OPC</mat-label>
                        <input matInput placeholder="Endereço OPC de Saída" type="text" formControlName="opcAddress"
                            autocomplete="off" data-cy="opcAddress" />
                    </mat-form-field>
                </div>
            </div>
            <div class="buttons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div fxFlex *ngIf="isEditing">
                    <button mat-button class="whiteButton"
                        *ngIf="user?.permissions?.canDelete && currentProjectService.isBuildingMode()" type="button"
                        (click)="deleteOutput()" data-cy="delete-process">
                        <i class="material-icons">delete</i>&nbsp;DELETAR SAÍDA
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                <button mat-raised-button class="blueButton" type="submit" data-cy="save"
                    *ngIf="user?.permissions?.canUpdate && currentProjectService.isBuildingMode()">
                    SALVAR
                </button>
            </div>
        </div>
    </form>
</div>