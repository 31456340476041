<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="title" data-cy="title">{{ isEditing ? "Editar" : "Adicionar" }} Usuário</div>
    <mat-divider></mat-divider>
    <div class="container body" fxLayout="column" fxLayoutGap="15px">
        <div class="sub-title">Dados do Usuário</div>

        <mat-form-field appearance="outline">
            <mat-label>Nome*</mat-label>
            <input matInput type="text" formControlName="name" data-cy="name" />
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Empresa</mat-label>
                <input matInput type="text" formControlName="company" data-cy="company" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Setor</mat-label>
                <input matInput type="text" formControlName="sector" data-cy="sector" />
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" class="no-title">
            <mat-form-field appearance="outline">
                <mat-label>Matrícula</mat-label>
                <input matInput type="text" formControlName="registry" data-cy="registry" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>E-mail*</mat-label>
                <input matInput type="email" formControlName="email" data-cy="email" />
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div class="sub-title">Acesso e Permissões</div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Login</mat-label>
                <input matInput type="text" formControlName="userName" data-cy="username" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Permissão</mat-label>
                <mat-select formControlName="roles" data-cy="role" multiple>
                    <mat-option *ngFor="let permission of permissions" [value]="permission.role">
                        {{ permission.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" *ngIf="setPassword">
            <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <input matInput type="password" formControlName="password" (focus)="clearPasswordField()"
                    autocomplete="on" data-cy="password" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Digite novamente a senha</mat-label>
                <input matInput type="password" formControlName="matchPassword" data-cy="match-password"
                    autocomplete="on" />
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="buttons">
        <div fxFlex>
            <button mat-button class="whiteButton"
                *ngIf="isEditing && currentUser?.permissions?.canUpdate && currentUser?.userName != data.userName"
                type="button" (click)="deleteUser()" data-cy="delete">
                <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR USUÁRIO
            </button>
        </div>
        <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
        <button mat-button class="blueButton" type="submit" data-cy="save">SALVAR</button>
    </div>
</form>