import { Claim } from './claim.model';

export class User {
    email: string;
    enable: boolean;
    local: boolean;
    name: string;
    userName: string;
    password: string;
    picture: string;
    roles: Array<string>;
    claims: Array<Claim>;
    isSuperUser?: boolean;

}

export class UserPermission extends User {

    isSuperUser?: boolean;
    permissions: {
        canCreate: boolean;
        canRead: boolean;
        canUpdate: boolean;
        canDelete: boolean;
        ldapAuthorization: true;
    }

}


export interface UserFormData {
    name: string;
    company: string;
    sector: string;
    registry: string;
    email: string;
    userName: string;
    password: string;
    matchPassword: string;
    roles: Array<string>;


}

export interface SelectOption {
    value: any;
    label: string;
    description?: string; // Opcional
  }