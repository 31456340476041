import { Injectable } from '@angular/core';

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { from, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { CurrentProjectService } from '../views-services/current-project.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthIdleService } from './auth.idle.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public dialog: MatDialog, private currentProjectService: CurrentProjectService, public oidcSecurityService: OidcSecurityService, public authIdle: AuthIdleService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Adiciona a autorização (jwt Token)  no cabeçalho caso o usuário já tenha se autenticado
        //const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (this.isRequestAvailable(request, next)) {
            return from(this.handle(request, next)).pipe(
                catchError((error: any) => {
                    if (error instanceof HttpErrorResponse && error?.status === 401) {

                        this.authIdle.recreateToken()
                        //    .pipe(delay(1000), mergeMap(_ => from(this.handle(request, next))));
                        return from(this.handle(request, next))

                    }
                    return throwError(error?.error);
                })
            );
        } else {
            return;;
        }
    }



    private async handle(request: HttpRequest<any>, next: HttpHandler) {

        let header = {};
        const acessToken = await lastValueFrom(this.oidcSecurityService.getAccessToken());
        if (acessToken) {
            header['Authorization'] = `Bearer ${acessToken}`;
        }
        header = this.getProjectRequestHeader(header);

        request = request.clone({
            setHeaders: header,
        });

        return lastValueFrom(next.handle(request))
    }

    private getProjectRequestHeader(header) {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject) {

            let project_id = currentProject?.id;
            let project_main_id = currentProject?.mainProjectId;
            let project_version_type = currentProject?.versionType;
            let project_name = currentProject?.name;
            let project_version = currentProject?.version;

            if (project_id) {
                header['project-id'] = project_id;
            }

            if (project_main_id) {
                header['project-main-id'] = project_main_id;
            }

            if (project_version_type) {
                header['project-version-type'] = project_version_type;
            }

            if (project_name) {
                header['project-name'] = project_name;
            }

            if (project_version) {
                header['project-version'] = `${project_version}`;
            }

        }
        return header;

    }


    private isRequestAvailable(request: HttpRequest<any>, next: HttpHandler): boolean {
        const project = this.currentProjectService.getCurrentProject();

        const isEditRequest =
            request.method == 'POST' ||
            request.method == 'PUT' ||
            request.method == 'PATCH' ||
            request.method == 'DELETE';

        const isProjectRequest =
            request.url.includes('project') ||
            request.url.includes('user') ||
            request.url.includes('opcServer') ||
            request.url.includes('all') ||
            request.url.includes('token') ||
            request.url.includes('jwks') ||
            request.url.includes('auth');


        if (project?.versionType == 'RUNNING' && isEditRequest && !isProjectRequest) {
            let conf = {
                component: ConfirmDialogComponent,
                panelClass: 'pop-up-dialog-container',
                minWidth: 'auto',
                data: {
                    title: 'Projeto está na versão RUNNING',
                    message: 'Altere o projeto para BUILDING para executar esta operação ',
                    cancelAvailable: false,
                    confirmButton: 'OK',
                },
            };
            this.dialog.open(conf.component, {
                panelClass: conf.panelClass,
                minWidth: conf.minWidth ? conf.minWidth : 'auto',
                height: 'auto',
                data: conf.data,
            });

            return false;
        }

        return true;
    }
}
